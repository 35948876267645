import React from "react";
import {
  List,
  Datagrid,
  TextField,
  ChipField,
  EditButton,
  FunctionField,
  DateField,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import { formattedLastTime } from "./formatter";

const useStyles = makeStyles({
  customer: { color: "green" },
  prospect: { color: "grey" },
  manager: { color: "yellow" },
  admin: { color: "red" },
});

const ColoredChipField = (props) => {
  const classes = useStyles();
  return (
    <ChipField
      className={classnames({
        [classes.customer]: props.record[props.source] === "customer",
        [classes.prospect]: props.record[props.source] === "prospect",
        [classes.manager]: props.record[props.source] === "manager",
        [classes.admin]: props.record[props.source] === "admin",
        [classes.admin]: props.record[props.source] === "sales",
      })}
      {...props}
    />
  );
};
// Ensure the original component defaultProps are still applied as they may be used by its parents (such as the `Show` component):
ColoredChipField.defaultProps = ChipField.defaultProps;

const UsersList = (props) => {
  return (
    <List
      {...props}
      filter={{ role: ["owner", "admin", "manager", "agent", "sales"] }}
    >
      <Datagrid rowClick="show">
        {/* expand={<UserShow />} */}
        <TextField source="name" />
        <TextField source="phone" />
        <TextField source="targetChannel" label="Channel" />
        <DateField source="targetDate" label="Reach" />

        {/* <DateField source="registerDate" label="Registed on" /> */}
        <FunctionField
          label="since last session"
          render={(record) => `${formattedLastTime(record.lastSession)}`}
        />
        <ChipField source="status" />
        <ColoredChipField source="role" />

        <EditButton label="Edit" basePath="/users" />
        {/* <DeleteButton label="Delete" basePath="/users" /> */}
      </Datagrid>
    </List>
  );
};

export default UsersList;
