import { fetchUtils } from "react-admin";
import { stringify } from "query-string";
import { apiUrl } from "../utils/constants";
// import dotenv from "dotenv";
// dotenv.config();

// const httpClient = fetchUtils.fetchJson;

export const httpClient = (url, tetherauth, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  try {
    const { token } = JSON.parse(tetherauth);

    options.headers.set("x-auth-token", token);
  } catch (e) {
    console.log("Token not found");
  }
  return fetchUtils.fetchJson(url, options);
};

const dataProvider = {
  getList: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;

    // if (params.filter == "q")
    // const f =  {phone: { $regex: searchText, $options: "i" }}

    // console.log("get List", resource);
    // console.log("full text fields", resource);
    // if (q) {
    //   let queryString = ""; //create a query string to put into an or( ) selector
    //   const fullTextSearchFields = Array.isArray(resourceOptions)
    //     ? resourceOptions
    //     : resourceOptions.fullTextSearchFields;

    //   fullTextSearchFields.forEach((field, index, array) => {
    //     /* query = query.ilike(field, `%${q}%`); */ // existing method - add on successive ilike selectors

    //     queryString += `${field}.ilike.%${q}%`; // new method - concatenate the ilike queries into one string

    //     if (index < array.length - 1) {
    //       queryString += ","; //add commas between the selectors, but not at the end
    //     }
    //   });
    //   query.or(queryString); //add an or( ) selector using the built query string

    // }
    const query = {
      sortBy: JSON.stringify([[field, order]]),
      limit: JSON.stringify(perPage),
      page: JSON.stringify(page),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify(params.filter),
    };

    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    return httpClient(url, localStorage.getItem("tetherauth"))
      .then(({ status, headers, json }) => ({
        data: json.map((resource) => ({ ...resource, id: resource._id })),
        total: parseInt(headers.get("X-Total-Count"), 10),
      }))
      .catch((e) => {
        throw new Error(e);
      });
  },

  getOne: (resource, params) =>
    httpClient(
      `${apiUrl}/${resource}/${params.id}`,
      localStorage.getItem("tetherauth")
    ).then(({ json }) => ({
      data: { ...json, id: json._id }, //!
    })),

  getMany: (resource, params) => {
    const query = {
      sortBy: JSON.stringify([]),
      filter: JSON.stringify({ _id: params.ids }),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    return httpClient(url, localStorage.getItem("tetherauth"))
      .then(({ status, headers, json }) => ({
        data: json.map((resource) => ({ ...resource, id: resource._id })),
        total: parseInt(headers.get("X-Total-Count"), 10),
      }))
      .catch((e) => {
        throw new Error(e);
      });
  },

  getManyReference: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    // const filter = { ......params.filter, ...params.filter._id: params.filter.id }
    const query = {
      sortBy: JSON.stringify([[field, order]]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    return httpClient(url, localStorage.getItem("tetherauth"))
      .then(({ status, headers, json }) => ({
        data: json.map((resource) => ({ ...resource, id: resource._id })),
        total: parseInt(headers.get("X-Total-Count"), 10),
      }))
      .catch((e) => {
        console.log("Error: ", e);
        throw new Error(e);
      });
  },

  update: (resource, params) =>
    httpClient(
      `${apiUrl}/${resource}/${params.id}`,
      localStorage.getItem("tetherauth"),
      {
        method: "PUT",
        body: JSON.stringify(params.data),
      }
    ).then(({ json }) => ({
      data: { ...json, id: json._id }, //!...json, id: json._id
    })),

  updateMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
      method: "PUT",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },

  create: (resource, params) =>
    httpClient(`${apiUrl}/${resource}`, localStorage.getItem("tetherauth"), {
      method: "POST",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({
      data: { ...params.data, id: json._id },
    })),

  delete: (resource, params) =>
    httpClient(
      `${apiUrl}/${resource}/${params.id}`,
      localStorage.getItem("tetherauth"),
      {
        method: "DELETE",
        body: JSON.stringify(params.id),
      }
    ).then(({ json }) => ({
      ...json,
      id: json._id,
    })),

  deleteMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    return httpClient(
      `${apiUrl}/${resource}?${stringify(query)}`,
      localStorage.getItem("tetherauth"),
      {
        method: "DELETE",
        body: JSON.stringify(params.data),
      }
    ).then(({ json }) => ({ data: json }));
  },
};
export default dataProvider;
