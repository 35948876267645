import { apiUrl } from "../utils/constants";
import translateErrorMessageToCode from "../utils/translateErrorMessageToCode";

import { fetchUtils } from "react-admin";

//"https://parkr-eth.herokuapp.com/api/team";
// const loginUrl = window.location.origin + "/login";
const authProvider = {
  login: ({ phone, email, password }) => {
    const option = {
      method: "POST",
      body: JSON.stringify({ phone, email, password }),
      headers: new Headers({ "Content-Type": "application/json" }),
    };
    return fetchUtils
      .fetchJson(apiUrl + "/login", option)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json;
      })
      .then((auth) => {
        localStorage.setItem("tetherauth", JSON.stringify(auth));
      })
      .catch((e) => {
        throw new Error(e);
      });
  },
  register: ({ name, phone, email, password }) => {
    const option = {
      method: "POST",
      body: JSON.stringify({ name, phone, email, password }),
      headers: new Headers({ "Content-Type": "application/json" }),
    };
    return fetchUtils
      .fetchJson(apiUrl + "/register", option)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json;
      })
      .then((auth) => {
        localStorage.setItem("tetherauth", JSON.stringify(auth));
      })
      .catch(() => {
        throw new Error("Network error");
      });
  },
  forgotPassword: ({ phone, email }) => {
    const option = {
      method: "POST",
      body: JSON.stringify({ phone, email }),
      headers: new Headers({ "Content-Type": "application/json" }),
    };
    return fetchUtils
      .fetchJson(apiUrl + "/forgot-password", option)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json;
      })
      .then(({ json }) => ({ data: json }))
      .catch(() => {
        throw new Error("Network error");
      });
  },
  verifyEmailResetPasswordToken: ({ token }) => {
    const option = {
      method: "POST",
      body: JSON.stringify({ token }),
      headers: new Headers({ "Content-Type": "application/json" }),
    };
    return fetchUtils
      .fetchJson(apiUrl + "/verify-email-reset-password-token", option)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json;
      })
      .then(({ json }) => ({ data: json }))
      .catch(() => {
        throw new Error("Network error");
      });
  },
  verifyPhoneResetPasswordOTP: ({ otp }) => {
    const option = {
      method: "POST",
      body: JSON.stringify({ otp }),
      headers: new Headers({ "Content-Type": "application/json" }),
    };
    return fetchUtils
      .fetchJson(apiUrl + "/verify-phone-reset-password-otp", option)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(({ json }) => ({ data: json }))
      .catch(() => {
        throw new Error("Network error");
      });
  },
  resetPasswordEmail: ({ token, password }) => {
    const option = {
      method: "POST",
      body: JSON.stringify({ token, password }),
      headers: new Headers({ "Content-Type": "application/json" }),
    };
    return fetchUtils
      .fetchJson(apiUrl + "/email-reset-password", option)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(({ json }) => ({ data: json }))
      .catch(() => {
        throw new Error("Network error");
      });
  },
  resetPasswordPhone: ({ otp, password }) => {
    const option = {
      method: "POST",
      body: JSON.stringify({ otp, password }),
      headers: new Headers({ "Content-Type": "application/json" }),
    };
    return fetchUtils
      .fetchJson(apiUrl + "/phone-reset-password", option)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(({ json }) => ({ data: json }))
      .catch(() => {
        throw new Error("Network error");
      });
  },
  // ...
  checkError: (error) => {
    const errorMsg = error.toString();
    const status = translateErrorMessageToCode(errorMsg);

    if (status === 401) {
      // || status === 403
      localStorage.removeItem("tetherauth");
      // window.location = '/#/login';
      return Promise.reject();
    }
    // other error code (404, 500, etc): no need to log out
    return Promise.resolve();
  },
  checkAuth: () => {
    return localStorage.getItem("tetherauth")
      ? Promise.resolve()
      : Promise.reject();
  },
  logout: () => {
    localStorage.removeItem("tetherauth");
    return Promise.resolve();
  },
  getIdentity: () => {
    try {
      const { id, name, avatar, role, companyId, companyName } = JSON.parse(
        localStorage.getItem("tetherauth")
      );
      return Promise.resolve({
        id: id,
        fullName: name || "",
        avatar: avatar || "",
        role: role || "",
        companyId: companyId || "",
        companyName: companyName || "",
      });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getPermissions: () => {
    const auth = JSON.parse(localStorage.getItem("tetherauth"));

    return Promise.resolve({
      role: auth?.role || [],
      companyId: auth?.companyId,
      verifyPhone: auth?.verifyPhone,
      verifyEmail: auth?.verifyEmail,
    });
  },
};

export default authProvider;
