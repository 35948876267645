import React, { useState } from "react";
import {
  Datagrid,
  TabbedShowLayout,
  Tab,
  TextField,
  ReferenceField,
  EditButton,
  BooleanField,
  Button,
  FunctionField,
  ReferenceManyField,
  ChipField,
  ShowView,
  ShowController,
  useUpdate,
  Notification,
  useNotify,
  DateField,
  useRefresh,
  useRedirect,
} from "react-admin";
import { formatMoney, formattedLastTime } from "./formatter";
import { makeStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import { approveUser } from "../api/useRoutes";

const ROLE = {
  OWNER: "owner",
  ADMIN: "admin",
  MANAGER: "manager",
  AGENT: "agent",
  CUSTOMER: "customer",
  PROSPECT: "prospect",
  SALES: "sales",
};

const useStyles = makeStyles({
  customer: { color: "green" },
  prospect: { color: "grey" },
  manager: { color: "yellow" },
  admin: { color: "red" },
});

const ColoredChipField = (props) => {
  const classes = useStyles();
  return (
    <ChipField
      className={classnames({
        [classes.customer]: props.record[props.source] === "customer",
        [classes.prospect]: props.record[props.source] === "prospect",
        [classes.manager]: props.record[props.source] === "manager",
        [classes.admin]: props.record[props.source] === "admin",
        [classes.admin]: props.record[props.source] === "sales",
      })}
      {...props}
    />
  );
};

const ApproveButton = (props) => {
  const { record } = props;
  const [loading, setLoading] = useState(false);
  const notify = useNotify();
  const redirect = useRedirect();

  return (
    <Button
      {...props}
      label="Approve"
      onClick={() => {
        setLoading(true);
        approveUser({ id: record.id })
          .then(() => {
            notify("User approved");
            redirect("/customers");
          })
          .catch((e) => {
            notify("User approval failed " + e.message, { type: "warning" });
          })
          .finally(() => {
            setLoading(false);
          });
      }}
      disabled={loading}
    />
  );
};

const UserShow = (props) => (
  <ShowController {...props}>
    {(controllerProps) => (
      <ShowView {...props} {...controllerProps}>
        <TabbedShowLayout>
          <Tab label="Account">
            <TextField source="name" />
            <TextField source="phone" />
            <TextField source="passphrase" label="Pass phrase" />
            <BooleanField source="isApproved" />
            {controllerProps.record && !controllerProps.record.isApproved && (
              <ApproveButton />
            )}

            {/* <Notification /> */}
          </Tab>

          <Tab label="Details">
            <TextField source="targetChannel" label="Channel" />
            <DateField source="targetDate" label="Reach" />

            {/* <DateField source="registerDate" label="Registed on" /> */}
            <FunctionField
              label="since last session"
              render={(record) => `${formattedLastTime(record.lastSession)}`}
            />
            <ChipField source="status" />
            <ColoredChipField source="role" />

            <EditButton label="Edit" basePath="/users" />
          </Tab>
          <Tab label="Security">
            <BooleanField source="isPasswordExpired" />
            <BooleanField source="isEmailVerified" />
            <BooleanField source="isPhoneVerified" />
          </Tab>
          {controllerProps.record &&
            controllerProps.record.role === ROLE.SALES && (
              <Tab label="Devices">
                <ReferenceManyField reference="devices" target="salesRef">
                  <Datagrid rowClick="show">
                    {/* for extended history */}
                    <TextField source="identifier" label="IME" />
                    <TextField source="model" label="Model" />
                    <TextField source="SIMNumber" label="Number" />
                    <TextField source="trackerType" label="Type" />
                    {/* <TextField source="serverURL" label="Uplink" /> */}
                    <TextField source="devicePassword" label="Password" />
                    <ColoredChipField source="deviceStatus" label="Status" />
                    <ReferenceField
                      label="Master"
                      source="masterRef"
                      reference="users"
                    >
                      <TextField source="name" />
                    </ReferenceField>
                    <BooleanField source="relayStatus" label="Relay" />

                    <TextField source="note" label="Note" />
                    <FunctionField
                      label="Last signal"
                      render={(record) =>
                        `${formattedLastTime(record.lastLog, true)}`
                      }
                    />
                  </Datagrid>
                </ReferenceManyField>
              </Tab>
            )}
          {controllerProps.record &&
            controllerProps.record.role === ROLE.CUSTOMER && (
              <Tab label="Vehicles">
                <ReferenceManyField
                  label="Assets"
                  reference="vehicles"
                  target="ownerRef"
                >
                  <Datagrid rowClick="show">
                    <TextField source="plate" />
                    {/* <TextField source="id" /> */}
                    <TextField source="brand" />
                    <TextField source="model" />
                    {/* <TextField source="type" /> */}
                    {/* <TextField source="tag" /> */}
                    {/* <TextField source="color" /> */}
                    {/* <TextField source="colorHex" /> */}
                    <ChipField source="serviceStatus" label="Service" />
                    {/* <TextField source="status" label="State" /> */}
                    {/* owner ref */}
                    {/* <TextField source="ownerRef" /> */}
                    <ReferenceField
                      label="Owner"
                      source="ownerRef"
                      reference="customers"
                      link="show"
                    >
                      <TextField source="name" />
                    </ReferenceField>
                    {/* driver ref */}
                    {/* <TextField source="driverRef" /> */}
                    {/* <ReferenceField label="Driver" source="driverRef" reference="customers">
          <TextField source="name" />
        </ReferenceField> */}

                    {/* device ref */}
                    {/* <TextField source="deviceRef" /> */}
                    <ReferenceField
                      label="Tracker"
                      source="deviceRef"
                      reference="devices"
                      link="show"
                    >
                      {/* <TextField source="SIMNumber" /> */}
                      <FunctionField
                        label="Tracker"
                        render={(record) =>
                          `imei ${record.identifier} - ${record.SIMNumber}`
                        }
                      />
                    </ReferenceField>

                    {/* notifier ref */}
                    <ReferenceField
                      label="Notifier"
                      source="offlineNotifierRef"
                      reference="users"
                      link="show"
                    >
                      <TextField source="name" />
                    </ReferenceField>

                    <FunctionField
                      label="last signal"
                      render={(record) =>
                        `${formattedLastTime(record.lastLog)}`
                      }
                    />

                    <DateField
                      source="subscriptionExpiryDate"
                      label="Expires on"
                    />
                  </Datagrid>
                </ReferenceManyField>
              </Tab>
            )}

          {controllerProps.record &&
            controllerProps.record.role === ROLE.CUSTOMER && (
              <Tab label="Invoice">
                <ReferenceManyField
                  label="Billing"
                  reference="invoices"
                  target="userRef"
                >
                  <Datagrid
                    sx={{
                      "& .RaDatagrid-rowOdd": {
                        backgroundColor: "#fee",
                      },
                    }}
                    rowClick="show"
                  >
                    <TextField source="invoiceNumber" />
                    <ReferenceField
                      label="Customer"
                      source="userRef"
                      reference="customers"
                      link="show"
                      emptyText="No User"
                    >
                      <TextField source="name" />
                    </ReferenceField>
                    <ReferenceField
                      label="Vehicle"
                      source="vehicleRef"
                      reference="vehicles"
                      link="show"
                      emptyText="No Vehicle"
                    >
                      <TextField source="plate" />
                    </ReferenceField>
                    <DateField source="issuedDate" />
                    <DateField source="dueDate" />
                    <ChipField source="status" />
                    <FunctionField
                      label="Progress"
                      render={(record) =>
                        (record.issuedNotified ? " √ " : " - ") +
                        (record.reminderNotified ? " √ " : " - ") +
                        (record.dueNotified ? " √ " : " - ") +
                        (record.overdueNotified ? " √ " : " - ") +
                        (record.warningNotified ? " √ " : " - ") +
                        (record.suspensionNotified ? " √ " : " - ")
                      }
                    />
                    <FunctionField
                      label="Total amount"
                      render={(record) =>
                        `${formatMoney(
                          record.items
                            .map((i) => i.amount)
                            .reduce((a, b) => a + b, 0),
                          2
                        )}`
                      }
                    />
                    <FunctionField
                      label="Total transaction"
                      render={(record) =>
                        `${formatMoney(
                          record.transactions
                            .map((i) => i.transactionAmount)
                            .reduce((a, b) => a + b, 0),
                          2
                        )}`
                      }
                    />
                  </Datagrid>
                </ReferenceManyField>
              </Tab>
            )}
        </TabbedShowLayout>
      </ShowView>
    )}
  </ShowController>
);

export default UserShow;
