import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  BooleanInput,
  NumberInput,
  ReferenceInput,
  required,
  SelectInput,
} from "react-admin";
import HorizontalInputStyle from "./HorizontalInputStyle";

const deviceStatus = [
  { id: "uninstalled", name: "uninstalled" },
  { id: "setup", name: "setup" },
  { id: "testing", name: "testing" },
  { id: "active", name: "active" },
  { id: "maintanance", name: "maintanance" },
  { id: "offline", name: "offline" },
  { id: "faulty", name: "faulty" },
];

const userOptionRenderer = (choice) => `${choice.name} - ${choice.phone}`;

const DevicesCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput disabled source="id" />
        <TextInput required source="identifier" style={HorizontalInputStyle} />
        <TextInput
          required
          source="model"
          style={HorizontalInputStyle}
          defaultValue={"GT720"}
        />
        <TextInput required source="SIMNumber" defaultValue={"09"} />
        <TextInput required source="trackerType" defaultValue={"relay GPS"} />
        <ReferenceInput
          label="Master"
          source="masterRef"
          reference="users"
          defaultValue={"605f309d1c8f360930ed8d4f"}
          validate={[required()]}
          filter={{ role: "agent" }}
          style={HorizontalInputStyle}
        >
          <SelectInput optionText={userOptionRenderer} />
        </ReferenceInput>
        <ReferenceInput
          label="Sales"
          source="salesRef"
          reference="users"
          filter={{ role: "sales" }}
        >
          <SelectInput optionText={userOptionRenderer} />
        </ReferenceInput>
        <TextInput
          required
          source="serverURL"
          style={HorizontalInputStyle}
          defaultValue={"https://tether.qedron.com/"}
        />
        <TextInput
          required
          source="devicePassword"
          style={HorizontalInputStyle}
          defaultValue={"875421"}
        />
        <SelectInput
          required
          source="deviceStatus"
          label="Status"
          choices={deviceStatus}
          defaultValue={deviceStatus[0].id}
        />
        {/* <TextInput required source="deviceStatus" /> */}
        <TextInput
          required
          source="relayOnCode"
          style={HorizontalInputStyle}
          defaultValue={"555"}
        />
        <TextInput required source="relayOffCode" defaultValue={"666"} />
        <NumberInput required source="SIMBalance" defaultValue={-1} />
        <BooleanInput
          required
          source="relayStatus"
          label="Relay"
          defaultValue={true}
        />
      </SimpleForm>
    </Create>
  );
};

export default DevicesCreate;
