import React from "react";
import {
  Edit,
  FormTab,
  TabbedForm,
  TextInput,
  SelectInput,
  BooleanInput,
  DateInput,
} from "react-admin";
import { acquisitionChannels } from "../utils/constants";

const userStatus = [
  { id: "active", name: "active" },
  { id: "expired", name: "expired" },
  { id: "suspended", name: "suspended" },
  { id: "fresh", name: "fresh" },
];

// const acquisitionChannels = [
//   { id: "fca", name: "FCA" },
//   { id: "gga", name: "GGA" },
//   { id: "wom", name: "wom" },
// ];

const userRole = [
  { id: "owner", name: "owner" },
  { id: "admin", name: "admin" },
  { id: "manager", name: "manager" },
  { id: "customer", name: "customer" },
  { id: "agent", name: "agent" },
  { id: "prospect", name: "prospect" },
  { id: "lead", name: "lead" },
  { id: "follower", name: "follower" },
  { id: "sales", name: "sales" },
];

const UsersEdit = (props) => {
  return (
    <Edit {...props}>
      <TabbedForm>
        <FormTab label="Account">
          <TextInput disabled source="id" />
          <TextInput required source="name" />
          <TextInput required source="phone" />
          <TextInput required source="password" />
          <TextInput required source="telegramChatId" />
          <TextInput required source="passphrase" />
        </FormTab>
        <FormTab label="Details">
          <SelectInput
            source="targetChannel"
            label="Channel"
            choices={acquisitionChannels}
          />
          <DateInput source="targetDate" label="Reach" />
          {/* <TextInput required source="subscriptionDate" /> */}
          <SelectInput
            required
            source="status"
            label="Status"
            choices={userStatus}
          />
          <SelectInput required source="role" label="Role" choices={userRole} />
          {/* <TextInput required source="role" /> */}
          {/* <TextInput required source="language" /> */}
          {/* <TextInput required source="devices" /> */}
          <DateInput required source="registerDate" disabled />
          <DateInput required source="lastSession" disabled />
        </FormTab>
        <FormTab label="Security">
          <BooleanInput source="isPasswordExpired" />
          <BooleanInput source="isEmailVerified" />
          <BooleanInput source="isPhoneVerified" />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export default UsersEdit;
