import React from "react";
import { AppBar, Login } from "react-admin";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";

const styles = {
  title: {
    flex: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  spacer: {
    flex: 1,
  },
};

const SearchAppBar = withStyles(styles)(({ classes, ...props }) => (
  <AppBar {...props}>
    <Typography
      variant="inherit"
      color="inherit"
      className={classes.title}
      id="react-admin-title"
    />
    <IconButton color="inherit">
      {/* <BackIcon /> */}
      {/* <SearchInput source="q" alwaysOn /> */}
    </IconButton>

    {/* </TextareaAutosize> */}
    <span className={classes.spacer} />
  </AppBar>
));

const TetherLoginPage = () => (
  <Login
  // A random image that changes everyday
  // backgroundImage="https://source.unsplash.com/random/1600x900/daily"
  />
);

export { SearchAppBar, TetherLoginPage };
