import React from "react";
import {
  TabbedShowLayout,
  Tab,
  Show,
  TextField,
  ChipField,
  BooleanField,
} from "react-admin";
import { MapAreaField } from "../components/MapField";

const AreaShow = (props) => {
  return (
    <Show {...props}>
      <TabbedShowLayout>
        <Tab label="Location">
          <TextField source="areaName" />
          <MapAreaField source="areaFence" />
        </Tab>
        <Tab label="Details">
          <ChipField source="status" />
          <TextField source="note" />
          <BooleanField source="isAreaFencedIn" label="Fenced In" />
          <BooleanField source="isAreaFencedOut" label="Fenced Out" />
          <TextField source="areaFenceInNotificationType" />
          <TextField source="areaFenceOutNotificationType" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default AreaShow;
