import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  ReferenceInput,
  required,
  DateInput,
} from "react-admin";

const userStatus = [
  { id: "active", name: "active" },
  { id: "expired", name: "expired" },
  { id: "suspended", name: "suspended" },
  { id: "fresh", name: "fresh" },
];

const userOptionRenderer = (choice) => `${choice.name} - ${choice.phone}`;

const CompanyCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput required source="companyName" />
        <TextInput required source="email" />
        <TextInput required source="phone" />
        <ReferenceInput
          label="Owner"
          source="ownerRef"
          reference="users"
          validate={[required()]}
        >
          <SelectInput optionText={userOptionRenderer} />
        </ReferenceInput>
        <DateInput required source="registerDate" defaultValue={Date()} />

        <SelectInput
          required
          source="status"
          label="Status"
          choices={userStatus}
        />
        <TextInput source="note" />
      </SimpleForm>
    </Create>
  );
};

export default CompanyCreate;
