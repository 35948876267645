import {
  iconDisconnected,
  iconDriving,
  iconEngineCutViolation,
  iconEngineCut,
  iconFenceViolation,
  iconFenced,
  iconParkViolation,
  iconPark,
  iconSpeedViolation,
  iconSpeed,
} from "./Icons";
import { getDistance, isPointInPolygon } from "geolib";

const getVehicleIcon = (vehicle, userId) => {
  if (!vehicle.log) return iconDisconnected;
  // isEngineOff and moving
  else if (
    isEngineOff(vehicle) &&
    getDistanceBetweenTwoPoints(
      vehicle.ownerParkedLocation,
      vehicle.lastLocation
    ) > 50
  ) {
    return iconEngineCutViolation;
  }
  // isParked violated
  else if (
    isOwnerParked(vehicle, userId) &&
    getDistanceBetweenTwoPoints(
      vehicle.ownerParkedLocation,
      vehicle.lastLocation
    ) > 50
  ) {
    return iconParkViolation;
  } else if (
    isDriverParked(vehicle, userId) &&
    getDistanceBetweenTwoPoints(
      vehicle.driverParkedLocation,
      vehicle.lastLocation
    ) > 50
  ) {
    return iconParkViolation;
  }
  // isFenced violated
  else if (
    isOwnerFenced(vehicle, userId) &&
    !isPointInFence(vehicle.ownerFence, vehicle.lastLocation)
  ) {
    return iconFenceViolation;
  } else if (
    isDriverFenced(vehicle, userId) &&
    !isPointInFence(vehicle.driverFence, vehicle.lastLocation)
  ) {
    return iconFenceViolation;
  }
  // isSpeedLimited violated
  else if (
    isOwnerSpeedLimited(vehicle, userId) &&
    isSpeedViolated(
      vehicle.log,
      vehicle.lastLog,
      vehicle.lastLocation,
      vehicle.ownerSpeedLimitValue
    )
  ) {
    return iconSpeedViolation;
  } else if (
    isDriverSpeedLimited(vehicle, userId) &&
    isSpeedViolated(
      vehicle.log,
      vehicle.lastLog,
      vehicle.lastLocation,
      vehicle.driverSpeedLimitValue
    )
  ) {
    return iconSpeedViolation;
  }
  // isEngineOff
  else if (isEngineOff(vehicle)) {
    return iconEngineCut;
  }
  // isParked
  else if (isOwnerParked(vehicle, userId) || isDriverParked(vehicle, userId)) {
    return iconPark;
  }
  // isFenced
  else if (isOwnerFenced(vehicle, userId) || isDriverFenced(vehicle, userId)) {
    return iconFenced;
  }
  // isSpeedLimited
  else if (
    isOwnerSpeedLimited(vehicle, userId) ||
    isDriverSpeedLimited(vehicle, userId)
  ) {
    return iconSpeed;
  }
  // everything is alright
  else return iconDriving;
};

function isOwnerParked(vehicle, userId) {
  return vehicle.isOwnerParked && userId === vehicle.ownerRef;
}

function isDriverParked(vehicle, userId) {
  return vehicle.isDriverParked && userId === vehicle.driverRef;
}

function isOwnerFenced(vehicle, userId) {
  return vehicle.isOwnerFenced && userId === vehicle.ownerRef;
}

function isDriverFenced(vehicle, userId) {
  return vehicle.isDriverFenced && userId === vehicle.driverRef;
}

function isOwnerSpeedLimited(vehicle, userId) {
  return vehicle.isOwnerSpeedLimited && userId === vehicle.ownerRef;
}

function isDriverSpeedLimited(vehicle, userId) {
  return vehicle.isDriverSpeedLimited && userId === vehicle.driverRef;
}

function isEngineOff(vehicle) {
  return !vehicle.engine;
}

function getDistanceBetweenTwoPoints(parkedLocation, lastLocation) {
  if (parkedLocation != null && lastLocation != null) {
    const fromSplit = parkedLocation.split(",");
    const fromLat = parseFloat(fromSplit[0]);
    const fromLon = parseFloat(fromSplit[1]);
    const toSplit = lastLocation.split(",");
    const toLat = parseFloat(toSplit[0]);
    const toLon = parseFloat(toSplit[1]);
    // const R = 6371; // km
    // const dLat = toRadians(toLat - fromLat);
    // const dLon = toRadians(toLon - fromLon);
    // const lat1 = toRadians(fromLat);
    // const lat2 = toRadians(toLat);

    // const a =
    //   sin(dLat / 2) * sin(dLat / 2) +
    //   sin(dLon / 2) * sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    // const c = 2 * atan2(Math.sqrt(a), Math.sqrt(1 - a));
    // const d = R * c;

    return getDistance(
      { latitude: fromLat, longitude: fromLon },
      { latitude: toLat, longitude: toLon }
    );
  } else {
    return 0.0;
  }
}

function isPointInFence(polygon, lastLocation) {
  if (!lastLocation || !polygon) return true;

  const point = lastLocation.split(",");
  const pointLat = parseFloat(point[0]);
  const pointLon = parseFloat(point[1]);
  //
  const arrayFence = polygon.map((point) => {
    const split = point.split(",");
    const lat = parseFloat(split[0]);
    const lon = parseFloat(split[1]);
    return [(lat, lon)];
  });

  return isPointInPolygon({ latitude: pointLat, longitude: pointLon }, [
    arrayFence,
  ]);
}

function isSpeedViolated(logList, lastLogDate, lastLog, limit) {
  if (lastLog === null || logList.isEmpty()) return false;

  const now = Date.now();
  const count = logList.size;
  const log = logList[count - 1];
  const logDate = log.logDate ? Date.parse(log.logDate).valueOf() : now;

  const lastDate = lastLogDate ? Date.parse(lastLogDate).valueOf() : now;

  const fromSplit = lastLog.split(",");
  const fromLat = parseFloat(fromSplit[0]);
  const fromLon = parseFloat(fromSplit[1]);
  const toSplit = log.coordinate.split(",");
  const toLat = parseFloat(toSplit[0]);
  const toLon = parseFloat(toSplit[1]);

  const hourElapsed = (logDate - lastDate) * 1000 * 60 * 60;

  const speedInKmPerHour =
    calcCrow(fromLat, fromLon, toLat, toLon) / hourElapsed;
  return speedInKmPerHour > limit;
}

function calcCrow(fromLat, fromLon, toLat, toLon) {
  // const R = 6371; // km
  // const dLat = toRadians(toLat - fromLat);
  // const dLon = toRadians(toLon - fromLon);
  // const lat1 = toRadians(fromLat);
  // const lat2 = toRadians(toLat);

  //   const a =
  //     sin(dLat / 2) * sin(dLat / 2) +
  //     sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
  //   const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  //   const d = R * c;
  return getDistance(
    { latitude: fromLat, longitude: fromLon },
    { latitude: toLat, longitude: toLon }
  );
}

const getBearing = (fromCoordinate, toCoordinate) => {
  const fromSplit = fromCoordinate.includes(",")
    ? fromCoordinate.split(",")
    : [8, 38];
  const fromLat = parseFloat(fromSplit[0]);
  const fromLon = parseFloat(fromSplit[1]);
  const toSplit = toCoordinate.includes(",")
    ? toCoordinate.split(",")
    : [8, 38];
  const toLat = parseFloat(toSplit[0]);
  const toLon = parseFloat(toSplit[1]);

  return bearing(fromLat, fromLon, toLat, toLon);
};

// Converts from degrees to radians.
function toRadians(degrees) {
  return (degrees * Math.PI) / 180;
}

// Converts from radians to degrees.
function toDegrees(radians) {
  return (radians * 180) / Math.PI;
}

function bearing(startLat, startLng, destLat, destLng) {
  startLat = toRadians(startLat);
  startLng = toRadians(startLng);
  destLat = toRadians(destLat);
  destLng = toRadians(destLng);

  const y = Math.sin(destLng - startLng) * Math.cos(destLat);
  const x =
    Math.cos(startLat) * Math.sin(destLat) -
    Math.sin(startLat) * Math.cos(destLat) * Math.cos(destLng - startLng);
  let brng = Math.atan2(y, x);
  brng = toDegrees(brng);
  return (brng + 360) % 360;
}

export { getBearing, getVehicleIcon };
