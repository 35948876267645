import React from "react";
import {
  List,
  Datagrid,
  TextField,
  ChipField,
  EditButton,
  FunctionField,
  DateField,
  NumberField,
  ReferenceField,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import { formattedLastTime } from "./formatter";

const useStyles = makeStyles({
  customer: { color: "green" },
  prospect: { color: "grey" },
  manager: { color: "yellow" },
  admin: { color: "red" },
});

const ColoredChipField = (props) => {
  const classes = useStyles();
  return (
    <ChipField
      className={classnames({
        [classes.customer]: props.record[props.source] === "customer",
        [classes.prospect]: props.record[props.source] === "prospect",
        [classes.manager]: props.record[props.source] === "manager",
        [classes.admin]: props.record[props.source] === "admin",
        [classes.admin]: props.record[props.source] === "sales",
      })}
      {...props}
    />
  );
};
// Ensure the original component defaultProps are still applied as they may be used by its parents (such as the `Show` component):
ColoredChipField.defaultProps = ChipField.defaultProps;

export const distanceOptionRenderer = (num) => {
  var kms = num / 1000;
  var rkms = Math.floor(kms);
  var meters = (kms - rkms) * 1000;
  var rmeters = Math.round(meters);
  if (rkms > 1) return `${rkms}km ${rmeters}m`;
  else return `${rmeters}m`;
};

export const minuteOptionRenderer = (num) => {
  var hours = num / 60;
  var rhours = Math.floor(hours);
  var minutes = (hours - rhours) * 60;
  var rminutes = Math.round(minutes);
  if (rhours > 1) return `${rhours}hours ${rminutes}mins`;
  else return `${rminutes}mins`;
};

export const speedOptionRenderer = (num) => {
  return `${Math.round(num)}km/h`;
};

export const DailyList = (props) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <ReferenceField
        source="vehicleRef"
        label="plate"
        reference="vehicles"
        link="show"
      >
        <TextField source="plate" />
      </ReferenceField>
      <DateField source="logDay" />
      {/* <NumberField source="mileageInMeters" /> */}
      <FunctionField
        label="Mileage"
        render={(record) => distanceOptionRenderer(record.mileageInMeters)}
      />
      <FunctionField
        label="Moving for"
        render={(record) => minuteOptionRenderer(record.movingInMinutes)}
      />
      <FunctionField
        label="Idling for"
        render={(record) => minuteOptionRenderer(record.idlingInMinutes)}
      />
      <FunctionField
        label="Parked for"
        render={(record) => minuteOptionRenderer(record.parkedInMinutes)}
      />
      <FunctionField
        label="Min speed"
        render={(record) => speedOptionRenderer(record.minSpeedInKmPh)}
      />
      <FunctionField
        label="Max speed"
        render={(record) => speedOptionRenderer(record.maxSpeedInKmPh)}
      />
      <FunctionField
        label="Avg speed"
        render={(record) => speedOptionRenderer(record.avgSpeedInKmPh)}
      />

      {/* <NumberField source="ownerParkViolations" />
      <NumberField source="ownerFenceViolations" />
      <NumberField source="ownerSpeedViolations" />
      <NumberField source="driverParkViolations" />
      <NumberField source="driverFenceViolations" />
      <NumberField source="driverSpeedViolations" /> */}
      {/* <NumberField source="shocks" /> */}
      <NumberField source="entries" />
      {/* <DateField source="expireAt" />
      <DateField source="__v" />
      <TextField source="id" /> */}
    </Datagrid>
  </List>
);
