import React, { useEffect } from "react";
import "./App.css";
import "./i18n";
import { withTranslation } from "react-i18next";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Landing from "./Landing";
import AdminPanel from "./app/AdminPanel";
import { loadUser } from "./utils/useUser";

function App() {
  // const store = createStore(reducer);
  useEffect(() => {
    loadUser();
  }, []);

  return (
    <Router>
      <div className="App">
        <Switch>
          <Route exact path={"/"} component={Landing} />
          {/* <Route exact path="/app#/home" render={() => <Redirect to="/" />} /> */}
          <Route exact path={"/app"} component={AdminPanel} />
        </Switch>
      </div>
    </Router>
  );
}

export default withTranslation()(App);
