import React, { useState } from "react";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import Lock from "@material-ui/icons/Lock";
import {
  Notification,
  useNotify,
  defaultTheme,
  Loading,
  useRedirect,
} from "react-admin";
import { Link } from "@material-ui/core";
import {
  Avatar,
  TextField,
  Button,
  Card,
  CardActions,
} from "@material-ui/core";
import {
  getValidEmail,
  getValidPhone,
  isValidPhone,
  isValidEmail,
} from "../utils/validator";
import { useHistory } from "react-router";
import { getColorsFromTheme, styles } from "../utils/authFormStyle";
import { forgotPasswordUser } from "../utils/useUser";

const ForgotPassword = ({ theme }) => {
  let history = useHistory();
  const redirect = useRedirect();
  const notify = useNotify();
  const [loading, setLoading] = useState(false);

  const [username, setUsername] = useState("");
  const [result, setResult] = useState("empty");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [buttonText, setButtonText] = useState("Send reset link");

  // const [nameError, setNameError] = useState(false);
  const [usernameError, setUsernameError] = useState(false);
  // const [passwordError, setPasswordError] = useState(false);

  const forgotPasswordTheme = createMuiTheme(defaultTheme);
  const { primary1Color, accent1Color } =
    getColorsFromTheme(forgotPasswordTheme);

  const handleUsernameChange = (value) => {
    setUsername(value);
    setPhone(getValidPhone(value));
    setEmail(getValidEmail(value));
    const isPhone = isValidPhone(value);
    const isEmail = isValidEmail(value);

    setUsernameError((er) => !isPhone && !isEmail);
    setButtonText((t) =>
      isPhone ? "Send Reset Code" : isEmail ? "Send Reset Email" : "Reset"
    );
  };

  const submit = (e) => {
    e.preventDefault();
    // const handleClick = () => {
    setLoading(true);

    forgotPasswordUser({ phone, email })
      .then(() => {
        notify(buttonText + " successful");
        if (email) setResult((r) => "success");
        if (phone) redirect("/phone-reset-password");
      })
      .catch((e) => {
        notify(buttonText + " failed", { type: "warning" });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (loading) return <Loading />;

  return (
    <ThemeProvider theme={forgotPasswordTheme}>
      <div style={styles.main}>
        <Card style={styles.card}>
          <div style={styles.avatar}>
            <Avatar backgroundcolor={accent1Color} icon={<Lock />} size={60} />
          </div>
          <form name="loginForm">
            <div style={styles.form}>
              <p style={styles.hint}>Reset your password</p>
              {result === "success" ? (
                <p style={styles.hint}>
                  A reset link has been sent to your email
                </p>
              ) : null}
              {result !== "success" ? (
                <div style={styles.input}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    name="username"
                    label="username"
                    helperText="Enter your email or phone number in valid format eg. 911223344 or name@email.com"
                    required
                    error={usernameError}
                    defaultValue={username}
                    onChange={(e) => handleUsernameChange(e.target.value)}
                  />
                </div>
              ) : null}
              {result !== "success" ? (
                <div style={styles.input}>
                  <Button
                    color="primary"
                    variant="contained"
                    size="large"
                    // disabled={submitting}
                    fullWidth
                    onClick={submit}
                  >
                    {buttonText}
                  </Button>
                </div>
              ) : null}
              <CardActions>
                <div style={styles.link}>
                  <Link
                    onClick={() => {
                      history.push("/login");
                    }}
                  >
                    Back to login
                  </Link>
                </div>
              </CardActions>
            </div>
          </form>
        </Card>
        <Notification />
      </div>
    </ThemeProvider>
  );
};

export default ForgotPassword;
