import React, { useState, useRef } from "react";
import { useRecordContext, useInput } from "react-admin";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  FeatureGroup,
  Polygon,
  Tooltip,
} from "react-leaflet";
// import { EditControl } from "react-leaflet-draw";
import EditControl from "../components/EditControl";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";

// const getCoordinates = (coordinates) => {
//   if (!coordinates) return [0, 0];
//   const array = coordinates.split(",");
//   return [array[0] ? array[0] : 0, array[1] ? array[1] : 0];
// };

const outerBounds = [[9.013431657356811, 38.76323997308592]];

const getCoordinates = (coordinates) => {
  if (!coordinates) return [0, 0];
  if (!coordinates.includes(",")) return [0, 0];
  const array = coordinates.split(",");
  return [array[0] ? array[0] : 0, array[1] ? array[1] : 0];
};

const MapField = ({ source }) => {
  const record = useRecordContext();
  const data = record[source];
  return (
    <MapContainer
      center={getCoordinates(data)}
      zoom={13}
      boundsOptions={{ padding: [50, 50] }}
      scrollWheelZoom={true}
      style={{ height: "50vh", marginBottom: "18px" }}
    >
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {data ? (
        // ? data.map((v) => (
        <Marker key={data.id} position={getCoordinates(data)}>
          <Popup>
            {record["tag"]}
            <br />
            {record["model"]}
            <br />
            {record["plate"]}
          </Popup>
        </Marker>
      ) : // ))
      null}
    </MapContainer>
  );
};

const MapAreaField = ({ source }) => {
  const record = useRecordContext();
  const data = record[source];
  return (
    <MapContainer
      bounds={
        data.length > 0 ? data.map((v) => getCoordinates(v)) : outerBounds
      }
      // center={getCoordinates(data)}
      zoom={13}
      boundsOptions={{ padding: [50, 50] }}
      scrollWheelZoom={true}
      style={{ height: "50vh", marginBottom: "18px" }}
    >
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />

      {data ? (
        // ? data.map((v) => (

        <Polygon
          pathOptions={{ color: "purple" }}
          positions={data.map((d) => getCoordinates(d))}
        >
          <Tooltip sticky> {record["areaName"]}</Tooltip>
        </Polygon>
      ) : // <Polygon positions={data} color="blue" />
      // <Polygon key={data.id} position={getCoordinates(data)}>
      //   <Popup>
      //     {record["areaName"]}
      //     <br />
      //   </Popup>
      // </Polygon>
      // ))
      null}
    </MapContainer>
  );
};

const MapFenceInput = (props) => {
  const {
    input: { name, value, onChange, ...rest },
    // meta: { touched, error },
    // isRequired,
    // source,
  } = useInput(props);

  // draw start
  const points = value ? value.map((point) => getCoordinates(point)) : [];
  // const [center, setCenter] = useState({ lat: 24.4539, lng: 54.3773 });
  // const [mapLayers, setMapLayers] = useState(points);
  const featureGroupRef = useRef();
  // const [editableFG, setEditableFG] = useState(null);

  const ZOOM_LEVEL = 12;

  const _onCreated = (e) => {
    console.log("_onCreated", e);
    console.log(featureGroupRef);

    const drawnItems = featureGroupRef.current._layers;
    console.log(drawnItems);
    if (Object.keys(drawnItems).length > 1) {
      Object.keys(drawnItems).forEach((layerid, index) => {
        if (index > 0) return;
        const layer = drawnItems[layerid];
        featureGroupRef.current.removeLayer(layer);
      });
      console.log(drawnItems);
    }
    const { layerType, layer } = e;
    if (layerType === "polygon" || layerType === "rectangle") {
      // const { _leaflet_id } = layer;
      const fenceValues = layer
        .getLatLngs()[0]
        .map((latlng) => `${latlng.lat},${latlng.lng}`);
      console.log(fenceValues);

      onChange(fenceValues);
      // console.log(mapLayers);
      // setMapLayers((layers) => [
      //   // ...layers,
      //   { id: _leaflet_id, latlngs: layer.getLatLngs()[0] },
      // ]);
    } else {
      console.log("invalid geofence");
      onChange([]);
    }
  };

  const _onEdited = (e) => {
    const {
      layers: { _layers },
    } = e;

    console.log(e);
    // const { layerType, layer } = e;
    const fenceValues = Object.values(_layers)[0]
      .getLatLngs()[0]
      .map((latlng) => `${latlng.lat},${latlng.lng}`);
    console.log(fenceValues);
    onChange(fenceValues);
    // Object.values(_layers).map(({ _leaflet_id, editing }) => {
    //   setMapLayers((layers) =>
    //     layers.map((l) =>
    //       l.id === _leaflet_id
    //         ? { ...l, latlngs: { ...editing.latlngs[0] } }
    //         : l
    //     )
    //   );
    // });
  };

  const _onDeleted = (e) => {
    console.log("_onDeleted", e);
    onChange([]);
  };

  const getBounds = (positions) => {
    return positions.length > 0
      ? positions
      : [
          [8, 38],
          [10, 39],
        ];
  };

  const drawOption = {
    rectangle: true,
    polyline: true,
    circle: false,
    circlemarker: false,
    marker: false,
    // polyline: false,
  };

  // end draw
  return (
    <MapContainer
      bounds={getBounds(points)}
      zoom={ZOOM_LEVEL}
      boundsOptions={{ padding: [50, 50] }}
      scrollWheelZoom={true}
      style={{ height: "50vh", marginBottom: "18px" }}
    >
      <FeatureGroup ref={featureGroupRef}>
        <EditControl
          position="topright"
          onEdited={_onEdited}
          onCreated={_onCreated}
          onDeleted={_onDeleted}
          draw={drawOption}
        />

        <Polygon positions={points}>
          <Tooltip sticky>GeoFence for vehicle</Tooltip>
        </Polygon>
      </FeatureGroup>
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
    </MapContainer>
  );
};

export { MapField, MapAreaField, MapFenceInput };
