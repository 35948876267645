import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  SelectInput,
  NumberInput,
  SelectArrayInput,
} from "react-admin";
import HorizontalInputStyle from "./HorizontalInputStyle";

// const notification = [
//   { id: "CALL", name: "CALL" },
//   { id: "SMS", name: "SMS" },
//   { id: "PUSH", name: "PUSH" },
// ];

const userStatus = [
  { id: "owner", name: "owner" },
  { id: "admin", name: "admin" },
  { id: "manager", name: "manager" },
  { id: "customer", name: "customer" },
  { id: "agent", name: "agent" },
  { id: "prospect", name: "prospect" },
  { id: "lead", name: "lead" },
  { id: "follower", name: "follower" },
];

const contentStatus = [
  { id: "active", name: "active" },
  { id: "paused", name: "paused" },
  { id: "finished", name: "finished" },
];

const method = [
  { id: "SMS", name: "SMS" },
  { id: "TELEGRAM", name: "TELEGRAM" },
];

// const defaultNotification = notification.map((notification) => notification.id);

const ContentCreate = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source="title" />
        <TextInput
          source="message"
          required
          defaultValue={`ጤና ይስጥልን {name}
          
ቴዘር።`}
          multiline
          fullWidth
        />

        <SelectInput
          source="messageStatus"
          style={HorizontalInputStyle}
          choices={contentStatus}
          required
        />
        <SelectArrayInput
          source="method"
          label="Send by"
          style={HorizontalInputStyle}
          choices={method}
          required
        />
        <SelectArrayInput
          source="userType"
          label="Users to send to"
          style={HorizontalInputStyle}
          choices={userStatus}
          required
        />
        <NumberInput source="hits" />
      </SimpleForm>
    </Edit>
  );
};

export default ContentCreate;
