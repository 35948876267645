import { Loading } from "react-admin";
import AdminDashboard from "./AdminDashboard";
import UserDashboard from "./UserDashboard";
const ROLE = {
  OWNER: "owner",
  ADMIN: "admin",
  MANAGER: "manager",
  AGENT: "agent",
  CUSTOMER: "customer",
  PROSPECT: "prospect",
  SALES: "sales",
};
function isTeam(permissions) {
  return (
    permissions.role === ROLE.OWNER ||
    permissions.role === ROLE.ADMIN ||
    permissions.role === ROLE.MANAGER
  );
}

export const Dashboard = (props) => {
  return props.permissions ? (
    isTeam(props.permissions) ? (
      <AdminDashboard {...props} />
    ) : (
      <UserDashboard {...props} />
    )
  ) : (
    <Loading />
  );
};
