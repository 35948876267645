import React from "react";
import {
  Datagrid,
  TabbedShowLayout,
  Tab,
  Show,
  TextField,
  ChipField,
  ReferenceManyField,
  FunctionField,
  DateField,
  EditButton,
} from "react-admin";
import MapPanel from "../components/MapPanel";
import { formattedLastTime } from "./formatter";

const CohortShow = (props) => {
  return (
    <Show {...props}>
      <TabbedShowLayout>
        <Tab label="Cohort vehicles">
          <TextField source="cohortName" />
          <ReferenceManyField reference="vehicles" target="cohortRef">
            <Datagrid expand={<MapPanel />} style={{ tableLayout: "fixed" }}>
              <TextField source="plate" />
              <TextField source="brand" />
              <TextField source="model" />
              <TextField source="color" />
              <ChipField source="serviceStatus" label="Service" />
              <TextField source="status" label="State" />
              <FunctionField
                label="last signal"
                render={(record) => `${formattedLastTime(record.lastLog)}`}
              />
              <DateField source="subscriptionExpiryDate" label="Expires on" />
              <EditButton label="Controls" basePath="/assets" />
            </Datagrid>
          </ReferenceManyField>
        </Tab>

        <Tab label="Details">
          <TextField source="cohortName" />
          <TextField source="cohortDescription" />
          <ChipField source="status" />
          <TextField source="note" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default CohortShow;
