import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  SelectInput,
  required,
  DateInput,
  ReferenceInput,
} from "react-admin";

const userStatus = [
  { id: "active", name: "active" },
  { id: "expired", name: "expired" },
  { id: "suspended", name: "suspended" },
  { id: "fresh", name: "fresh" },
];

const userOptionRenderer = (choice) => `${choice.name} - ${choice.phone}`;

const CompanyEdit = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput disabled source="id" />
        <TextInput required source="companyName" />
        <TextInput required source="email" />
        <TextInput required source="phone" />
        <ReferenceInput
          label="Owner"
          source="ownerRef"
          reference="users"
          validate={[required()]}
        >
          <SelectInput optionText={userOptionRenderer} />
        </ReferenceInput>
        <DateInput required source="registerDate" disabled />

        <SelectInput
          required
          source="status"
          label="Status"
          choices={userStatus}
        />
        <TextInput source="note" disabled />
      </SimpleForm>
    </Edit>
  );
};

export default CompanyEdit;
