import {
  Filter,
  TextInput,
  AutocompleteInput,
  ReferenceInput,
} from "react-admin";

export const userOptionRenderer = (choice) =>
  choice ? `${choice.name} - ${choice.phone}` : "";

export const userFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search by DataCode" source="id" alwaysOn />

    {/* <ReferenceInput
      label="Owner"
      source="ownerRef"
      reference="customers"
      sort={{ field: "phone", order: "ASC" }}
      filterToQuery={(searchText) => ({ phone: searchText })}
      allowEmpty={true}
      alwaysOn
    >
      <AutocompleteInput optionText="phone" />
    </ReferenceInput> */}
  </Filter>
);
