import React from "react";

const SummaryText = (props) => {
  const { top, middle, bottom, tint } = props;
  return (
    <div className="summary-container">
      <div className="summary-top" style={{ color: `${tint}99` }}>
        {top}
      </div>

      <div className="summary-middle" style={{ color: `${tint}` }}>
        {middle}
      </div>

      <div className="summary-bottom" style={{ color: `${tint}99` }}>
        {bottom}
      </div>
    </div>
  );
};

export default SummaryText;
