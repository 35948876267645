import React, { useState } from "react";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import Lock from "@material-ui/icons/Lock";
import {
  Notification,
  useNotify,
  defaultTheme,
  Loading,
  useRedirect,
} from "react-admin";
import { Link } from "@material-ui/core";
import {
  Avatar,
  TextField,
  Button,
  Card,
  CardActions,
} from "@material-ui/core";
import { isValidPassword } from "../utils/validator";
import { useHistory } from "react-router";
import { getColorsFromTheme, styles } from "../utils/authFormStyle";
import { verifyResetOTPUser, changeUserPasswordOTP } from "../utils/useUser";

const ChangePasswordOTP = ({ theme }) => {
  let history = useHistory();
  const successText =
    "We have successfully verified your email. You can now proceed to your dashboard.";
  const failText =
    "The reset password token have expired or is unavailable. Go to reset password page and try again.";
  const defaultText = "The 6 digit number sent to your phone";
  const titleTextVerify = "Verify you own the phone number";
  const titleTextChange = "Your new password";

  const redirect = useRedirect();
  const notify = useNotify();
  const [loading, setLoading] = useState(false);

  const [otp, setOtp] = useState("");
  const [password, setPassword] = useState("");
  const [cpassword, setCpassword] = useState("");

  const [OTPError, setOTPError] = useState(false);
  const [cpasswordError, setCpasswordError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [result, setResult] = useState("none");
  const [helper, setHelper] = useState(defaultText);
  const [VerificationRes, setVerificationRes] = useState(failText);

  const registerTheme = createMuiTheme(defaultTheme);
  const { primary1Color, accent1Color } = getColorsFromTheme(registerTheme);

  const handleOtpChange = (value) => {
    setOtp((o) => value);
    setOTPError(false);
    setHelper(defaultText);
    console.log(value);
    if (value.length === 6) verifyResetOTP(value);
  };

  const verifyResetOTP = (value) => {
    setLoading(true);

    setResult((result) => "none");

    verifyResetOTPUser({ otp: value })
      .then(() => {
        setResult((result) => "success");
        setOTPError(false);
        setHelper(defaultText);
        setVerificationRes((VerificationRes) => successText);
      })
      .catch((e) => {
        setResult((result) => "fail");
        setOTPError(true);
        setHelper(failText);
        setVerificationRes((VerificationRes) => failText);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handlePasswordChange = (value) => {
    setPassword(value);
    setPasswordError((er) => !isValidPassword(value));
  };

  const handleCpasswordChange = (value) => {
    setCpassword(value);
    setCpasswordError((er) => !isValidPassword(value) || value !== password);
  };

  const submit = (e) => {
    e.preventDefault();
    // const handleClick = () => {
    setLoading(true);

    changeUserPasswordOTP({ otp, password })
      .then(() => {
        notify("Password change successful");
        redirect("/login");
      })
      .catch((e) => {
        notify("Password change failed " + e.message, { type: "warning" });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (loading) return <Loading />;

  return (
    <ThemeProvider theme={registerTheme}>
      <div style={styles.main}>
        <Card style={styles.card}>
          <div style={styles.avatar}>
            <Avatar backgroundcolor={accent1Color} icon={<Lock />} size={60} />
          </div>
          <form name="changePasswordOTPForm">
            <div style={styles.form}>
              <p style={styles.hint}>
                {result !== "success" ? titleTextVerify : titleTextChange}
              </p>

              {result !== "success" ? (
                <div style={styles.input}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    name="otp"
                    label="verification code"
                    helperText={helper}
                    required
                    error={OTPError}
                    defaultValue={otp}
                    onChange={(e) => handleOtpChange(e.target.value)}
                  />
                </div>
              ) : null}

              {result === "success" ? (
                <div>
                  <div style={styles.input}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      name="password"
                      label="new password"
                      type="password"
                      helperText="Password must be more than 6 chars long and must include special characters and capital letters"
                      required
                      error={passwordError}
                      defaultValue={password}
                      onChange={(e) => handlePasswordChange(e.target.value)}
                    />
                  </div>
                  <div style={styles.input}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      name="confirmpassword"
                      label="confirm password"
                      type="password"
                      helperText="Password must be valid and match with the above field"
                      required
                      error={cpasswordError}
                      defaultValue={cpassword}
                      onChange={(e) => handleCpasswordChange(e.target.value)}
                    />
                  </div>
                  <div style={styles.input}>
                    <Button
                      color="primary"
                      variant="contained"
                      size="large"
                      // disabled={submitting}
                      fullWidth
                      onClick={submit}
                    >
                      Change password
                    </Button>
                  </div>
                </div>
              ) : null}
              <CardActions>
                <div style={styles.link}>
                  <Link
                    onClick={() => {
                      history.push("/forgot-password");
                    }}
                  >
                    Reset password
                  </Link>
                </div>
              </CardActions>
            </div>
          </form>
        </Card>
        <Notification />
      </div>
    </ThemeProvider>
  );
};

export default ChangePasswordOTP;
