import React from "react";
import {
  List,
  Datagrid,
  TextField,
  FunctionField,
  ReferenceField,
  ChipField,
} from "react-admin";
import { formattedLastTime } from "./formatter";

import { makeStyles } from "@material-ui/core/styles";
import classnames from "classnames";

const useStyles = makeStyles({
  done: { color: "green" },
  active: { color: "red" },
});

const ColoredChipField = (props) => {
  const classes = useStyles();
  return (
    <ChipField
      className={classnames({
        [classes.active]: props.record[props.source] === "active",
        [classes.done]: props.record[props.source] === "done",
      })}
      {...props}
    />
  );
};

// Ensure the original component defaultProps are still applied as they may be used by its parents (such as the `Show` component):
ColoredChipField.defaultProps = ChipField.defaultProps;

const NotificationsList = (props) => {
  return (
    <List {...props}>
      <Datagrid>
        <ReferenceField label="Language" source="userRef" reference="users">
          <TextField source="language" />
        </ReferenceField>
        <TextField source="notificationType" label="Alert" />
        <TextField source="phone" />
        <ReferenceField label="User" source="userRef" reference="users">
          <TextField source="name" />
        </ReferenceField>
        <TextField source="violationType" label="Violation" />
        <TextField source="vehiclePlate" label="Plate" />
        <ReferenceField label="Model" source="vehicleRef" reference="vehicles">
          <TextField source="model" />
        </ReferenceField>
        {/* <TextField source="title" />
        <TextField source="body" /> */}

        <ColoredChipField source="status" />
        <FunctionField
          label="time elapsed"
          render={(record) => `${formattedLastTime(record.eventDate)}`}
        />
        {/* <BooleanField
          source="isLocked"
          label="State"
          TrueIcon={Lock}
          FalseIcon={Schedule}
        /> */}

        <ReferenceField label="Handler" source="isLocked" reference="users">
          {/* <FunctionField
            label=""
            render={(record) => (record === "" ? Schedule : Lock)}
          /> */}
          <TextField source="name" />
        </ReferenceField>
        {/* <EditButton label="Edit" basePath="/notifications" /> */}
        {/* <DeleteButton label="Delete" basePath="/notifications" /> */}
      </Datagrid>
    </List>
  );
};

export default NotificationsList;
