import { format } from "date-fns";

const getElapsedDays = (lastDate) => {
  if (!lastDate) return Number.MAX_VALUE;
  let diffInMilliSeconds = Math.abs(Date.now() - Date.parse(lastDate)) / 1000;
  return Math.floor(diffInMilliSeconds / 86400);
};

const formattedLastTime = (lastDate, showLive = true) => {
  if (!lastDate) return "";

  let diffInMilliSeconds = Math.abs(Date.now() - Date.parse(lastDate)) / 1000;

  if (diffInMilliSeconds < 180 && showLive) return "Just now";

  // calculate days
  const days = Math.floor(diffInMilliSeconds / 86400);
  diffInMilliSeconds -= days * 86400;

  // calculate hours
  const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
  diffInMilliSeconds -= hours * 3600;

  // calculate minutes
  const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
  diffInMilliSeconds -= minutes * 60;

  // calculate seconds
  const seconds = Math.floor(diffInMilliSeconds) % 60;
  diffInMilliSeconds -= seconds;

  let difference = "";
  if (days > 0) {
    difference += days === 1 ? `${days} day ` : `${days} days `;
  } else {
    if (hours > 0)
      difference += hours === 1 ? `${hours} hr, ` : `${hours} hrs, `;

    if (minutes > 0)
      difference += minutes === 1 ? `${minutes} mins, ` : `${minutes} mins, `;

    difference +=
      seconds === 0 || seconds === 1 ? `${seconds} sec` : `${seconds} secs`;
  }

  return difference;
};

const formattedTime = (time, longForm = true) => {
  if (!time) return longForm ? "none" : "00:00:00";

  let diffInMilliSeconds = Math.abs(time) / 1000;

  // calculate days
  const days = Math.floor(diffInMilliSeconds / 86400);
  diffInMilliSeconds -= days * 86400;

  // calculate hours
  const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
  diffInMilliSeconds -= hours * 3600;

  // calculate minutes
  const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
  diffInMilliSeconds -= minutes * 60;

  // calculate seconds
  const seconds = Math.floor(diffInMilliSeconds) % 60;
  diffInMilliSeconds -= seconds;

  let difference = "";
  if (days > 0) {
    difference += longForm
      ? days === 1
        ? `${days} day `
        : `${days} days `
      : `${days}d `;
  } else {
    if (hours > 0 || !longForm)
      difference += longForm
        ? hours === 1
          ? `${hours} hr, `
          : `${hours} hrs, `
        : `${pad2(hours)}:`;

    if (minutes > 0 || !longForm)
      difference += longForm
        ? minutes === 1
          ? `${minutes} mins, `
          : `${minutes} mins, `
        : `${pad2(minutes)}:`;
    difference += longForm
      ? seconds === 0 || seconds === 1
        ? `${seconds} sec`
        : `${seconds} secs`
      : `${pad2(seconds)}`;
  }

  return difference;
};

const formattedDate = (dateStr, formatString = "E..EEE MMM do, YY") => {
  var date = new Date(dateStr);

  var formattedDate = format(date, formatString);
  return formattedDate;
};

function pad2(number) {
  return (number < 10 ? "0" : "") + number;
}

const formatToDecimal = (num, decimal) => {
  return (Math.round(num * 100) / 100).toFixed(decimal);
};

function formatMoney(amount, decimalCount = 2, decimal = ".", thousands = ",") {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-" : "";

    let i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
    ).toString();
    let j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : "") +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : "")
    );
  } catch (e) {
    console.log(e);
  }
}

export {
  formattedLastTime,
  formattedTime,
  formattedDate,
  formatToDecimal,
  getElapsedDays,
  formatMoney,
};
