import { format } from "date-fns";
import { formatToDecimal, getElapsedDays } from "../app/formatter";

// const barData = [
//   {
//     name: "Page A",
//     uv: 4000,
//     pv: 2400,
//     amt: 2400,
//   },
//   {
//     name: "Page B",
//     uv: 3000,
//     pv: 1398,
//     amt: 2210,
//   },
//   {
//     name: "Page C",
//     uv: 2000,
//     pv: 9800,
//     amt: 2290,
//   },
//   {
//     name: "Page D",
//     uv: 2780,
//     pv: 3908,
//     amt: 2000,
//   },
//   {
//     name: "Page E",
//     uv: 1890,
//     pv: 4800,
//     amt: 2181,
//   },
//   {
//     name: "Page F",
//     uv: 2390,
//     pv: 3800,
//     amt: 2500,
//   },
//   {
//     name: "Page G",
//     uv: 3490,
//     pv: 4300,
//     amt: 2100,
//   },
// ];

// const quarterData = [
//   {
//     date: "2000-01",
//     uv: 4000,
//     pv: 2400,
//     amt: 2400,
//   },
//   {
//     date: "2000-02",
//     uv: 3000,
//     pv: 1398,
//     amt: 2210,
//   },
//   {
//     date: "2000-03",
//     uv: 2000,
//     pv: 9800,
//     amt: 2290,
//   },
//   {
//     date: "2000-04",
//     uv: 2780,
//     pv: 3908,
//     amt: 2000,
//   },
//   {
//     date: "2000-05",
//     uv: 1890,
//     pv: 4800,
//     amt: 2181,
//   },
//   {
//     date: "2000-06",
//     uv: 2390,
//     pv: 3800,
//     amt: 2500,
//   },
//   {
//     date: "2000-07",
//     uv: 3490,
//     pv: 4300,
//     amt: 2100,
//   },
//   {
//     date: "2000-08",
//     uv: 4000,
//     pv: 2400,
//     amt: 2400,
//   },
//   {
//     date: "2000-09",
//     uv: 3000,
//     pv: 1398,
//     amt: 2210,
//   },
//   {
//     date: "2000-10",
//     uv: 2000,
//     pv: 9800,
//     amt: 2290,
//   },
//   {
//     date: "2000-11",
//     uv: 2780,
//     pv: 3908,
//     amt: 2000,
//   },
//   {
//     date: "2000-12",
//     uv: 1890,
//     pv: 4800,
//     amt: 2181,
//   },
// ];

// const pieData = [
//   { name: "Group A", value: 400 },
//   { name: "Group B", value: 300 },
//   { name: "Group C", value: 300 },
//   { name: "Group D", value: 200 },
// ];

function getDashBoardData(data) {
  return {
    totalAssetCount: getAssetCount(data),
    onlineAssetCount: getConnectedAssetCount(data, 0, 3),
    offlineAssetCount: getConnectedAssetCount(data, 3, 21),
    disconnectedAssetCount: getConnectedAssetCount(data, 21, 1000),

    heatMapData: getHeatMap(data),

    // tested
    mileageTotalData: getMileageTotal(data),

    mileageAverageData: getMileageAvarage(data),
    mileageTopData: getMileageTop(data),
    mileageBottomData: getMileageBottom(data),

    idlingTopData: getIdlingTop(data),
    idlingBottomData: getIdlingBottom(data),

    parkedTopData: getParkedTop(data),
    parkedBottomData: getParkedBottom(data),

    violationsData: getViolations(data),
    topSpeedViolatorsData: getSpeedViolationsTop(data),
    topFenceViolatorsData: getFenceViolationsTop(data),

    avgTravelPerDayInKms: getAvgTravelPerDayInKms(data),
    avgTravelPerDayInHrs: getAvgTravelPerDayInHrs(data),
    speedViolationRate: getSpeedViolationRate(data),
    fenceViolationRate: getFenceViolationRate(data),
  };
}

function getAssetCount(data) {
  return data.length;
}

function getConnectedAssetCount(data, minDays, maxDays) {
  return data.filter((d) => {
    const elapsed = getElapsedDays(d.lastLog);
    return elapsed >= minDays && elapsed <= maxDays;
  }).length;
}

function getHeatMap(data) {
  const points = data
    .map((d) => d.log.map((l) => l.coordinate.split(",")))
    .flat(1)
    .map((point, i) => [i, point[0], point[1]]);
  return points;
}

function getMileageTotal(data) {
  // iterate date
  var now = new Date();
  var start = new Date().minusDays(3);
  var mileageData = [];
  for (var date = start; date <= now; date = date.plusDays(1)) {
    const vName = formattedDate(date, "yyyy-MM-dd");

    var mileage = [];
    const vehicles = data; //JSON.parse(data);
    vehicles.forEach((v) => {
      const r = v.report.filter((re) => re.date === vName);
      mileage.push({
        [v.plate]:
          r.length > 0 ? formatToDecimal(r[0].mileageInMeters / 1000) : 0,
      });
    });

    mileage.push({ name: vName });
    const value = mileage.reduce(function (result, item) {
      var key = Object.keys(item)[0]; //first property: a, b, c
      result[key] = item[key];
      return result;
    }, {});

    mileageData.push(value);
  }
  return mileageData;
}

function getMileageAvarage(data) {
  var now = new Date();
  var start = new Date().minusDays(3);
  var mileageData = [];
  for (var date = start; date <= now; date = date.plusDays(1)) {
    const vName = formattedDate(date, "yyyy-MM-dd");

    var mileage = [];
    var mileageAvg = [];
    const vehicles = data; //JSON.parse(data);
    vehicles.forEach((v) => {
      const r = v.report.filter((re) => re.date === vName);
      mileageAvg.push({
        distance:
          r.length > 0 ? formatToDecimal(r[0].mileageInMeters / 1000) : 0,
      });
    });

    mileage.push({ name: vName });

    // const value = mileage.reduce(function (result, item) {
    //   var key = Object.keys(item)[0]; //first property: a, b, c
    //   result[key] = item[key];
    //   return result;
    // }, {});

    mileageData.push(average(mileageAvg).toFixed(2));
  }
  return mileageData;
}

function getMileageTop(data) {
  var now = new Date();
  var start = new Date().minusDays(3);
  var mileageData = [];
  for (var date = start; date <= now; date = date.plusDays(1)) {
    const vName = formattedDate(date, "yyyy-MM-dd");

    var mileage = [];
    const vehicles = data; //JSON.parse(data);
    vehicles
      .sort(
        (a, b) =>
          b.report.filter((re) => re.date === vName) -
          a.report.filter((re) => re.date === vName)
      )
      .slice(0, vehicles.length > 5 ? 5 : vehicles.length)
      .forEach((v) => {
        const r = v.report.filter((re) => re.date === vName);
        mileage.push({
          [v.plate]:
            r.length > 0 ? formatToDecimal(r[0].mileageInMeters / 1000) : 0,
        });
      });

    mileage.push({ name: vName });
    const value = mileage.reduce(function (result, item) {
      var key = Object.keys(item)[0]; //first property: a, b, c
      result[key] = item[key];
      return result;
    }, {});

    mileageData.push(value);
  }
  console.log(mileageData);
  return mileageData;
}

function getMileageBottom(data) {
  var now = new Date();
  var start = new Date().minusDays(3);
  var mileageData = [];
  for (var date = start; date <= now; date = date.plusDays(1)) {
    const vName = formattedDate(date, "yyyy-MM-dd");

    var mileage = [];
    const vehicles = data; //JSON.parse(data);
    vehicles
      .sort(
        (a, b) =>
          b.report.filter((re) => re.date === vName) -
          a.report.filter((re) => re.date === vName)
      )
      .slice(vehicles.length > 5 ? vehicles.length - 5 : 0, vehicles.length)
      .forEach((v) => {
        const r = v.report.filter((re) => re.date === vName);
        mileage.push({
          [v.plate]:
            r.length > 0 ? formatToDecimal(r[0].mileageInMeters / 1000) : 0,
        });
      });

    mileage.push({ name: vName });
    const value = mileage.reduce(function (result, item) {
      var key = Object.keys(item)[0]; //first property: a, b, c
      result[key] = item[key];
      return result;
    }, {});

    mileageData.push(value);
  }
  console.log(mileageData);
  return mileageData;
}

function getIdlingTop(data) {
  // { name: "Group A", value: 400 },
  // { name: "Group B", value: 300 },
  // { name: "Group C", value: 300 },
  // { name: "Group D", value: 200 },
  return data;
}

function getIdlingBottom(data) {
  // { name: "Group A", value: 400 },
  // { name: "Group B", value: 300 },
  // { name: "Group C", value: 300 },
  // { name: "Group D", value: 200 },
  return data;
}

function getParkedTop(data) {
  return data;
}

function getParkedBottom(data) {
  return data;
}

function getViolations(data) {
  var now = new Date();
  var start = new Date().minusDays(3);
  var violationData = [];
  for (var date = start; date <= now; date = date.plusDays(1)) {
    const vName = formattedDate(date, "yyyy-MM-dd");

    // var mileage = [];
    // var mileage = [];
    let speedViolation = 0;
    let fenceViolation = 0;
    const vehicles = data; //JSON.parse(data);
    vehicles.forEach((v) => {
      const r = v.report.filter((re) => re.date === vName);
      if (r.length > 0) {
        speedViolation += r[0].speed_violation;
        fenceViolation += r[0].fence_violation;
      }
      // mileage.push({
      //   [v.plate]:
      //     r.length > 0 ? formatToDecimal(r[0].mileageInMeters / 1000) : 0,
      // });
    });

    violationData.push({
      name: vName,
      speed: speedViolation,
      geofence: fenceViolation,
    });
    // const value = mileage.reduce(function (result, item) {
    //   var key = Object.keys(item)[0]; //first property: a, b, c
    //   result[key] = item[key];
    //   return result;
    // }, {});

    // violationData.push(value);
  }
  return violationData;
}

function getSpeedViolationsTop(data) {
  var vehicleData = [];
  data.forEach((v) => {
    var speedViolation = 1;
    v.report.forEach((r) => {
      speedViolation += r.fence_violation;
    });
    vehicleData.push({ name: v.plate, value: speedViolation });
  });

  vehicleData.sort(function (a, b) {
    return a.value - b.value;
  });
  return vehicleData;
}

function getFenceViolationsTop(data) {
  var vehicleData = [];
  data.forEach((v) => {
    var fenceViolation = 1;
    v.report.forEach((r) => {
      fenceViolation += r.fence_violation;
    });
    vehicleData.push({ name: v.plate, value: fenceViolation });
  });

  vehicleData.sort(function (a, b) {
    return a.value - b.value;
  });
  return vehicleData;
}

function getAvgTravelPerDayInKms(data) {
  var now = new Date();
  var start = new Date().minusDays(2);
  var mileageData = [];
  for (var date = start; date <= now; date = date.plusDays(1)) {
    const vName = formattedDate(date, "yyyy-MM-dd");

    var mileage = 0;
    const vehicles = data; //JSON.parse(data);
    vehicles.forEach((v) => {
      const r = v.report.filter((re) => re.date === vName);
      if (r.length > 0)
        if (r[0].mileageInMeters) mileage += r[0].mileageInMeters;
    });

    mileageData.push(mileage / 1000);
  }
  var mileageAvgData = `${average(mileageData).toFixed(0)}kms`;

  console.log("mileageAvgData", mileageData);
  return mileageAvgData;
}

function getAvgTravelPerDayInHrs(data) {
  var now = new Date();
  var start = new Date().minusDays(3);
  var hourData = [];
  for (var date = start; date < now; date = date.plusDays(1)) {
    const vName = formattedDate(date, "yyyy-MM-dd");

    var minutes = 0;
    const vehicles = data; //JSON.parse(data);
    vehicles.forEach((v) => {
      const r = v.report.filter((re) => re.date === vName);
      if (r.length > 0)
        if (r[0].movingInMinutes) minutes += r[0].movingInMinutes;
    });

    hourData.push(minutes / 60);
  }
  var hourAvgData = `${average(hourData).toFixed(0)}hrs`;

  console.log("hourAvgData", hourData);
  return hourAvgData;
}

function getSpeedViolationRate(data) {
  var now = new Date();
  var start = new Date().minusDays(3);
  var speedData = [];
  // var violationData = [];
  for (var date = start; date < now; date = date.plusDays(1)) {
    const vName = formattedDate(date, "yyyy-MM-dd");
    let speedViolation = 0;
    // let fenceViolation = 0;
    const vehicles = data; //JSON.parse(data);
    vehicles.forEach((v) => {
      const r = v.report.filter((re) => re.date === vName);
      if (r.length > 0) {
        speedViolation += r[0].speed_violation;
        // fenceViolation += r[0].fence_violation;
      }
    });

    speedData.push(speedViolation);
  }
  var speedAvgData = `${average(speedData).toFixed(0)}x`;

  return speedAvgData;
}

function getFenceViolationRate(data) {
  var now = new Date();
  var start = new Date().minusDays(3);
  var fenceData = [];
  for (var date = start; date < now; date = date.plusDays(1)) {
    const vName = formattedDate(date, "yyyy-MM-dd");
    // let speedViolation = 0;
    let fenceViolation = 0;
    const vehicles = data; //JSON.parse(data);
    vehicles.forEach((v) => {
      const r = v.report.filter((re) => re.date === vName);
      if (r.length > 0) {
        // speedViolation += r[0].speed_violation;
        fenceViolation += r[0].fence_violation;
      }
    });

    fenceData.push(fenceViolation);
  }
  var speedAvgData = `${average(fenceData).toFixed(0)}x`;

  return speedAvgData;
}

function formattedDate(dateStr, formatString = "MMMM Do, yyyy") {
  var date = new Date(dateStr);

  var formattedDate = format(date, formatString);
  return formattedDate;
}

Date.prototype.plusDays = function (days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};

Date.prototype.minusDays = function (days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() - days);
  return date;
};

var stringToColor = function (str) {
  var hash = 0;
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  var colour = "#";
  for (var j = 0; j < 3; j++) {
    var value = (hash >> (j * 8)) & 0xff;
    colour += ("00" + value.toString(16)).substr(-2);
  }
  return colour;
};

const average = (arr) => arr.reduce((a, b) => a + b, 0) / arr.length;

export { getDashBoardData, formattedDate, stringToColor };
