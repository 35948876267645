import React, { useState } from "react";
import {
  TabbedShowLayout,
  Tab,
  Show,
  TextField,
  ChipField,
  BooleanField,
  ReferenceField,
  FunctionField,
  DateField,
  Button,
  useNotify,
  Toolbar,
  SaveButton,
  useRedirect,
  ShowController,
  ShowView,
} from "react-admin";
import { confirmPayment } from "../api/useRoutes";
import { useRecordContext } from "react-admin";
import { formatMoney, formattedDate } from "./formatter";

const ConfirmButton = (props) => {
  const { record } = props;
  const [loading, setLoading] = useState(false);
  const notify = useNotify();
  const redirect = useRedirect();

  return (
    <Button
      {...props}
      label="Confrim"
      onClick={() => {
        setLoading(true);
        confirmPayment({ id: record.id })
          .then(() => {
            notify("Payment confirmed");
            redirect("/invoices");
          })
          .catch((e) => {
            notify("Payment confirmation failed " + e.message, {
              type: "warning",
            });
          })
          .finally(() => {
            setLoading(false);
          });
      }}
      disabled={loading}
    />
  );
};

const TransactionsField = () => {
  const record = useRecordContext();
  return (
    <ul>
      {record.tags.map((item) => (
        <li key={item.name}>{item.name}</li>
      ))}
    </ul>
  );
};

const InvoiceItemsField = (props) => {
  return (
    <>
      {Array.isArray(props.items) ? (
        <ul>
          {props.items.map((item) => (
            <li key={item.description}>
              {item.description} - {item.amount}
            </li>
          ))}
        </ul>
      ) : (
        "no items"
      )}
    </>
  );
};

const InvoiceToolbar = () => (
  <Toolbar>
    <SaveButton label="Save" />
  </Toolbar>
);

const InvoiceShow = (props) => {
  return (
    <ShowController {...props}>
      {(controllerProps) => (
        <ShowView {...props} {...controllerProps}>
          <TabbedShowLayout>
            <Tab label="Details">
              <TextField source="invoiceNumber" />
              <ReferenceField
                label="Customer"
                source="userRef"
                reference="customers"
                link="show"
                emptyText="No User"
              >
                <TextField source="name" />
              </ReferenceField>
              <ReferenceField
                label="Vehicle"
                source="vehicleRef"
                reference="vehicles"
                link="show"
                emptyText="No Vehicle"
              >
                <TextField source="plate" />
              </ReferenceField>
              <DateField source="dueDate" />

              {/* <TextField source="amount" /> */}

              <TextField source="status" />
              {controllerProps.record &&
                controllerProps.record.status === "confirm" && (
                  <ConfirmButton />
                )}
            </Tab>
            <Tab label="Items">
              {controllerProps.record &&
                Array.isArray(controllerProps.record.items) && (
                  <FunctionField
                    label="Items"
                    render={(record) => (
                      <ul>
                        {record.items.map((item) => (
                          <li key={item.description}>
                            {item.description}: {formatMoney(item.amount, 2)}
                          </li>
                        ))}
                      </ul>
                    )}
                  />
                )}
            </Tab>
            <Tab label="Bank Info">
              <TextField source="PayToName" />
              <TextField source="PayToBankName" />
              <TextField source="PayToAccountName" />
              <TextField source="PayToAccountNumber" />
            </Tab>
            <Tab label="Transactions">
              {controllerProps.record &&
                Array.isArray(controllerProps.record.transaction) && (
                  <FunctionField
                    label="Total transaction"
                    render={(record) => (
                      <ul>
                        {record.transaction.map((item) => (
                          <li key={item.transactionNumber}>
                            {item.transactionNumber}{" "}
                            {formattedDate(item.transactionDate)}:{" "}
                            {item.senderName} via {item.bankName}{" "}
                            {item.transactionAmount}
                          </li>
                        ))}
                      </ul>
                    )}
                  />
                )}
            </Tab>
            <Tab label="Dates">
              <DateField source="issuedDate" />
              <DateField source="nextExpiryDate" />
              <DateField source="dueDate" />
              <DateField source="issuedNoticeDate" />
              <DateField source="reminderNoticeDate" />
              <DateField source="dueNoticeDate" />
              <DateField source="overdueNoticeDate" />
              <DateField source="warningNoticeDate" />
              <DateField source="suspensionNoticeDate" />
            </Tab>
            <Tab label="Notified">
              <BooleanField source="issuedNotified" />
              <BooleanField source="reminderNotified" />
              <BooleanField source="dueNotified" />
              <BooleanField source="overdueNotified" />
              <BooleanField source="warningNotified" />
              <BooleanField source="suspensionNotified" />
            </Tab>
          </TabbedShowLayout>
        </ShowView>
      )}
    </ShowController>
  );
};

export default InvoiceShow;
