// in src/Dashboard.js
import React, { useState, useEffect, useContext } from "react";
import {
  ListContextProvider,
  Loading,
  useQuery,
  useGetOne,
  DataProviderContext,
  Error,
  Link,
  useDataProvider,
} from "react-admin";
import keyBy from "lodash/keyBy";
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  Legend,
  AreaChart,
  XAxis,
  YAxis,
  Area,
  Tooltip,
  CartesianGrid,
  PieChart,
  Sector,
  Pie,
  Cell,
} from "recharts";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
// import TextField from "@material-ui/utils/d";

import {
  MapContainer,
  TileLayer,
  CircleMarker,
  useMapEvents,
} from "react-leaflet";
import { LatLng } from "leaflet";
// import L from "leaflet";
import "leaflet-fullscreen/dist/leaflet.fullscreen.css";
import "leaflet/dist/leaflet.css";
import "leaflet-fullscreen/dist/Leaflet.fullscreen.js";
import "leaflet.tilelayer.colorfilter/src/leaflet-tilelayer-colorfilter.js";
import SummaryText from "./SummaryText";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { Button, Box, Typography, InputAdornment } from "@material-ui/core";
import { getDashBoardData, stringToColor } from "../utils/fleetUtil";
import { formatMoney, formatToDecimal, formattedDate } from "./formatter";
// import HeatmapLayer from "react-leaflet-heatmap-layer";

import { loadUser } from "../utils/useUser";
import {
  getHealthStat,
  getMarketingStat,
  getServerStat,
} from "../api/useRoutes";
import StatText from "./StatText";
import { useLocalStorage } from "../utils/useLocalStorage";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

const COLORS = ["#0088FE", "#00C49F", "#ffc400", "#ff2121"];

const renderAcquisitionActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill="#333"
      >{`${value}`}</text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={18}
        textAnchor={textAnchor}
        fill="#999"
      >
        {`(${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  );
};

const channels = {
  tko: "tiktok",
  fca: "facebook",
  wom: "words",
  gca: "Google",
};

const AdminDashboard = (props) => {
  const { data, loading, error } = useGetOne("dash", "stats");
  const [activePieIndex, setPieActiveIndex] = useState(0);

  const today = new Date();
  const delayedSignalDate = new Date(today.getTime() - 1000 * 60 * 3);
  const warningSignalDate = new Date(today.getTime() - 1000 * 60 * 15);
  const disconnectedSignalDate = new Date(today.getTime() - 1000 * 60 * 60 * 3);
  const malfunctionSignalDate = new Date(
    today.getTime() - 1000 * 60 * 60 * 24 * 15
  );
  const recoverSIMAfterDate = new Date(
    today.getTime() - 1000 * 60 * 60 * 24 * 90
  );

  const allVehicles = `/vehicles?`;
  const activeSignalFilter = `/vehicles?filter=%7B%20%22lastLog%22%3A%20%7B%22%24gte%22%3A%20%22${delayedSignalDate.getTime()}%22%2C%20%22%24lte%22%3A%20%22${today.getTime()}%22%7D%7D&order=ASC&page=1&perPage=10&sort=id`;
  const delaySignalFilter = `/vehicles?filter=%7B%20%22lastLog%22%3A%20%7B%22%24gte%22%3A%20%22${warningSignalDate.getTime()}%22%2C%20%22%24lte%22%3A%20%22${delayedSignalDate.getTime()}%22%7D%7D&order=ASC&page=1&perPage=10&sort=id`;
  const warningSignalFilter = `/vehicles?filter=%7B%20%22lastLog%22%3A%20%7B%22%24gte%22%3A%20%22${disconnectedSignalDate.getTime()}%22%2C%20%22%24lte%22%3A%20%22${warningSignalDate.getTime()}%22%7D%7D&order=ASC&page=1&perPage=10&sort=id`;
  const disconnectedSignalFilter = `/vehicles?filter=%7B%20%22lastLog%22%3A%20%7B%22%24gte%22%3A%20%22${malfunctionSignalDate.getTime()}%22%2C%20%22%24lte%22%3A%20%22${disconnectedSignalDate.getTime()}%22%7D%7D&order=ASC&page=1&perPage=10&sort=id`;
  const malfunctionSignalFilter = `/vehicles?filter=%7B%20%22lastLog%22%3A%20%7B%22%24gte%22%3A%20%22${0}%22%2C%20%22%24lte%22%3A%20%22${malfunctionSignalDate.getTime()}%22%7D%7D&order=ASC&page=1&perPage=10&sort=id`;
  const activeRegistrationFilter = `/vehicles?filter=%7B%20%22serviceStatus%22%3A%20%22active%22%7D&order=ASC&page=1&perPage=10&sort=id`;
  const expiredRegistrationFilter = `/vehicles?filter=%7B%20%22serviceStatus%22%3A%20%22expired%22%7D&order=ASC&page=1&perPage=10&sort=id`;
  const unpaidActiveFilter = `/vehicles?filter=%7B%20%22subscriptionExpiryDate%22%3A%20%7B%22%24lte%22%3A%20%22${today.getTime()}%22%7D%2C%20%22serviceStatus%22%3A%22active%22%7D&order=ASC&page=1&perPage=10&sort=id`;
  const recoverSIMFilter = `/vehicles?filter=%7B%20%22subscriptionExpiryDate%22%3A%20%7B%22%24lte%22%3A%20%22${recoverSIMAfterDate.getTime()}%22%7D%2C%20%22serviceStatus%22%3A%22expired%22%7D&order=ASC&page=1&perPage=10&sort=id`;

  const [simMonthlyPrice, setSimMonthlyPrice] = useLocalStorage(
    "sim_monthly_cost",
    30
  );
  const [monthlyServerExpense, setMonthlyServerExpense] = useLocalStorage(
    "server_monthly_cost",
    870
  );
  const [monthlyOfficeExpense, setMonthlyOfficeExpense] = useLocalStorage(
    "office_monthly_cost",
    4500
  );
  const [monthlySubscription, setMonthlySubscription] = useLocalStorage(
    "monthly_subscription",
    299
  );

  //#region dash
  const classes = useStyles();

  const onPieEnter = (_, index) => {
    setPieActiveIndex(index);
  };

  if (!data && loading) {
    return <Loading />;
  }

  if (error) {
    return <p>ERROR: {JSON.stringify(error)}</p>;
  }

  const { dataHealth, dataMarketing, dataServer } = data;
  const {
    activeSignals,
    disconnectedSignals,
    lateSignals,
    malfunctionSignals,
    totalSignals,
    warningSignals,
    avarageLastSession,
  } = dataHealth.management;

  const {
    activeRregistrations,
    arr,
    expiredRregistrations,
    mrr,
    paymentPastDueRegistrations,
    runwayCumulativeMonths,
    runwayMonths,
    simsToRecover,
    totalRregistrations,
    registrationRevenueByMonths,
  } = dataHealth.bussiness;

  const {
    avgObjSizeKB,
    collections,
    dataSizeMB,
    indexSizeMB,
    indexes,
    objects,
    storageSizeMB,
    totalDBSize,
  } = dataServer.database;
  const { CPU, Cores, Disk, Kernel, Node, OS, RAM, V8 } = dataServer.server;
  const { acquisitionChannels, salesCycleInDays, userTypes } =
    dataMarketing.marketing;

  const {
    sizeGB: totalGBDSK,
    usedGB: usedGBDSK,
    freeGB: freeGBDSK,
    type: typeDSK,
  } = Disk;
  const { totalGB: totalGBRAM, usedGB: usedGBRAM, freeGB: freeGBRAM } = RAM;
  const acquisitionChannelsArray = Object.entries(acquisitionChannels).map(
    ([key, value]) => {
      return {
        name: key === "undefined" ? "unknown" : channels[key],
        value: value,
      };
    }
  );

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const registrationRevenueArray = Object.entries(registrationRevenueByMonths)
    .map(([key, value]) => {
      const date = new Date(key);
      return {
        date: Date.parse(key),
        name:
          months[date.getMonth()] +
          " " +
          date.getFullYear().toString().substr(2),
        revenue: value.revenue,
        sales: value.pcs,
      };
    })
    .sort((a, b) => a.date - b.date);

  return (
    <div className="dashboard-card-container">
      <Grid container spacing={3} fixed={1}>
        <Grid item xs={6} sm={6} md={6} lg={3}>
          <TextField
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">br</InputAdornment>
              ),
            }}
            label="Server monthly cost"
            variant="outlined"
            value={monthlyServerExpense}
            onChange={(e) => setMonthlyServerExpense(e.target.value)}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={3}>
          <TextField
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">br</InputAdornment>
              ),
            }}
            label="SIM monthly cost"
            variant="outlined"
            value={simMonthlyPrice}
            onChange={(e) => setSimMonthlyPrice(e.target.value)}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={3}>
          <TextField
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">br</InputAdornment>
              ),
            }}
            label="Monthly expense"
            variant="outlined"
            value={monthlyOfficeExpense}
            onChange={(e) => setMonthlyOfficeExpense(e.target.value)}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={3}>
          <TextField
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">br</InputAdornment>
              ),
            }}
            label="Monthly Subscription"
            variant="outlined"
            value={monthlySubscription}
            onChange={(e) => setMonthlySubscription(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={3}>
          <Paper className={classes.paper}>
            <div className="stat-card-title">Signals</div>
            <div className="summary-card-container">
              <div className="stat-holder">
                <Link to={allVehicles}>
                  <StatText title={"Total"} value={totalSignals} />
                </Link>
                <Link to={activeSignalFilter}>
                  <StatText
                    title={"Active"}
                    tint={COLORS[1]}
                    value={activeSignals}
                  />
                </Link>
                <Link to={delaySignalFilter}>
                  <StatText title={"Delayed"} value={lateSignals} />
                </Link>
                <Link to={warningSignalFilter}>
                  <StatText
                    title={"Warning"}
                    tint={COLORS[2]}
                    value={warningSignals}
                  />
                </Link>
                <Link to={disconnectedSignalFilter}>
                  <StatText
                    title={"Disconnected"}
                    tint={COLORS[2]}
                    value={disconnectedSignals}
                  />
                </Link>
                <Link to={malfunctionSignalFilter}>
                  <StatText
                    title={"Malfunction"}
                    tint={COLORS[3]}
                    value={malfunctionSignals}
                  />
                </Link>
              </div>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={3}>
          <Paper className={classes.paper}>
            <div className="stat-card-title">Subscriptions</div>
            <div className="summary-card-container">
              <div className="stat-holder">
                <Link to={allVehicles}>
                  <StatText title={"Total"} value={totalRregistrations} />
                </Link>
                <Link to={activeRegistrationFilter}>
                  <StatText
                    title={"Active"}
                    tint={COLORS[1]}
                    value={activeRregistrations}
                  />
                </Link>
                <Link to={expiredRegistrationFilter}>
                  <StatText title={"Expired"} value={expiredRregistrations} />
                </Link>
                <Link to={unpaidActiveFilter}>
                  <StatText
                    title={"Unpaid active"}
                    tint={COLORS[3]}
                    value={paymentPastDueRegistrations}
                  />
                </Link>
                <Link to={recoverSIMFilter}>
                  <StatText
                    title={"Expired SIM"}
                    tint={COLORS[2]}
                    value={simsToRecover}
                  />
                </Link>
                {/* <Link to={recoverSIMFilter}>
                  <StatText
                    title={"Avg Last session"}
                    tint={COLORS[4]}
                    value={avarageLastSession}
                  />
                </Link> */}
              </div>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={3}>
          <Paper className={classes.paper}>
            <div className="stat-card-title">Revenue</div>
            <div className="summary-card-container">
              <div className="stat-holder">
                <StatText
                  title={"ARR in Br"}
                  tint={COLORS[1]}
                  value={formatMoney(arr * monthlySubscription, 0)}
                />
                <StatText
                  title={"MRR in Br"}
                  tint={COLORS[1]}
                  value={formatMoney(mrr * monthlySubscription, 0)}
                />
                <StatText
                  title={"Runway SIM cost"}
                  tint={COLORS[3]}
                  value={formatMoney(
                    runwayCumulativeMonths * simMonthlyPrice,
                    0
                  )}
                />
                <StatText
                  title={"Runway fixed cost"}
                  tint={COLORS[3]}
                  value={formatMoney(
                    runwayMonths * monthlyServerExpense +
                      runwayMonths * monthlyOfficeExpense,
                    0
                  )}
                />
              </div>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={3}>
          <Paper className={classes.paper}>
            <div className="stat-card-title">Space</div>
            <div className="summary-card-container">
              <div className="stat-holder">
                <StatText
                  title={"Disc usage"}
                  isSmall={true}
                  tint={
                    1 - freeGBDSK / totalGBDSK > 0.7 ? COLORS[3] : "#000000"
                  }
                  value={`${formatMoney(
                    100 - (freeGBDSK / totalGBDSK) * 100,
                    0
                  )}%`}
                />
                <StatText
                  title={"RAM usage"}
                  isSmall={true}
                  tint={freeGBDSK / totalGBDSK > 0.7 ? COLORS[3] : "#000000"}
                  value={`${formatMoney((freeGBRAM / usedGBRAM) * 100, 0)}%`}
                />
                <StatText
                  title={"Total DB docs"}
                  value={formatMoney(objects, 0)}
                />
                <StatText title={"DB Size"} value={`${totalDBSize}MB`} />
                <StatText
                  title={`${collections} DB Collections`}
                  value={`${dataSizeMB}MB`}
                />
                <StatText
                  title={`${indexes} DB Indeces`}
                  value={`${indexSizeMB}MB`}
                />
              </div>
            </div>
          </Paper>
        </Grid>
        {/* charts */}
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Paper className={classes.paper}>
            <div className="card-title">Acquisition</div>
            <ResponsiveContainer width="100%" height={400}>
              <BarChart
                width={400}
                height={300}
                data={registrationRevenueArray}
                margin={{
                  top: 20,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
                barSize={20}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis yAxisId="left" orientation="left" stroke={COLORS[3]} />
                <YAxis
                  yAxisId="right"
                  orientation="right"
                  stroke={COLORS[1]}
                  tickFormatter={(str) => formatMoney(str, 0)}
                />
                <Tooltip formatter={(str) => formatMoney(str)} />
                <Legend />
                <Bar yAxisId="left" dataKey="sales" fill={COLORS[3]} />
                <Bar yAxisId="right" dataKey="revenue" fill={COLORS[1]} />
              </BarChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Paper className={classes.paper}>
            <div className="card-title">Acquisition channels</div>
            <ResponsiveContainer width="100%" height={400}>
              <PieChart width={400} height={400}>
                <Pie
                  activeIndex={activePieIndex}
                  activeShape={renderAcquisitionActiveShape}
                  data={acquisitionChannelsArray}
                  cx="50%"
                  cy="50%"
                  innerRadius={60}
                  outerRadius={120}
                  fill="#8884d8"
                  dataKey="value"
                  onMouseEnter={onPieEnter}
                >
                  {acquisitionChannelsArray.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default AdminDashboard;
