import React from "react";
import {
  Datagrid,
  TabbedShowLayout,
  Tab,
  Show,
  TextField,
  DateField,
  ReferenceField,
  ReferenceManyField,
  FunctionField,
  ChipField,
} from "react-admin";

import { formattedLastTime } from "./formatter";

// const userOptionRenderer = (choice) => `${choice.name} - ${choice.phone}`;

const CompanyShow = (props) => {
  return (
    <Show {...props}>
      <TabbedShowLayout>
        <Tab label="Details">
          <TextField source="companyName" />
          <TextField source="email" />
          <TextField source="phone" />
          <ReferenceField label="Owner" source="ownerRef" reference="customers">
            <TextField source="name" />
          </ReferenceField>
          <DateField source="registerDate" />
          <ChipField source="status" />
          <TextField source="note" />
        </Tab>
        <Tab label="vehicles">
          <ReferenceManyField reference="vehicles" target="companyRef">
            <Datagrid rowClick="show">
              <TextField source="plate" />
              {/* <TextField source="id" /> */}
              <TextField source="brand" />
              <TextField source="model" />
              {/* <TextField source="type" /> */}
              {/* <TextField source="tag" /> */}
              {/* <TextField source="color" /> */}
              {/* <TextField source="colorHex" /> */}
              <ChipField source="serviceStatus" label="Service" />
              {/* <TextField source="status" label="State" /> */}
              {/* owner ref */}
              {/* <TextField source="ownerRef" /> */}
              <ReferenceField label="Owner" source="ownerRef" reference="users">
                <TextField source="name" />
              </ReferenceField>

              <ReferenceField
                label="Tracker"
                source="deviceRef"
                reference="devices"
              >
                {/* <TextField source="SIMNumber" /> */}
                <FunctionField
                  label="Tracker"
                  render={(record) =>
                    `imei ${record.identifier} - ${record.SIMNumber}`
                  }
                />
              </ReferenceField>

              {/* notifier ref */}
              <ReferenceField
                label="Notifier"
                source="offlineNotifierRef"
                reference="users"
              >
                <TextField source="name" />
              </ReferenceField>
              <FunctionField
                label="last signal"
                render={(record) => `${formattedLastTime(record.lastLog)}`}
              />
              {/* for extended history */}
              {/* <TextField source="deferTrimDate" /> */}
              {/* <TextField source="registerDate" /> */}
              <DateField source="subscriptionExpiryDate" label="Expires on" />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default CompanyShow;
