export const apiUrl =
  process.env.REACT_APP_DEVELOPMENT_ENV === "dev"
    ? "http://localhost:5000/api/team"
    : window.location.origin + "/api/team";

export const subscriptionTier = [
  { id: "MOKARI", name: "MOKARI", price: 948 },
  { id: "JEMARI", name: "JEMARI", price: 1428 },
  { id: "AWAQI", name: "AWAQI", price: 2388 },
  { id: "MEDEBEGNA", name: "MEDEBEGNA", price: 2388 },
  { id: "MULU", name: "MULU", price: 3588 },
  { id: "NIGD", name: "NIGD", price: 4788 },
];

export const acquisitionChannels = [
  { id: "fca", name: "FCA" },
  { id: "tko", name: "TKO" },
  { id: "gga", name: "GGA" },
  { id: "wom", name: "WOM" },
];
