// in src/auth/VerifyOTP.js
import React, { useState } from "react";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import {
  Notification,
  useNotify,
  defaultTheme,
  Loading,
  useRedirect,
  useLogout,
} from "react-admin";
import { Link } from "@material-ui/core";
import { TextField, Button, Card, CardActions } from "@material-ui/core";
import { useHistory } from "react-router";
import { styles } from "../utils/authFormStyle";
import { resendUserVerification, verifyUserPhone } from "../utils/useUser";

const VerifyPhone = ({ theme }) => {
  let history = useHistory();
  const successText =
    "We have successfully verified your phone. You can now proceed to your dashboard.";
  const failText =
    "The verification code have expired or is unavailable. You can resend the verification code by pressing the button bellow.";
  const emptyText =
    "The account is awaiting phone number verification. Activate your account with the verification code we've sent to your phone. If you did not receive an sms, You can resend the verification code by pressing the button bellow.";
  const redirect = useRedirect();
  const notify = useNotify();
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState("none");
  const logout = useLogout();

  const notiRef = React.createRef(null);
  const [VerificationRes, setVerificationRes] = useState(emptyText);
  // const [token, setToken] = useState("");

  const formTheme = createMuiTheme(defaultTheme);
  // const { primary1Color, accent1Color } = getColorsFromTheme(formTheme);

  const setOtp = (value) => {
    if (value.length === 6) verifyPhone(value);
  };

  const verifyPhone = (otp) => {
    setLoading(true);

    setResult((result) => "none");

    verifyUserPhone({ otp })
      .then(() => {
        notify("Verification successful");
        setResult((result) => "success");
        setVerificationRes((VerificationRes) => successText);
      })
      .catch((e) => {
        notify("Verification failed", { type: "warning" });
        setResult((result) => "fail");
        setVerificationRes((VerificationRes) => failText);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const resendVerification = () => {
    setLoading(true);
    setResult((result) => "none");

    resendUserVerification()
      .then(() => {
        notify("Verification code sent");
        setResult((result) => "none");
        setVerificationRes((VerificationRes) => emptyText);
      })
      .catch((e) => {
        notify("Sending verification code failed", { type: "warning" });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (loading) return <Loading />;

  return (
    <ThemeProvider theme={formTheme}>
      <div style={styles.main}>
        <Card style={styles.card}>
          <form name="verifyEmailForm">
            <div style={styles.form}>
              <p style={styles.hint}>Phone verification</p>
              <p style={styles.hint}>{VerificationRes}</p>
              {result !== "success" ? (
                <div style={styles.input}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    name="otp"
                    label="verification code"
                    helperText="The 6 digit number sent to your phone"
                    required
                    defaultValue={""}
                    onChange={(e) => setOtp(e.target.value)}
                  />
                </div>
              ) : null}
              {result === "success" ? (
                <div style={styles.input} label="gotoapp">
                  <Button
                    color="primary"
                    variant="contained"
                    size="large"
                    fullWidth
                    onClick={() => {
                      history.push("/");
                    }}
                  >
                    go to app
                  </Button>
                </div>
              ) : null}
              {result !== "success" ? (
                <div style={styles.input}>
                  <Button
                    color="primary"
                    variant="contained"
                    size="large"
                    fullWidth
                    onClick={() => {
                      resendVerification();
                    }}
                  >
                    Resend verification code
                  </Button>
                </div>
              ) : null}
              {result !== "success" ? (
                <CardActions>
                  <div style={styles.link}>
                    <Link
                      onClick={() => {
                        logout();
                      }}
                    >
                      Logout
                    </Link>
                  </div>
                </CardActions>
              ) : null}
            </div>
          </form>
        </Card>
        <div ref={notiRef}>
          <Notification />
        </div>
      </div>
    </ThemeProvider>
  );
};

export default VerifyPhone;
