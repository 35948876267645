import React from "react";
import {
  List,
  Datagrid,
  TextField,
  ChipField,
  BooleanField,
  FunctionField,
  DateField,
  ReferenceField,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import { formatMoney } from "./formatter";

const useStyles = makeStyles({
  paid: { color: "green" },
  active: { color: "grey" },
  overdue: { color: "yellow" },
  confirm: { color: "red" },
});

const ColoredChipField = (props) => {
  const classes = useStyles();
  return (
    <ChipField
      className={classnames({
        [classes.customer]: props.record[props.source] === "active",
        [classes.prospect]: props.record[props.source] === "sent",
        [classes.manager]: props.record[props.source] === "overdue",
        [classes.manager]: props.record[props.source] === "confirm",
        [classes.admin]: props.record[props.source] === "paid",
        [classes.manager]: props.record[props.source] === "closed",
      })}
      {...props}
    />
  );
};

// const NotifiedField = (props) => {
//   const text = props.record.issuedNotified
//     ? "*"
//     : "-" + props.record.reminderNotified
//     ? "*"
//     : "-" + props.record.dueNotified
//     ? "*"
//     : "-" + props.record.overdueNotified
//     ? "*"
//     : "-" + props.record.warningNotified
//     ? "*"
//     : "-" + props.record.suspensionNotified
//     ? "*"
//     : "-";
//   return <TextField {...props} text={text} />;
// };

// Ensure the original component defaultProps are still applied as they may be used by its parents (such as the `Show` component):
ColoredChipField.defaultProps = ChipField.defaultProps;

const InvoicesList = (props) => {
  return (
    <List
      {...props}
      filter={{
        status: [
          "active",
          "sent",
          "overdue",
          "overdue",
          "outstanding",
          "automatic",
        ],
      }}
    >
      <Datagrid
        sx={{
          "& .RaDatagrid-rowOdd": {
            backgroundColor: "#fee",
          },
        }}
        rowClick="show"
      >
        <TextField source="invoiceNumber" />
        <ReferenceField
          label="Customer"
          source="userRef"
          reference="customers"
          link="show"
          emptyText="No User"
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          label="Vehicle"
          source="vehicleRef"
          reference="vehicles"
          link="show"
          emptyText="No Vehicle"
        >
          <TextField source="plate" />
        </ReferenceField>
        <DateField source="issuedDate" />
        <DateField source="dueDate" />
        <ChipField source="status" />
        <FunctionField
          label="Progress"
          render={(record) =>
            (record.issuedNotified ? " √ " : " - ") +
            (record.reminderNotified ? " √ " : " - ") +
            (record.dueNotified ? " √ " : " - ") +
            (record.overdueNotified ? " √ " : " - ") +
            (record.warningNotified ? " √ " : " - ") +
            (record.suspensionNotified ? " √ " : " - ")
          }
        />
        <FunctionField
          label="Total amount"
          render={(record) =>
            `${formatMoney(
              record.items.map((i) => i.amount).reduce((a, b) => a + b, 0),
              2
            )}`
          }
        />
        <FunctionField
          label="Total transaction"
          render={(record) =>
            `${formatMoney(
              record.transactions
                .map((i) => i.transactionAmount)
                .reduce((a, b) => a + b, 0),
              2
            )}`
          }
        />
      </Datagrid>
    </List>
  );
};

export default InvoicesList;
