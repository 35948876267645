import React from "react";
import {
  Create,
  TextInput,
  BooleanInput,
  DateInput,
  NumberInput,
  TabbedForm,
  SelectInput,
  FormTab,
  required,
  SelectArrayInput,
  ReferenceInput,
  Datagrid,
  ListBase,
  ListToolbar,
  AutocompleteInput,
} from "react-admin";

import { ColorInput } from "../components/ColorComponent";
import { subscriptionTier } from "../utils/constants";
import HorizontalInputStyle from "./HorizontalInputStyle";
import { userFilter, userOptionRenderer } from "./userFilter";

const serviceStatus = [
  { id: "expired", name: "expired" },
  { id: "offline", name: "offline" },
  { id: "active", name: "active" },
];

const status = [
  { id: "tampered", name: "tampered" },
  { id: "offline", name: "offline" },
  { id: "driving", name: "driving" },
  { id: "parked", name: "parked" },
  { id: "violated", name: "violated" },
  { id: "idling", name: "idling" },
];

const notification = [
  { id: "CALL", name: "CALL" },
  { id: "SMS", name: "SMS" },
  { id: "PUSH", name: "PUSH" },
];

const driverStatus = [
  { id: "unassigned", name: "unassigned" },
  { id: "requested", name: "requested" },
  { id: "active", name: "active" },
  { id: "suspended", name: "suspended" },
  { id: "declined", name: "declined" },
  { id: "fresh", name: "fresh" },
];

const vehicleType = [
  { id: "mini", name: "mini" },
  { id: "Hatchback", name: "Hatchback" },
  { id: "SUV/Crossover", name: "SUV/Crossover" },
  { id: "Pickup", name: "Pickup" },
  { id: "Coupe", name: "Coupe" },
  { id: "Stationwagon", name: "Stationwagon" },
  { id: "Convertible", name: "Convertible" },
  { id: "Van", name: "Van" },
  { id: "Mini van", name: "Mini van" },
  { id: "Micro", name: "Micro" },
  { id: "City/Kei car", name: "City/Kei car" },
  { id: "Bus", name: "Bus" },
  { id: "Motorcycle", name: "Motorcycle" },
  { id: "Dump truck", name: "Dump truck" },
  { id: "Trailer truck", name: "Trailer truck" },
  { id: "Yellow equipment", name: "Yellow equipment" },
];

const defaultNotification = notification.map((notification) => notification.id);

const trackerOptionRenderer = (tracker) =>
  tracker ? `imei ${tracker.identifier} #${tracker.SIMNumber}` : "";
// tracker ?
// : "";

const getSubscriptionDate = () => {
  var d = new Date();
  return d.setFullYear(d.getFullYear() + 1);
};

const VehiclesCreate = (props) => {
  return (
    <Create {...props}>
      <TabbedForm>
        <FormTab label="Details">
          {/* <TextInput disabled source="id" /> */}
          <TextInput required source="plate" defaultValue={"-አአኢት-"} />
          {/* device ref */}
          <ReferenceInput
            label="Tracker"
            source="deviceRef"
            reference="devices"
            style={HorizontalInputStyle}
            filterToQuery={(searchText) => ({
              identifier: { $regex: searchText, $options: "i" },
            })}
            allowEmpty
          >
            <AutocompleteInput optionText={trackerOptionRenderer} />
          </ReferenceInput>
          {/* <ReferenceInput
            label="Tracker"
            source="deviceRef"
            reference="devices"
            style={HorizontalInputStyle}
            allowEmpty
          >
            <SelectInput optionText={trackerOptionRenderer} />
          </ReferenceInput> */}
          <SelectInput
            required
            defaultValue={serviceStatus[2].id}
            source="serviceStatus"
            choices={serviceStatus}
            style={HorizontalInputStyle}
          />
          <SelectInput
            required
            source="status"
            choices={status}
            defaultValue={status[5].id}
          />
          <TextInput required source="brand" style={HorizontalInputStyle} />
          <TextInput required source="model" style={HorizontalInputStyle} />
          <SelectInput
            required
            source="type"
            style={HorizontalInputStyle}
            label="Vehicle type"
            choices={vehicleType}
            defaultValue={vehicleType[4].id}
          />
          <TextInput required source="tag" />

          <TextInput
            required
            source="color"
            label="color name"
            defaultValue={"ግራጫ"}
            style={HorizontalInputStyle}
          />
          <ColorInput
            source="colorHex"
            picker="Swatches"
            defaultValue={"#cfd8dc"}
          />
          <br />
          {/* Block Chrome Compact Github Photoshop Swatches Twitter */}
        </FormTab>

        <FormTab label="Owner">
          {/* owner ref */}

          <ReferenceInput
            label="Owner"
            source="ownerRef"
            reference="customers"
            style={HorizontalInputStyle}
            filterToQuery={(searchText) => ({
              phone: { $regex: searchText, $options: "i" },
            })}
          >
            <AutocompleteInput optionText={userOptionRenderer} />
          </ReferenceInput>

          {/* owner values */}
          <TextInput
            source="ownerFence"
            label="GeoFence"
            style={HorizontalInputStyle}
          />
          {/* <NumberInput
          required
          source="ownerSpeed"
       
        /> */}
          <NumberInput
            source="ownerSpeedLimitValue"
            label="Speed limit"
            style={HorizontalInputStyle}
          />
          <TextInput source="ownerParkedLocation" label="Parked location" />
          {/* owner notification type */}
          <SelectArrayInput
            required
            source="ownerTetherNotificationType"
            label="Tamper notification"
            style={HorizontalInputStyle}
            choices={notification}
            defaultValue={defaultNotification}
          />

          <SelectArrayInput
            required
            source="ownerFenceNotificationType"
            label="Fence notification"
            style={HorizontalInputStyle}
            choices={notification}
            defaultValue={defaultNotification}
          />

          <SelectArrayInput
            required
            source="ownerSpeedNotificationType"
            label="Speed notification"
            style={HorizontalInputStyle}
            choices={notification}
            defaultValue={defaultNotification}
          />

          <SelectArrayInput
            required
            source="ownerParkNotificationType"
            label="Park notification"
            choices={notification}
            defaultValue={defaultNotification}
          />

          {/* owner booleans */}
          <BooleanInput
            required
            defaultValue={false}
            source="isOwnerSpeedLimited"
            label="Speed limited"
            style={HorizontalInputStyle}
          />
          <BooleanInput
            required
            defaultValue={false}
            source="isOwnerFenced"
            label="Fenced"
            style={HorizontalInputStyle}
          />
          <BooleanInput
            required
            defaultValue={false}
            source="isOwnerParked"
            label="Parked"
            style={HorizontalInputStyle}
          />
          <BooleanInput
            required
            defaultValue={true}
            source="isOwnerServiceActive"
            label="Service"
          />
          {/* owner perm section */}
          <BooleanInput
            required
            defaultValue={true}
            source="canOwnerSpeedLimit"
            label="Speed permission"
            style={HorizontalInputStyle}
          />
          <BooleanInput
            required
            defaultValue={true}
            source="canOwnerFence"
            label="Fence permission"
            style={HorizontalInputStyle}
          />
          <BooleanInput
            required
            defaultValue={true}
            source="canOwnerPark"
            label="Park permission"
            style={HorizontalInputStyle}
          />

          <BooleanInput
            required
            defaultValue={true}
            source="canOwnerTrack"
            label="Track permission"
            style={HorizontalInputStyle}
          />
          <BooleanInput
            required
            defaultValue={true}
            source="canOwnerKill"
            label="Engine permission"
          />
          {/* owner notif section */}
          <BooleanInput
            required
            defaultValue={true}
            source="canOwnerGetDisconNotif"
            label="Tamper notifications"
            style={HorizontalInputStyle}
          />
          <BooleanInput
            required
            defaultValue={true}
            source="canOwnerGetSpeedLimitNotif"
            label="Speed notifications"
            style={HorizontalInputStyle}
          />
          <BooleanInput
            required
            defaultValue={true}
            source="canOwnerGetFenceNotif"
            label="Fenced notifications"
            style={HorizontalInputStyle}
          />
          <BooleanInput
            required
            defaultValue={true}
            source="canOwnerGetParkNotif"
            label="Parked notifications"
          />
        </FormTab>

        <FormTab label="Driver">
          {/* driver ref */}
          <ReferenceInput
            label="Driver"
            source="driverRef"
            reference="customers"
            style={HorizontalInputStyle}
            filterToQuery={(searchText) => ({
              phone: { $regex: searchText, $options: "i" },
            })}
          >
            <AutocompleteInput optionText={userOptionRenderer} />
          </ReferenceInput>
          {/* driver values */}
          <TextInput
            source="driverFence"
            label="GeoFence"
            style={HorizontalInputStyle}
          />
          <NumberInput
            source="driverSpeedLimitValue"
            label="Speed limit"
            style={HorizontalInputStyle}
          />
          <TextInput source="driverParkedLocation" label="Parked location" />

          {/* driver status */}
          <SelectInput
            source="driverStatus"
            label="Driver status"
            choices={driverStatus}
            style={HorizontalInputStyle}
          />

          <TextInput source="driverVerification" label="Driver verification" />

          {/* driver notification types */}

          <SelectArrayInput
            source="driverTetherNotificationType"
            label="Tamper notification"
            style={HorizontalInputStyle}
            choices={notification}
            defaultValue={defaultNotification}
          />

          <SelectArrayInput
            source="driverFenceNotificationType"
            label="Fence notification"
            style={HorizontalInputStyle}
            choices={notification}
            defaultValue={defaultNotification}
          />

          <SelectArrayInput
            source="driverSpeedNotificationType"
            label="Speed notification"
            style={HorizontalInputStyle}
            choices={notification}
            defaultValue={defaultNotification}
          />

          <SelectArrayInput
            source="driverParkNotificationType"
            label="Park notification"
            choices={notification}
            defaultValue={defaultNotification}
          />
          {/* driver booleans */}
          <BooleanInput
            defaultValue={false}
            source="isDriverSpeedLimited"
            label="Speed limited"
            style={HorizontalInputStyle}
          />
          <BooleanInput
            defaultValue={false}
            source="isDriverFenced"
            label="Fenced"
            style={HorizontalInputStyle}
          />
          <BooleanInput
            defaultValue={false}
            source="isDriverParked"
            label="Parked"
            style={HorizontalInputStyle}
          />
          <BooleanInput
            defaultValue={false}
            source="isDriverServiceActive"
            label="Service"
          />
          {/* driver perm section */}
          <BooleanInput
            defaultValue={true}
            source="canDriverSpeedLimit"
            label="Speed permission"
            style={HorizontalInputStyle}
          />
          <BooleanInput
            defaultValue={true}
            source="canDriverFence"
            label="Fence permission"
            style={HorizontalInputStyle}
          />
          <BooleanInput
            defaultValue={true}
            source="canDriverPark"
            label="Park permission"
            style={HorizontalInputStyle}
          />
          <BooleanInput
            defaultValue={true}
            source="canDriverTrack"
            label="Track permission"
            style={HorizontalInputStyle}
          />
          <BooleanInput
            defaultValue={false}
            source="canDriverKill"
            label="Engine permission"
          />
          {/* driver notif section */}
          <BooleanInput
            defaultValue={true}
            source="canDriverGetDisconNotif"
            label="Tamper notifications"
            style={HorizontalInputStyle}
          />
          <BooleanInput
            defaultValue={true}
            source="canDriverGetSpeedLimitNotif"
            label="Speed notifications"
            style={HorizontalInputStyle}
          />
          <BooleanInput
            defaultValue={true}
            source="canDriverGetFenceNotif"
            label="Fenced notifications"
            style={HorizontalInputStyle}
          />
          <BooleanInput
            defaultValue={true}
            source="canDriverGetParkNotif"
            label="Parked notifications"
          />
        </FormTab>

        <FormTab label="Account">
          {/* last owner notification_timestamp */}
          {/* <TextInput required source="lastOwnerDisconnectedNotif" /> */}
          {/* <TextInput
          required
          source="lastOwnerSpeedLimitNotif"
       
        /> */}
          {/* <TextInput
          required
          source="lastOwnerFenceNotif"
       
        /> */}
          {/* <TextInput required source="lastOwnerParkNotif" /> */}
          {/* last driver notification_timestamp */}
          {/* <TextInput required source="lastDriverDisconnectedNotif" /> */}
          {/* <TextInput
          disabled
          source="lastDriverSpeedLimitNotif"
       
        /> */}
          {/* <TextInput
          disabled
          source="lastDriverFenceNotif"
       
        /> */}
          {/* <TextInput disabled source="lastDriverParkNotif" /> */}
          {/* last fields */}
          {/* <TextInput required source="lastLocation" />
        <TextInput required source="lastLog" style={HorizontalInputStyle} /> */}
          {/* for extended history */}

          <DateInput source="deferTrimDate" />
          <NumberInput source="extraLogDays" />
          <DateInput required source="registerDate" defaultValue={Date()} />
          <DateInput
            required
            source="subscriptionExpiryDate"
            defaultValue={getSubscriptionDate()}
          />
          {/* <NumberInput
            required
            source="subscriptionPrice"
            defaultValue={2280}
          /> */}
          <SelectInput
            required
            source="subscriptionTier"
            label="Subscription Tier"
            choices={subscriptionTier}
            defaultValue={subscriptionTier[2].id}
          />
          <NumberInput required source="devicePrice" defaultValue={6200} />
        </FormTab>

        <FormTab label="Control">
          {/* notifier ref */}
          <BooleanInput source="isTesting" />
          <ReferenceInput
            label="Notifier"
            source="offlineNotifierRef"
            reference="users"
            defaultValue={"605f309d1c8f360930ed8d4f"}
            filter={{ role: "agent" }}
            validate={[required()]}
          >
            <SelectInput source="name" />
          </ReferenceInput>
          {/* vehicle controls */}
          <BooleanInput required source="engine" defaultValue={true} />
        </FormTab>
      </TabbedForm>
    </Create>
  );
};

export default VehiclesCreate;
