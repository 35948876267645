import { apiUrl } from "../utils/constants";
import { httpClient } from "./dataProvider";

export const approveUser = async ({ id }) => {
  const url = `${apiUrl}/approve_user/${id}`;
  const response = await httpClient(url, localStorage.getItem("tetherauth"), {
    method: "POST",
  });
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  return response.json;
};

export const getHealthStat = async () => {
  const url = `${apiUrl}/health`;
  const response = await httpClient(url, localStorage.getItem("tetherauth"), {
    method: "GET",
  });
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  return response.json;
};

export const getServerStat = async () => {
  const url = `${apiUrl}/server`;
  const response = await httpClient(url, localStorage.getItem("tetherauth"), {
    method: "GET",
  });
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  return response.json;
};

export const getMarketingStat = async () => {
  const url = `${apiUrl}/marketing`;
  const response = await httpClient(url, localStorage.getItem("tetherauth"), {
    method: "GET",
  });
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  return response.json;
};

export const confirmPayment = async ({ id }) => {
  const url = `${apiUrl}/approve_payment/${id}`;
  const response = await httpClient(url, localStorage.getItem("tetherauth"), {
    method: "POST",
  });
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  return response.json;
};

export const clearSMSQueue = async ({ id }) => {
  const url = `${apiUrl}/clear_queue/${id}`;
  const response = await httpClient(url, localStorage.getItem("tetherauth"), {
    method: "POST",
  });
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  return response.json;
};

export const generateSMSQueue = async ({ id }) => {
  const url = `${apiUrl}/generate_queue/${id}`;
  const response = await httpClient(url, localStorage.getItem("tetherauth"), {
    method: "POST",
  });
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  return response.json;
};

export const GenerateSMSTest = async ({ id }) => {
  const url = `${apiUrl}/sms_test/${id}`;
  const response = await httpClient(url, localStorage.getItem("tetherauth"), {
    method: "POST",
  });
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  return response.json;
};
