import React from "react";
import { Show, CardActions } from "react-admin";
import { MapField } from "./MapField";

const NoneActions = (props) => <CardActions />;

const MapPanel = (props) => (
  <Show {...props} actions={<NoneActions />}>
    <MapField source="lastLocation" />
  </Show>
);

export default MapPanel;
