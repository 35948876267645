import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  BooleanInput,
  SelectArrayInput,
} from "react-admin";
import { MapFenceInput } from "../components/MapField";
import HorizontalInputStyle from "./HorizontalInputStyle";

const notification = [
  { id: "CALL", name: "CALL" },
  { id: "SMS", name: "SMS" },
  { id: "PUSH", name: "PUSH" },
];

const areaStatus = [
  { id: "passive", name: "passive" },
  { id: "active", name: "active" },
];

const defaultNotification = notification.map((notification) => notification.id);

const AreaCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="areaName" />
        <MapFenceInput source="areaFence" label="Geo Fence" />

        <BooleanInput
          source="isAreaFencedIn"
          label="Fence In"
          style={HorizontalInputStyle}
          required
        />
        <BooleanInput source="isAreaFencedOut" label="Fence Out" required />
        <SelectArrayInput
          required
          source="areaFenceInNotificationType"
          label="In notification"
          style={HorizontalInputStyle}
          choices={notification}
          defaultValue={defaultNotification}
        />
        <SelectArrayInput
          required
          source="areaFenceOutNotificationType"
          label="Out notification"
          choices={notification}
          defaultValue={defaultNotification}
        />

        <SelectInput
          source="status"
          style={HorizontalInputStyle}
          choices={areaStatus}
          required
        />
        <TextInput source="note" />
      </SimpleForm>
    </Create>
  );
};

export default AreaCreate;
