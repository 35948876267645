import React from "react";
import {
  List,
  Datagrid,
  Show,
  TextField,
  DateField,
  FunctionField,
  ChipField,
  EditButton,
  CardActions,
} from "react-admin";
import { MapField } from "../components/MapField";
import { formattedLastTime } from "./formatter";

const NoneActions = (props) => <CardActions {...props} />;

const VehiclePanel = (props) => (
  <Show {...props} actions={<NoneActions />}>
    <MapField source="lastLocation" />
  </Show>
);

const VehiclesList = (props) => {
  return (
    <List {...props} title="Vehicles" bulkActionButtons={false}>
      <Datagrid rowClick="show" expand={<VehiclePanel />}>
        <TextField source="plate" />
        {/* <TextField source="id" /> */}
        <TextField source="brand" />
        <TextField source="model" />
        {/* <TextField source="type" /> */}
        {/* <TextField source="tag" /> */}
        <TextField source="color" />
        {/* <TextField source="colorHex" /> */}
        <ChipField source="serviceStatus" label="Service" />
        <TextField source="status" label="State" />

        <FunctionField
          label="last signal"
          render={(record) => `${formattedLastTime(record.lastLog)}`}
        />
        {/* for extended history */}
        {/* <TextField source="deferTrimDate" /> */}
        {/* <TextField source="registerDate" /> */}
        <DateField source="subscriptionExpiryDate" label="Expires on" />
        {/* <TextField source="subscriptionPrice" /> */}

        {/* <ShowButton label="Show" basePath="/vehicles" /> */}
        <EditButton label="Controls" basePath="/assets" />
        {/* <DeleteButton label="Delete" basePath="/vehicles" /> */}
      </Datagrid>
    </List>
  );
};

export default VehiclesList;
