import React, { useState } from "react";
import {
  Show,
  SimpleForm,
  TextField,
  useNotify,
  ChipField,
  useRedirect,
  NumberField,
  useRecordContext,
  Tab,
  TabbedShowLayout,
  Button,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import {
  GenerateSMSTest,
  generateSMSQueue,
  clearSMSQueue,
} from "../api/useRoutes";

const useStyles = makeStyles({
  lead: { color: "blue" },
  follower: { color: "grey" },
  customer: { color: "green" },
});

const ColoredChipField = (props) => {
  const classes = useStyles();
  return (
    <ChipField
      className={classnames({
        [classes.lead]: props.record[props.source] === "lead",
        [classes.follower]: props.record[props.source] === "follower",
        [classes.customer]: props.record[props.source] === "customer",
      })}
      {...props}
    />
  );
};

const userStatus = [
  { id: "lead", name: "lead" },
  { id: "follower", name: "follower" },
  { id: "customer", name: "customer" },
];

const contentStatus = [
  { id: "active", name: "active" },
  { id: "paused", name: "paused" },
  { id: "finished", name: "finished" },
];

const GenerateButton = (props) => {
  const { record } = props;
  const [loading, setLoading] = useState(false);
  const notify = useNotify();
  const redirect = useRedirect();

  return (
    <Button
      {...props}
      label="Generate"
      onClick={() => {
        setLoading(true);
        generateSMSQueue({ id: record.id })
          .then(() => {
            notify("Queue generation started");
            redirect("/content");
          })
          .catch((e) => {
            notify("Queue generation failed " + e.message, {
              type: "warning",
            });
          })
          .finally(() => {
            setLoading(false);
          });
      }}
      disabled={loading}
    />
  );
};

const TestSMSButton = (props) => {
  const { record } = props;
  const [loading, setLoading] = useState(false);
  const notify = useNotify();
  const redirect = useRedirect();

  return (
    <Button
      {...props}
      label="Test SMS"
      onClick={() => {
        setLoading(true);
        GenerateSMSTest({ id: record.id })
          .then(() => {
            notify("Queue generation test started");
            redirect("/content");
          })
          .catch((e) => {
            notify("Queue generation test failed " + e.message, {
              type: "warning",
            });
          })
          .finally(() => {
            setLoading(false);
          });
      }}
      disabled={loading}
    />
  );
};

const ClearButton = (props) => {
  const { record } = props;
  const [loading, setLoading] = useState(false);
  const notify = useNotify();
  const redirect = useRedirect();

  return (
    <Button
      {...props}
      label="Clear"
      onClick={() => {
        setLoading(true);
        clearSMSQueue({ id: record.id })
          .then(() => {
            notify("Queue cleared");
            redirect("/content");
          })
          .catch((e) => {
            notify("Queue clearing failed " + e.message, {
              type: "warning",
            });
          })
          .finally(() => {
            setLoading(false);
          });
      }}
      disabled={loading}
    />
  );
};
// const defaultNotification = notification.map((notification) => notification.id);

const ContentShow = (props) => {
  return (
    <Show {...props}>
      <TabbedShowLayout>
        <Tab label="Details">
          <TextField source="title" />
          <TextField source="message" required multiline fullWidth />
          <TextField source="messageStatus" />
          <TextField source="userType" label="Recipients" />
          <TextField source="method" />
          <NumberField source="smsHits" />
          <NumberField source="telegramHits" />

          <GenerateButton />
          <TestSMSButton />
          <ClearButton />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default ContentShow;
