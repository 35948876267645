import React, { useEffect } from "react";
import "./App.css";
import "./i18n";
import { withTranslation, useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";

function Landing() {
  const { t, i18n } = useTranslation();
  // const [valid, setValid] = useState(true);
  // const [emailPhone, setEmailPhone] = useState("");

  useEffect(() => {
    document.title = t("app.title");
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", t("app.meta"));
  }, [t, i18n.language]);

  // useEffect(() => {
  //   document
  //     .querySelector('meta[name="description"]')
  //     .setAttribute("content", t("app.meta"));
  // }, [i18n.language]);

  // const requestUrl =
  //   process.env.NODE_ENV === "development"
  //     ? "http://localhost:5000/api/v1/auth/request"
  //     : window.location.origin + "/api/v1/auth/request";

  // const validateEmail = (e) => {
  //   const emailRex =
  //     /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  //   const phoneRex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

  //   setValid(emailRex.test(e.target.value) || phoneRex.test(e.target.value));
  // };

  // const requestDemo = (e) => {
  //   e.preventDefault();
  //   fetch(requestUrl, {
  //     method: "post",
  //     headers: { "Content-Type": "application/json" },
  //     body: JSON.stringify({
  //       email_or_phone: emailPhone,
  //     }),
  //   })
  //     .then((response) => response.json())
  //     .then((response) => {
  //       if (response.data === emailPhone) window.location.href = "/requested";
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // };

  // const centeredRowStyle = {
  //   verticalAlign: "bottom",
  // };

  return (
    <div className="App">
      <div className={i18n.language}>
        <div className="body-wrap">
          <header id="masthead" className="site-header">
            <div className="container">
              <div className="site-header-inner">
                <h1 className="site-title m-0">
                  <a href="/" rel="home">
                    {/* <svg
                    width="32"
                    height="32"
                    viewBox="0 0 75 71"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M1.38824 13.5452C11.1285 5.21885 23.7359 0.152344 37.5215 0.152344C51.3071 0.152344 63.9145 5.17958 73.5762 13.5059C75.3436 14.9984 75.5007 17.6691 73.8511 19.3187L59.712 33.4578C58.3374 34.8717 56.138 34.9895 54.5669 33.772C49.8539 30.1194 43.9626 27.92 37.5215 27.92C31.1196 27.92 25.189 30.1194 20.476 33.8112C18.905 35.0288 16.7056 34.9109 15.2917 33.497L1.15259 19.3579C-0.496972 17.7084 -0.339871 15.0377 1.38824 13.5452ZM21.8111 55.1377C21.8111 46.4613 28.8447 39.4276 37.5212 39.4276C46.1977 39.4276 53.2313 46.4613 53.2313 55.1377C53.2313 63.8142 46.1977 70.8478 37.5212 70.8478C28.8447 70.8478 21.8111 63.8142 21.8111 55.1377Z"
                      fill="#FBC02D"
                    />
                  </svg> */}
                    <svg
                      width="31"
                      height="33"
                      viewBox="0 0 31 33"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0.574003 6.522C4.59995 3.08047 9.81095 0.986328 15.509 0.986328C21.207 0.986328 26.418 3.06424 30.4114 6.50577C31.142 7.12265 31.2069 8.22653 30.5251 8.90835L24.681 14.7525C24.1128 15.3369 23.2037 15.3856 22.5544 14.8823C20.6063 13.3726 18.1713 12.4635 15.509 12.4635C12.8629 12.4635 10.4116 13.3726 8.46356 14.8986C7.81421 15.4018 6.90513 15.3531 6.32071 14.7687L0.476601 8.92458C-0.205212 8.24277 -0.140277 7.13888 0.574003 6.522ZM10.6269 20.0189C9.73073 20.0189 9.00425 20.7454 9.00425 21.6415V31.3773C9.00425 32.2734 9.73073 32.9999 10.6269 32.9999H20.3759C21.2721 32.9999 21.9985 32.2734 21.9985 31.3773V21.6415C21.9985 20.7454 21.2721 20.0189 20.3759 20.0189H10.6269Z"
                        fill="#FBC02D"
                      />
                    </svg>
                  </a>
                </h1>
                <div class="header-links">
                  {/* <button
                  id="header-nav-toggle"
                  class="header-nav-toggle"
                  aria-controls="primary-menu"
                  aria-expanded="false"
                >
                  <span class="screen-reader-text">Menu</span>
                  <span class="hamburger">
                    <span class="hamburger-inner"></span>
                  </span>
                </button> */}
                  <ul id="header-nav" class="header-nav list-reset mb-0">
                    <a className="callwrapper" href="tel:+251911702254">
                      <span className="call-icon">
                        <svg
                          height="16px"
                          width="16px"
                          viewBox="0 0 24 24"
                          fill="#01100a"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M0 0h24v24H0z" fill="none" />
                          <path d="M20.01 15.38c-1.23 0-2.42-.2-3.53-.56-.35-.12-.74-.03-1.01.24l-1.57 1.97c-2.83-1.35-5.48-3.9-6.89-6.83l1.95-1.66c.27-.28.35-.67.24-1.02-.37-1.11-.56-2.3-.56-3.53 0-.54-.45-.99-.99-.99H4.19C3.65 3 3 3.24 3 3.99 3 13.28 10.73 21 20.01 21c.71 0 .99-.63.99-1.18v-3.45c0-.54-.45-.99-.99-.99z" />
                        </svg>
                      </span>
                      <label className="call-text en">+251911702254</label>
                    </a>

                    <li>
                      <a class="login-button" href="../app">
                        {t("app.login")}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </header>

          <main>
            <div id="primary" className="content-area">
              <section className="hero text-center">
                <div className="container-sm">
                  <h1 className="hero-title mt-0 mb-12 centered-text">
                    <div>{t("app.header")}</div>
                    {/*<span className="old-price nm">253</span>
                    <span className="has-tooltip nm">
                      199<span className="tooltip">{t("app.limited")}</span>
                    </span>
                    {t("app.rate")} */}
                  </h1>
                  <p className="text-l mb-32 centered-text ">
                    {t("app.description")}
                  </p>

                  {/* <div className="centered-text">
                    <a
                      className="button button-primary btn"
                      href="https://drive.google.com/file/d/1pmwfeHWe8HulksZSLu3RywB9qh5a3V5d/view?usp=sharing"
                      rel="nofollow"
                      target="_blank" rel="noreferrer"
                      style={{ background: "#F9A825" }}
                    >
                      {t("app.download")}
                    </a>
                    <a
                      className="image-button-container-center"
                      target="_blank" rel="noreferrer"
                      href="https://play.google.com/store/apps/details?id=com.biniamkiros.tether"
                      rel="nofollow"
                    >
                      <img
                        src="dist/images/google-play-badge.png"
                        alt="google play"
                        title="google play badge"
                        width="198"
                        height="46"
                      />
                    </a>
                  </div>
                  */}
                  <Grid container align="center">
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <div className="subscription-block">
                        <div className="subscription-box">
                          <div className="subscription-title">
                            {t("pricing.tierI")}
                          </div>
                          <div className="subscription-description">
                            {t("pricing.tierdiscriptionI")}
                          </div>

                          <div className="subscription-container">
                            <span className="subscription-currency">
                              {t("pricing.birr")}
                            </span>
                            <span className="subscription-value nm">199</span>
                            <span className="subscription-duration">
                              {t("pricing.permonth")}
                            </span>
                          </div>

                          <div className="subscription-payment">
                            {t("pricing.payment")}
                          </div>
                          <div class="control">
                            <a
                              class="button button-block"
                              href="tel:+251911702254"
                            >
                              {t("pricing.pricingaction")}
                            </a>
                          </div>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <div className="subscription-block">
                        <div className="subscription-box">
                          <div className="subscription-title">
                            <span className="has-tooltip">
                              {t("pricing.tierII")}
                              <span className="tooltip">
                                {t("pricing.popular")}
                              </span>
                            </span>
                          </div>
                          <div className="subscription-description">
                            {t("pricing.tierdiscriptionII")}
                          </div>

                          <div className="subscription-container">
                            <span className="subscription-currency">
                              {t("pricing.birr")}
                            </span>
                            <span className="subscription-value nm">299</span>
                            <span className="subscription-duration">
                              {t("pricing.permonth")}
                            </span>
                          </div>

                          <div className="subscription-payment">
                            {t("pricing.payment")}
                          </div>
                          <div class="control">
                            <a
                              class="button	button-primary button-block"
                              href="tel:+251911702254"
                            >
                              {t("pricing.pricingaction")}
                            </a>
                          </div>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <div className="subscription-block">
                        <div className="subscription-box">
                          <div className="subscription-title">
                            {t("pricing.tierIII")}
                          </div>
                          <div className="subscription-description">
                            {t("pricing.tierdiscriptionIII")}
                          </div>
                          <div className="subscription-container">
                            <span className="subscription-currency">
                              {t("pricing.birr")}
                            </span>
                            <span className="subscription-value nm">399</span>
                            <span className="subscription-duration">
                              {t("pricing.permonth")}
                            </span>
                          </div>
                          <div className="subscription-payment">
                            {t("pricing.payment")}
                          </div>
                          <div class="control">
                            <a
                              class="button button-block"
                              href="tel:+251911702254"
                              // href="sms:+251911702254;?&body=mulu"
                            >
                              {t("pricing.pricingaction")}
                            </a>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  </Grid>

                  <div className="centered-text">
                    <a
                      className="image-button-container-center"
                      target="_blank"
                      // rel="noreferrer"
                      href="https://play.google.com/store/apps/details?id=com.biniamkiros.tether"
                      rel="nofollow"
                    >
                      <img
                        src="dist/images/google-play-badge.png"
                        alt="google play"
                        title="google play badge"
                        width="198"
                        height="46"
                      />
                    </a>
                  </div>
                  {/* <Grid container align="center">
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <div className="pricing-block">
                        <div className="pricing-details">
                          <div className="pricing-title">
                            {t("pricing.ras")}
                          </div>

                          <div className="pricing-monthly">
                            <span className="subscription-price nm">199</span>
                            {t("pricing.birr/month")}
                          </div>
                          <div className="pricing-feature">
                            {t("pricing.feature1")}
                          </div>
                          <div className="pricing-feature">
                            {" "}
                            {t("pricing.feature2")}
                          </div>
                          <div className="pricing-feature">
                            {t("pricing.feature3")}
                          </div>
                          <div className="pricing-feature">
                            {t("pricing.feature4")}
                          </div>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <div className="pricing-block">
                        <div className="pricing-details">
                          <div className="pricing-title">
                            {t("pricing.qetari")}
                          </div>
                          <div className="pricing-monthly">
                            <span className="subscription-price nm">299</span>
                            {t("pricing.birr/month")}
                          </div>
                          <div className="pricing-feature">
                            {t("pricing.featureadditional1")}
                          </div>
                          <div className="pricing-feature">
                            {t("pricing.feature5")}
                          </div>
                          <div className="pricing-feature">
                            {t("pricing.feature6")}
                          </div>
                          <div className="pricing-feature">
                            {t("pricing.feature7")}
                          </div>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <div className="pricing-block">
                        <div className="pricing-details">
                          <div className="pricing-title">
                            {t("pricing.nigd")}
                          </div>{" "}
                          <div className="pricing-monthly">
                            <span className="subscription-price nm">399</span>
                            {t("pricing.birr/month")}
                          </div>
                          <div className="pricing-feature">
                            {t("pricing.featureadditional2")}
                          </div>
                          <div className="pricing-feature">
                            {t("pricing.feature8")}
                          </div>
                          <div className="pricing-feature">
                            {t("pricing.feature9")}
                          </div>
                          <div className="pricing-feature">
                            {t("pricing.feature10")}
                          </div>
                        </div>
                      </div>
                    </Grid>
                  </Grid> */}

                  {/* region */}

                  {/* <div className="hero-copy">
                    <h1 className="hero-title mt-0">{t("app.motto")}</h1>
                    <p className="hero-paragraph">{t("app.description")}</p>
                    <div className="hero-form field field-grouped">
                      <div className="control control-expanded">
                        <input
                          className="input"
                          type="email"
                          name="email"
                          onChange={(e) => {
                            validateEmail(e);
                            setEmailPhone(e.target.value);
                          }}
                          type="email"
                          placeholder={t("app.email_placeholder")}
                        />
                        {valid ? null : (
                          <div className="invalid-email-phone">
                            {t("app.invalid_email_phone")}
                          </div>
                        )}
                      </div>
                      <div className="control">
                        <a
                          className="
												button
												button-primary
												button-block
											"
                          href="#"
                          onClick={(e) => requestDemo(e)}
                        >
                          {t("app.request_demo")}
                        </a>
                      </div>
                    </div>
                  </div> */}
                  {/* end region */}
                </div>
              </section>
            </div>
          </main>
          <footer className="site-footer has-top-divider">
            <div className="container">
              <div className="site-footer-inner">
                <div className="brand footer-brand">
                  <div>
                    <span className="radiowrapper" id="wrap1">
                      <input
                        id="am"
                        checked={i18n.language === "am"}
                        onChange={(e) => i18n.changeLanguage("am")}
                        type="radio"
                      />
                      <label className="language-label am" htmlFor="am">
                        አማርኛ
                      </label>
                    </span>
                    <span className="radiowrapper" id="wrap2">
                      <input
                        id="en"
                        checked={i18n.language === "en"}
                        type="radio"
                        onChange={(e) => i18n.changeLanguage("en")}
                      />
                      <label className="language-label en" htmlFor="en">
                        English
                      </label>
                    </span>
                    {/* <span className="radiowrapper" id="wrap3">
                    <input
                      id="om"
                      checked={i18n.language === "om"}
                      type="radio"
                      onChange={e => i18n.changeLanguage("om")}
                    />
                    <label className="language-label" htmlFor="om">
                      Afaan oromoo
                    </label>
                  </span>
                  <span className="radiowrapper" id="wrap4">
                    <input
                      id="sm"
                      checked={i18n.language === "so"}
                      type="radio"
                      onChange={e => i18n.changeLanguage("so")}
                    />
                    <label className="language-label" htmlFor="sm">
                      Soomaali
                    </label>
                  </span>
                  <span className="radiowrapper" id="wrap5">
                    <input
                      id="ti"
                      name="language"
                      value="ti"
                      checked={t === "ti"}
                      type="radio"
                      onChange={e => i18n.changeLanguage("ti")}
                    />
                    <label className="language-label" htmlFor="ti">
                      ትግርኛ
                    </label>
                  </span> */}
                  </div>
                </div>
                <ul className="footer-links list-reset">
                  {/* <li>
                  <a href="#">{t("app.contact")}</a>
                </li>
                <li>
                  <a href="#">{t("app.about")}</a>
                </li> */}
                  <li>
                    <a
                      href="https://www.notion.so/20b49caeb34b47d9bb90b0cb703ce443?v=73f6ba3c16ed4fc38def8b6a66a0e814"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t("app.faq")}
                    </a>
                  </li>

                  <li>
                    <a href="tel:+251911702254">{t("app.support")}</a>
                  </li>
                </ul>
                <ul className="footer-social-links list-reset">
                  <li>
                    <a
                      href="https://fb.com/tethereth"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span className="screen-reader-text">Facebook</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <path
                          d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z"
                          fill="#d1d1d1"
                        />
                      </svg>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.twitter.com/@tethereth"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span className="screen-reader-text">Twitter</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <path
                          d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"
                          fill="#d1d1d1"
                        />
                      </svg>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://t.me/tethereth"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span className="screen-reader-text">Telegram</span>

                      <svg
                        width="24"
                        height="24"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        xmlSpace="preserve"
                        style={{
                          fillRule: "evenodd",
                          clipRule: "evenodd",
                          strokeLinejoin: "round",
                          strokeMiterlimit: "1.41421",
                        }}
                      >
                        <path
                          id="telegram-1"
                          d="M18.384,22.779c0.322,0.228 0.737,0.285 1.107,0.145c0.37,-0.141 0.642,-0.457 0.724,-0.84c0.869,-4.084 2.977,-14.421 3.768,-18.136c0.06,-0.28 -0.04,-0.571 -0.26,-0.758c-0.22,-0.187 -0.525,-0.241 -0.797,-0.14c-4.193,1.552 -17.106,6.397 -22.384,8.35c-0.335,0.124 -0.553,0.446 -0.542,0.799c0.012,0.354 0.25,0.661 0.593,0.764c2.367,0.708 5.474,1.693 5.474,1.693c0,0 1.452,4.385 2.209,6.615c0.095,0.28 0.314,0.5 0.603,0.576c0.288,0.075 0.596,-0.004 0.811,-0.207c1.216,-1.148 3.096,-2.923 3.096,-2.923c0,0 3.572,2.619 5.598,4.062Zm-11.01,-8.677l1.679,5.538l0.373,-3.507c0,0 6.487,-5.851 10.185,-9.186c0.108,-0.098 0.123,-0.262 0.033,-0.377c-0.089,-0.115 -0.253,-0.142 -0.376,-0.064c-4.286,2.737 -11.894,7.596 -11.894,7.596Z"
                          fill="#d1d1d1"
                        />
                      </svg>
                    </a>
                  </li>
                </ul>
                <div className="footer-copyright">
                  {t("app.copyright")}
                  {/* <a className="en" href="https://www.t.me/biniamkiros">
                    @biniamkiros
                  </a> */}
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </div>
  );
}

export default withTranslation()(Landing);
