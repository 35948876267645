import React from "react";
import {
  List,
  Datagrid,
  TextField,
  ChipField,
  EditButton,
  FunctionField,
  DateField,
  BooleanField,
  TextInput,
  SearchInput,
  useUpdate,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import { formattedLastTime } from "./formatter";
import { Filter } from "@material-ui/icons";
import { Button } from "@material-ui/core";

const useStyles = makeStyles({
  customer: { color: "green" },
  prospect: { color: "grey" },
  manager: { color: "yellow" },
  admin: { color: "red" },
});

const customersFilters = [
  <SearchInput source="q" alwaysOn />,
  <TextInput label="Type" source="role" defaultValue="Hello, World!" />,
];

const UserFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search By ID" source="uid" alwaysOn />
  </Filter>
);

// if (!teacher) return <Loading/>

const ColoredChipField = (props) => {
  const classes = useStyles();
  return (
    <ChipField
      className={classnames({
        [classes.customer]: props.record[props.source] === "customer",
        [classes.prospect]: props.record[props.source] === "prospect",
        [classes.manager]: props.record[props.source] === "manager",
        [classes.admin]: props.record[props.source] === "admin",
      })}
      {...props}
    />
  );
};
// Ensure the original component defaultProps are still applied as they may be used by its parents (such as the `Show` component):
ColoredChipField.defaultProps = ChipField.defaultProps;

const CustomersList = (props) => {
  return (
    <List
      {...props}
      // filters={<UserFilter />}
      // filters={customersFilters}
      filter={{ role: "customer" }}
    >
      <Datagrid rowClick="show">
        {/* expand={<UserShow />} */}
        <TextField source="name" />
        <TextField source="phone" />
        <TextField source="email" />
        {/* <TextField source="targetChannel" label="Channel" />
        <DateField source="targetDate" label="Reach" /> */}

        {/* <DateField source="registerDate" label="Registed on" /> */}
        <FunctionField
          label="since last session"
          render={(record) => `${formattedLastTime(record.lastSession)}`}
        />
        <ChipField source="status" />
        <ColoredChipField source="role" />
        <BooleanField source="isApproved" label="Approved" />
        <EditButton label="Edit" basePath="/customers" />
        {/* <DeleteButton label="Delete" basePath="/users" /> */}
      </Datagrid>
    </List>
  );
};

export default CustomersList;
