import React from "react";
import {
  List,
  Datagrid,
  TextField,
  ChipField,
  EditButton,
  FunctionField,
  DateField,
  BooleanField,
  TextInput,
  SearchInput,
  useUpdate,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import { formattedLastTime } from "./formatter";
import { Filter } from "@material-ui/icons";
import { Button } from "@material-ui/core";

const useStyles = makeStyles({
  lead: { color: "blue" },
  follower: { color: "grey" },
});

const customersFilters = [
  <SearchInput source="q" alwaysOn />,
  <TextInput label="Type" source="role" defaultValue="Hello, World!" />,
];

const UserFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search By ID" source="uid" alwaysOn />
  </Filter>
);

// if (!teacher) return <Loading/>

const ColoredChipField = (props) => {
  const classes = useStyles();
  return (
    <ChipField
      className={classnames({
        [classes.lead]: props.record[props.source] === "lead",
        [classes.follower]: props.record[props.source] === "follower",
      })}
      {...props}
    />
  );
};
// Ensure the original component defaultProps are still applied as they may be used by its parents (such as the `Show` component):
ColoredChipField.defaultProps = ChipField.defaultProps;

const LeadList = (props) => {
  return (
    <List
      {...props}
      // filters={<UserFilter />}
      // filters={customersFilters}
      filter={{ role: ["lead", "follower", "prospect"] }}
    >
      <Datagrid rowClick="show">
        {/* expand={<UserShow />} */}
        <TextField source="name" />
        <TextField source="phone" />
        <TextField source="email" />
        <TextField source="smsHits" />

        {/* <TextField source="targetChannel" label="Channel" />
        <DateField source="targetDate" label="Reach" /> */}

        {/* <DateField source="registerDate" label="Registed on" /> */}
        {/* <FunctionField
          label="since last session"
          render={(record) => `${formattedLastTime(record.lastSession)}`}
        /> */}
        {/* <ChipField source="status" /> */}
        <ColoredChipField source="role" />
        <BooleanField source="isMarketable" label="Marketable" />
        <EditButton label="Edit" basePath="/leads" />
        {/* <DeleteButton label="Delete" basePath="/users" /> */}
      </Datagrid>
    </List>
  );
};

export default LeadList;
