import React from "react";
import { Route } from "react-router-dom";
import Register from "./Register";
import ForgotPassword from "./ForgotPassword";
import VerifyPhone from "./VerifyPhone";
import VerifyEmail from "./VerifyEmail";
import ChangePasswordToken from "./ChangePasswordToken";
import ChangePasswordOTP from "./ChangePasswordOTP";

export default [
  <Route exact path="/register" component={Register} noLayout />,
  <Route exact path="/forgot-password" component={ForgotPassword} noLayout />,
  <Route exact path="/verify-phone" component={VerifyPhone} noLayout />,
  <Route exact path="/verify-email" component={VerifyEmail} noLayout />,
  // <Route exact path="/verify-token" component={VerifyToken} />,
  // <Route exact path="/verify-otp" component={VerifyOTP} />,
  <Route
    exact
    path="/email-reset-password"
    component={ChangePasswordToken}
    noLayout
  />,
  <Route
    exact
    path="/phone-reset-password"
    component={ChangePasswordOTP}
    noLayout
  />,
];
