const translateErrorMessageTocode = (errorMsg) => {
  if (errorMsg.includes("Unauthorized")) {
    return 401;
  }
  if (errorMsg.includes("unauthenticated")) {
    return 401;
  }
  if (errorMsg.includes("aborted")) {
    return 409;
  }
  if (errorMsg.includes("not-found")) {
    return 404;
  }
  return 200;
};

export default translateErrorMessageTocode;
