import React from "react";
import { Admin, ListGuesser, Resource, ShowGuesser } from "react-admin";
import dataProvider from "../api/dataProvider";
import authProvider from "../api/authProvider";
import { Dashboard } from "./Dashboard";

import UsersList from "./UsersList";
import UsersEdit from "./UsersEdit";
import UsersCreate from "./UsersCreate";
import AssetsList from "./AssetsList";
import AssetShow from "./AssetShow";
import ViolationsList from "./ViolationsList";
import VehiclesList from "./VehiclesList";
import VehiclesShow from "./VehiclesShow";
import VehiclesTrack from "./VehiclesTrack";
import VehiclesEdit from "./VehiclesEdit";
import VehiclesCreate from "./VehiclesCreate";
import DevicesList from "./DevicesList";
import DevicesEdit from "./DevicesEdit";
import DevicesCreate from "./DevicesCreate";
import NotificationsList from "./NotificationsList";
import UserIcon from "@material-ui/icons/People";
import RecentActorsIcon from "@material-ui/icons/RecentActors";
import VehicleIcon from "@material-ui/icons/DirectionsCar";
import DeviceIcon from "@material-ui/icons/SimCard";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import MapIcon from "@material-ui/icons/Map";
import BusinessIcon from "@material-ui/icons/Business";
import PlaceIcon from "@material-ui/icons/Place";
import PaymentIcon from "@material-ui/icons/Payment";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import TextsmsIcon from "@material-ui/icons/Textsms";
import ScheduleIcon from "@material-ui/icons/Schedule";
import DescriptionIcon from "@material-ui/icons/Description";
import NotificationImportant from "@material-ui/icons/NotificationImportant";
import GroupWork from "@material-ui/icons/GroupWork";
import AdminLayout from "./AdminLayout";

import { defaultTheme } from "react-admin";
import { createMuiTheme } from "@material-ui/core/styles";
import TrackEdit from "./TrackEdit";
import CompaniesList from "./CompaniesList";
import CompanyEdit from "./CompanyEdit";
import CompanyCreate from "./CompanyCreate";
import CompanyShow from "./CompanyShow";
import CustomersList from "./CustomersList";
import LeadList from "./LeadList";
import UserShow from "./UserShow";
import AreasList from "./AreasList";
import AreaShow from "./AreaShow";
import AreaEdit from "./AreaEdit";
import AreaCreate from "./AreaCreate";
import CohortsList from "./CohortsList";
import CohortShow from "./CohortShow";
import CohortEdit from "./CohortEdit";
import CohortCreate from "./CohortCreate";
import Login from "../auth/Login";
import CustomRoute from "../auth/CustomRoute";
import InvoicesList from "./InvoicesList";
import PaymentsList from "./PaymentsList";
import SettledList from "./SettledList";

import InvoiceShow from "./InvoiceShow";
import InvoiceCreate from "./InvoiceCreate";
import InvoiceEdit from "./InvoiceEdit";
import { DailyList } from "./DailyList";
import ContentCreate from "./ContentCreate";
import ContentEdit from "./ContentEdit";
import ContentList from "./ContentList";
import ContentShow from "./ContentShow";
import QueueList from "./QueueList";
import AdminDashboard from "./AdminDashboard";

const tetherTheme = createMuiTheme({
  ...defaultTheme,
  ...{
    palette: {
      primary: {
        main: "#0000FF",
      },
      secondary: {
        main: "#FBC02D",
      },
    },
  },
});

const ROLE = {
  OWNER: "owner",
  ADMIN: "admin",
  MANAGER: "manager",
  AGENT: "agent",
  CUSTOMER: "customer",
  PROSPECT: "prospect",
  SALES: "sales",
};

function isTeam(permissions) {
  return (
    permissions.role === ROLE.OWNER ||
    permissions.role === ROLE.ADMIN ||
    permissions.role === ROLE.MANAGER
  );
}

function isCutomer(permissions) {
  return permissions.role === ROLE.CUSTOMER || permissions === ROLE.PROSPECT;
}

function hasCompany(permissions) {
  return permissions.companyId ? true : false;
}

const getResources = (permissions) => {
  return [
    isCutomer(permissions) ? (
      <Resource
        options={{ label: "Stream" }}
        name="Track"
        list={VehiclesTrack}
        edit={TrackEdit}
        icon={MapIcon}
      />
    ) : null,
    isCutomer(permissions) ? (
      <Resource
        name="cohorts"
        list={CohortsList}
        create={CohortCreate}
        show={CohortShow}
        edit={CohortEdit}
        icon={GroupWork}
      />
    ) : null,
    hasCompany(permissions) ? <Resource name="vehicles" /> : null,
    isCutomer(permissions) ? (
      <Resource
        options={{ label: "Vehicles" }}
        name="Assets"
        list={AssetsList}
        edit={TrackEdit}
        show={AssetShow}
        icon={VehicleIcon}
      />
    ) : null,
    hasCompany(permissions) ? (
      <Resource
        options={{ label: "Reports" }}
        name="AzReports"
        list={AssetsList}
        icon={DescriptionIcon}
      />
    ) : null,
    hasCompany(permissions) ? (
      <Resource
        name="areas"
        list={AreasList}
        create={AreaCreate}
        show={AreaShow}
        edit={AreaEdit}
        icon={PlaceIcon}
      />
    ) : null,

    isCutomer(permissions) ? (
      <Resource
        name="Violations"
        list={ViolationsList}
        icon={NotificationImportant}
      />
    ) : null,
    // isTeam(permissions) ? (
    //   <Resource name="Dashboard" list={UsersList} icon={DashboardIcon} />
    // ) : null,

    isTeam(permissions) ? (
      <Resource
        name="Watchdog"
        list={VehiclesTrack}
        edit={TrackEdit}
        icon={MapIcon}
        options={{ label: "Watchdog" }}
      />
    ) : null,
    isTeam(permissions) ? (
      <Resource name="Notifications" list={NotificationsList} />
    ) : null,
    <Resource name="log" />,
    <Resource name="dash" />,
    <Resource name="server" />,
    <Resource name="health" />,
    <Resource name="marketing" />,
    <Resource name="report" />,
    isTeam(permissions) ? (
      <Resource
        name="companies"
        list={CompaniesList}
        edit={CompanyEdit}
        create={CompanyCreate}
        show={CompanyShow}
        icon={BusinessIcon}
      />
    ) : null,
    isTeam(permissions) ? (
      <Resource
        name="cohorts"
        list={CohortsList}
        create={CohortCreate}
        show={CohortShow}
        edit={CohortEdit}
        icon={GroupWork}
      />
    ) : null,
    isTeam(permissions) ? (
      <Resource
        name="leads"
        list={LeadList}
        edit={UsersEdit}
        show={UserShow}
        create={UsersCreate}
        icon={RecentActorsIcon}
      />
    ) : null,
    isTeam(permissions) ? (
      <Resource
        name="users"
        list={UsersList}
        edit={UsersEdit}
        show={UserShow}
        create={UsersCreate}
        icon={UserIcon}
        options={{ label: "Team" }}
      />
    ) : null,
    isTeam(permissions) ? (
      <Resource
        name="customers"
        list={CustomersList}
        edit={UsersEdit}
        show={UserShow}
        create={UsersCreate}
        icon={VerifiedUserIcon}
      />
    ) : null,
    isTeam(permissions) ? (
      <Resource
        name="vehicles"
        // list={ListGuesser}
        list={VehiclesList}
        show={VehiclesShow}
        edit={VehiclesEdit}
        create={VehiclesCreate}
        icon={VehicleIcon}
      />
    ) : null,
    isTeam(permissions) ? (
      <Resource
        name="devices"
        list={DevicesList}
        edit={DevicesEdit}
        create={DevicesCreate}
        icon={DeviceIcon}
      />
    ) : null,
    isTeam(permissions) ? (
      <Resource name="daily" list={DailyList} show={ShowGuesser} />
    ) : null,
    isTeam(permissions) ? (
      <Resource
        name="invoices"
        options={{ label: "Billing" }}
        list={InvoicesList}
        show={InvoiceShow}
        edit={InvoiceEdit}
        create={InvoiceCreate}
        icon={PaymentIcon}
      />
    ) : null,

    isTeam(permissions) ? (
      <Resource
        name="payments"
        list={PaymentsList}
        show={InvoiceShow}
        icon={AccountBalanceIcon}
      />
    ) : null,
    isTeam(permissions) ? (
      <Resource
        name="history"
        options={{ label: "Settled" }}
        list={SettledList}
        show={InvoiceShow}
        edit={InvoiceEdit}
        create={InvoiceCreate}
        icon={PaymentIcon}
      />
    ) : null,
    isTeam(permissions) ? (
      <Resource
        name="content"
        label="sms campaign"
        list={ContentList}
        create={ContentCreate}
        show={ContentShow}
        edit={ContentEdit}
        icon={TextsmsIcon}
      />
    ) : null,
    isTeam(permissions) ? (
      <Resource name="queue" list={QueueList} icon={ScheduleIcon} />
    ) : null,
  ];
};

function AdminPanel() {
  return (
    <Admin
      layout={AdminLayout}
      loginPage={Login}
      // dashboard={VehiclesTrack}
      dashboard={Dashboard}
      theme={tetherTheme}
      authProvider={authProvider}
      dataProvider={dataProvider}
      //  loginPage={TetherLoginPage}
      customRoutes={CustomRoute}
    >
      {(permissions) => getResources(permissions)}
    </Admin>
  );
}

export default AdminPanel;
