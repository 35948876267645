import React from "react";
import { Create, SimpleForm, TextInput, SelectInput } from "react-admin";
import HorizontalInputStyle from "./HorizontalInputStyle";

// const notification = [
//   { id: "CALL", name: "CALL" },
//   { id: "SMS", name: "SMS" },
//   { id: "PUSH", name: "PUSH" },
// ];

const areaStatus = [
  { id: "passive", name: "passive" },
  { id: "active", name: "active" },
];

// const defaultNotification = notification.map((notification) => notification.id);

const CohortCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="cohortName" />
        <TextInput source="cohortDescription" />

        <SelectInput
          source="status"
          style={HorizontalInputStyle}
          choices={areaStatus}
          required
        />
        <TextInput source="note" />
      </SimpleForm>
    </Create>
  );
};

export default CohortCreate;
