import { fetchUtils } from "react-admin";
import { apiUrl } from "./constants";

export const loadUser = () => {
  const data = {
    fcmToken: "fcmToken",
    deviceId: "deviceId",
    deviceInfo: "deviceInfo",
  };
  const options = {
    method: "POST",
    body: JSON.stringify(data),
  };
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  try {
    const tetherauth = localStorage.getItem("tetherauth");
    if (!tetherauth) throw new Error("Token not found on local storage");
    const { token } = JSON.parse(localStorage.getItem("tetherauth"));
    options.headers.set("x-auth-token", token);
  } catch (e) {
    console.log(e);
  }

  fetchUtils
    .fetchJson(`${apiUrl}/get`, options)
    .then((response) => {
      if (response.status < 200 || response.status >= 300) {
        throw new Error(response.statusText);
      }
      return response.json;
    })
    .then((auth) => {
      localStorage.setItem("tetherauth", JSON.stringify(auth));
      if (auth.verifyEmail) window.location.replace("/app#/verify-email");
      if (auth.verifyPhone) window.location.replace("/app#/verify-phone");
    })
    .catch((e) => {
      console.log("failed to load user", e);
    });
};

export const registerUser = async ({ name, phone, email, password }) => {
  const options = {
    method: "POST",
    body: JSON.stringify({ name, phone, email, password }),
    headers: new Headers({ "Content-Type": "application/json" }),
  };
  const response = await fetchUtils.fetchJson(`${apiUrl}/register`, options);
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  return response.json;
};

export const verifyUserEmail = async ({ token }) => {
  const options = {
    method: "POST",
    body: JSON.stringify({ token }),
    headers: new Headers({ "Content-Type": "application/json" }),
  };
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  try {
    const tetherauth = localStorage.getItem("tetherauth");
    if (!tetherauth) throw new Error("Token not found on local storage");
    const { token } = JSON.parse(localStorage.getItem("tetherauth"));
    options.headers.set("x-auth-token", token);
  } catch (e) {
    console.log(e);
  }
  const response = await fetchUtils.fetchJson(
    `${apiUrl}/verify-email`,
    options
  );
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  return response.json;
};

export const verifyUserPhone = async ({ otp }) => {
  const options = {
    method: "POST",
    body: JSON.stringify({ otp }),
    headers: new Headers({ "Content-Type": "application/json" }),
  };
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  try {
    const tetherauth = localStorage.getItem("tetherauth");
    if (!tetherauth) throw new Error("Token not found on local storage");
    const { token } = JSON.parse(localStorage.getItem("tetherauth"));
    options.headers.set("x-auth-token", token);
  } catch (e) {
    console.log("Auth token not set: ", e.message);
  }
  const response = await fetchUtils.fetchJson(
    `${apiUrl}/verify-phone`,
    options
  );
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  return response.json;
};

export const resendUserVerification = async () => {
  let options;
  try {
    const tetherauth = localStorage.getItem("tetherauth");
    if (!tetherauth) throw new Error("Token not found on local storage");
    const { token, phone, email } = JSON.parse(
      localStorage.getItem("tetherauth")
    );

    options = {
      method: "POST",
      body: JSON.stringify({ phone, email }),
      headers: new Headers({ "Content-Type": "application/json" }),
    };

    if (!options.headers) {
      options.headers = new Headers({ Accept: "application/json" });
    }

    options.headers.set("x-auth-token", token);
  } catch (e) {
    console.log("Auth token not set: ", e.message);
  }

  const response = await fetchUtils.fetchJson(
    `${apiUrl}/resend-verification`,
    options
  );
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  return response.json;
};

export const forgotPasswordUser = async ({ phone, email }) => {
  const options = {
    method: "POST",
    body: JSON.stringify({ phone, email }),
    headers: new Headers({ "Content-Type": "application/json" }),
  };
  const response = await fetchUtils.fetchJson(
    `${apiUrl}/forgot-password`,
    options
  );
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  return response.json;
};

export const verifyResetTokenUser = async ({ token }) => {
  const options = {
    method: "POST",
    body: JSON.stringify({ token }),
    headers: new Headers({ "Content-Type": "application/json" }),
  };
  const response = await fetchUtils.fetchJson(
    `${apiUrl}/verify-email-reset-password-token`,
    options
  );
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  return response.json;
};

export const verifyResetOTPUser = async ({ otp }) => {
  const options = {
    method: "POST",
    body: JSON.stringify({ otp }),
    headers: new Headers({ "Content-Type": "application/json" }),
  };
  const response = await fetchUtils.fetchJson(
    `${apiUrl}/verify-phone-reset-password-otp`,
    options
  );
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  return response.json;
};

export const changeUserPasswordToken = async ({ token, password }) => {
  const options = {
    method: "POST",
    body: JSON.stringify({ token, password }),
    headers: new Headers({ "Content-Type": "application/json" }),
  };
  const response = await fetchUtils.fetchJson(
    `${apiUrl}/email-reset-password`,
    options
  );
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  return response.json;
};

export const changeUserPasswordOTP = async ({ otp, password }) => {
  const options = {
    method: "POST",
    body: JSON.stringify({ otp, password }),
    headers: new Headers({ "Content-Type": "application/json" }),
  };
  const response = await fetchUtils.fetchJson(
    `${apiUrl}/phone-reset-password`,
    options
  );
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  return response.json;
};
