// const validator = require("validator");
import validator from "validator";

export const isValidEmail = (email) => {
  // console.log("validphone?", Boolean(email) && !validator.isEmail(email));
  return Boolean(email) && validator.isEmail(email);
};

export const isValidPhone = (phone) => {
  // console.log("validphone?", Boolean(phone) && /^\d{9}$/.test(phone));
  return Boolean(phone) && /^\d{9}$/.test(phone);
};

export const isValidPassword = (password) => {
  return (
    password.match(/\d/) && password.match(/[a-zA-Z]/) && password.length > 6
  );
};

export const getValidEmail = (email) => {
  return isValidEmail(email) ? email : null;
};

export const getValidPhone = (phone) => {
  return isValidPhone(phone) ? phone : null;
};
