import { TextField } from "@material-ui/core";
import { cyan, pink } from "@material-ui/core/colors";

export const styles = {
  main: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    background: "#d9d9d9",
  },
  card: {
    width: "420px",
  },
  avatar: {
    margin: "1em",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
  },
  form: {
    padding: "0 1em 1em 1em",
  },
  input: {
    display: "flex",
    minWidth: "350px",
    marginTop: "1rem",
    marginBottom: "1rem",
  },
  hint: {
    textAlign: "center",
    marginTop: "1em",
    color: "#ccc",
  },
  link: { paddingLeft: "5px", cursor: "pointer" },
};

export function getColorsFromTheme(theme) {
  if (!theme) return { primary1Color: cyan, accent1Color: pink };
  const {
    palette: { primary1Color, accent1Color },
  } = theme;
  return { primary1Color, accent1Color };
}

// see http://redux-form.com/6.4.3/examples/material-ui/
export const renderInput = ({
  meta: { touched, error } = {},
  input: { ...inputProps },
  ...props
}) => (
  <TextField
    error={touched && error}
    {...inputProps}
    {...props}
    variant="outlined"
    fullWidth
  />
);
