import React from "react";
import {
  List,
  Datagrid,
  TextField,
  ChipField,
  EditButton,
  FunctionField,
  DateField,
  BooleanField,
  ReferenceField,
  TextInput,
  SearchInput,
  useUpdate,
  NumberField,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import { formattedLastTime } from "./formatter";
import { Filter } from "@material-ui/icons";
import { Button } from "@material-ui/core";

const useStyles = makeStyles({
  lead: { color: "blue" },
  follower: { color: "grey" },
  customer: { color: "green" },
});

const ColoredChipField = (props) => {
  const classes = useStyles();
  return (
    <ChipField
      className={classnames({
        [classes.lead]: props.record[props.source] === "lead",
        [classes.follower]: props.record[props.source] === "follower",
        [classes.customer]: props.record[props.source] === "customer",
      })}
      {...props}
    />
  );
};
// Ensure the original component defaultProps are still applied as they may be used by its parents (such as the `Show` component):
ColoredChipField.defaultProps = ChipField.defaultProps;

const QueueList = (props) => (
  <List {...props}>
    <Datagrid rowClick="show">
      <ReferenceField
        label="Person"
        source="userRef"
        reference="customers"
        link="show"
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        label="Content"
        source="contentRef"
        reference="content"
        link="show"
      >
        <TextField source="title" />
        {/* <FunctionField
          label="Content"
          render={(record) => `Content ${record.message.substring(0, 5)}`}
        /> */}
        {/* <TextField source="message" /> */}
        {/* <TextField source="for" /> */}
      </ReferenceField>
      <TextField source="to" />
      <TextField source="method" />
      <TextField source="message" />
      <DateField source="expireAt" />
    </Datagrid>
  </List>
);

export default QueueList;
