import React from "react";
import {
  List,
  Datagrid,
  TextField,
  ChipField,
  BooleanField,
  FunctionField,
  DateField,
  ReferenceField,
  Show,
  useUpdate,
  SimpleShowLayout,
  ShowController,
  ShowView,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import CardActions from "@material-ui/core/CardActions";
import { formatMoney, formattedDate } from "./formatter";

const useStyles = makeStyles({
  paid: { color: "green" },
  active: { color: "grey" },
  overdue: { color: "yellow" },
  confirm: { color: "red" },
});

const ColoredChipField = (props) => {
  const classes = useStyles();
  return (
    <ChipField
      className={classnames({
        [classes.customer]: props.record[props.source] === "active",
        [classes.prospect]: props.record[props.source] === "sent",
        [classes.manager]: props.record[props.source] === "overdue",
        [classes.manager]: props.record[props.source] === "confirm",
        [classes.admin]: props.record[props.source] === "paid",
        [classes.manager]: props.record[props.source] === "closed",
      })}
      {...props}
    />
  );
};
// Ensure the original component defaultProps are still applied as they may be used by its parents (such as the `Show` component):
ColoredChipField.defaultProps = ChipField.defaultProps;

const NoneActions = (props) => <CardActions {...props} />;

const PaymentPanel = (props) => (
  <ShowController {...props}>
    {(controllerProps) => (
      <ShowView {...props} {...controllerProps}>
        {/* <Show {...props} actions={<NoneActions />}> */}
        <SimpleShowLayout>
          {controllerProps.record &&
            Array.isArray(controllerProps.record.items) && (
              <FunctionField
                label="Items"
                render={(record) => (
                  <ul>
                    {record.items.map((item) => (
                      <li key={item.description}>
                        {item.description}: {formatMoney(item.amount, 2)}
                      </li>
                    ))}
                  </ul>
                )}
              />
            )}
          <DateField source="issuedDate" />
          <DateField source="dueDate" />

          {controllerProps.record &&
            Array.isArray(controllerProps.record.transaction) && (
              <FunctionField
                label="Total transaction"
                render={(record) => (
                  <ul>
                    {record.transaction.map((item) => (
                      <li key={item.transactionNumber}>
                        {item.transactionNumber}{" "}
                        {formattedDate(item.transactionDate)}: {item.senderName}{" "}
                        via {item.bankName} {item.transactionAmount}
                      </li>
                    ))}
                  </ul>
                )}
              />
            )}
          {/* <DateField source="nextExpiryDate" />
    <DateField source="issuedNoticeDate" />
    <DateField source="reminderNoticeDate" />
    <DateField source="dueNoticeDate" />
    <DateField source="overdueNoticeDate" />
    <DateField source="warningNoticeDate" />
    <DateField source="suspensionNoticeDate" /> */}
        </SimpleShowLayout>
        {/* </Show> */}
      </ShowView>
    )}
  </ShowController>
);

const SettledList = (props) => {
  return (
    <List {...props} filter={{ status: ["closed", "paid"] }}>
      <Datagrid rowClick="show" expand={<PaymentPanel />}>
        <TextField source="invoiceNumber" />
        <ReferenceField
          label="Customer"
          source="userRef"
          reference="customers"
          link="show"
          emptyText="No User"
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          label="Vehicle"
          source="vehicleRef"
          reference="vehicles"
          link="show"
          emptyText="No Vehicle"
        >
          <TextField source="plate" />
        </ReferenceField>
        <DateField source="issuedDate" />
        <DateField source="dueDate" />
        <ChipField source="status" />
        <FunctionField
          label="Total amount"
          render={(record) =>
            `${formatMoney(
              record.items.map((i) => i.amount).reduce((a, b) => a + b, 0),
              2
            )}`
          }
        />
        <FunctionField
          label="Total transaction"
          render={(record) =>
            `${formatMoney(
              record.transactions
                .map((i) => i.transactionAmount)
                .reduce((a, b) => a + b, 0),
              2
            )}`
          }
        />
      </Datagrid>
    </List>
  );
};

export default SettledList;
