export function getParamsFromUrl() {
  const href = window.location.href;
  const split = href.split("?");
  const params = split.length > 1 ? split[1] : "";

  // Be sure url params exist
  if (params && params !== "") {
    const result = params.split("&").reduce(function (res, item) {
      const parts = item.split("=");
      res[parts[0]] = parts[1];
      return res;
    }, {});

    return result;
  } else return null;
}
