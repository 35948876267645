import React, { useEffect, useState } from "react";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import {
  Notification,
  useNotify,
  defaultTheme,
  Loading,
  useRedirect,
  useLogout,
} from "react-admin";
import { Link } from "@material-ui/core";
import { Button, Card, CardActions } from "@material-ui/core";
import { useHistory } from "react-router";
import { styles } from "../utils/authFormStyle";
import { resendUserVerification, verifyUserEmail } from "../utils/useUser";
import { getParamsFromUrl } from "../utils/urlUtils";

const VerifyEmail = ({ theme }) => {
  let history = useHistory();
  const successText =
    "We have successfully verified your email. You can now proceed to your dashboard.";
  const failText =
    "The verification token have expired or is unavailable. You can resend the verification email by pressing the button bellow.";
  const emptyText =
    "The account is awaiting email verification. Go to your email and activate your account. If you did not receive an email, You can resend the verification email by pressing the button bellow.";
  // const redirect = useRedirect();
  const notify = useNotify();
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState("none");
  const logout = useLogout();

  const notiRef = React.createRef(null);
  const [VerificationRes, setVerificationRes] = useState(emptyText);
  // const [token, setToken] = useState("");

  const formTheme = createMuiTheme(defaultTheme);
  // const { primary1Color, accent1Color } = getColorsFromTheme(formTheme);

  useEffect(() => {
    const params = getParamsFromUrl();
    const token = params?.token;

    if (token) {
      setLoading(true);
      setResult((result) => "none");
      verifyUserEmail({ token })
        .then(() => {
          notify("Verification successful");
          setResult((result) => "success");
          setVerificationRes((VerificationRes) => successText);
        })
        .catch((e) => {
          notify("Verification failed", { type: "warning" });
          setResult((result) => "fail");
          setVerificationRes((VerificationRes) => failText);
        })
        .finally(() => {
          setLoading(false);
        });
    }
    // effect cleanup function
    return () => {};
  }, []);

  const resendVerification = () => {
    setLoading(true);
    setResult((result) => "none");

    resendUserVerification()
      .then(() => {
        notify("Verification email sent");
        setResult((result) => "none");
        setVerificationRes((VerificationRes) => emptyText);
      })
      .catch((e) => {
        notify("Sending verification email failed", { type: "warning" });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (loading) return <Loading />;

  return (
    <ThemeProvider theme={formTheme}>
      <div style={styles.main}>
        <Card style={styles.card}>
          <form name="verifyEmailForm">
            <div style={styles.form}>
              <p style={styles.hint}>Email verification</p>
              <p style={styles.hint}>{VerificationRes}</p>
              {result === "success" ? (
                <div style={styles.input} label="gotoapp">
                  <Button
                    color="primary"
                    variant="contained"
                    size="large"
                    fullWidth
                    onClick={() => {
                      history.push("/");
                    }}
                  >
                    go to app
                  </Button>
                </div>
              ) : null}
              {result !== "success" ? (
                <div style={styles.input}>
                  <Button
                    color="primary"
                    variant="contained"
                    size="large"
                    fullWidth
                    onClick={() => {
                      resendVerification();
                    }}
                  >
                    Resend verification email
                  </Button>
                </div>
              ) : null}{" "}
              {result !== "success" ? (
                <CardActions>
                  <div style={styles.link}>
                    <Link
                      onClick={() => {
                        logout();
                      }}
                    >
                      Logout
                    </Link>
                  </div>
                </CardActions>
              ) : null}
            </div>
          </form>
        </Card>
        <div ref={notiRef}>
          <Notification />
        </div>
      </div>
    </ThemeProvider>
  );
};

export default VerifyEmail;
