import React, { useState, useEffect, useRef, forwardRef } from "react";
// import { View, Text, StyleSheet, TouchableOpacity } from "react-native";
// import { BottomSheet } from "react-native-btr";

import keyBy from "lodash/keyBy";
import {
  useQuery,
  Loading,
  ListContextProvider,
  EditButton,
} from "react-admin";

import { getBearing, getVehicleIcon } from "./getVehicleIcon";
import {
  MapContainer,
  TileLayer,
  Popup,
  Polygon,
  Polyline,
  Tooltip,
} from "react-leaflet";
import ReactLeafletDriftMarker from "react-leaflet-drift-marker";
import "leaflet-fullscreen/dist/Leaflet.fullscreen.js";
import "leaflet-fullscreen/dist/leaflet.fullscreen.css";
import "leaflet/dist/leaflet.css";
import "leaflet.tilelayer.colorfilter/src/leaflet-tilelayer-colorfilter.js";

import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import L from "leaflet";
import "leaflet-rotatedmarker";
import SettingsRemote from "@material-ui/icons/SettingsRemote";
// import { getBearing } from "../utils/geoUtil";
// import Control from "react-leaflet-control";
// import WebSocket from "ws";

// const params = {
//   pagination: { page: 1, perPage: 10 },
//   sort: { order: "ASC" },
//   filter: "none",
// };
const outerBounds = [[9.013431657356811, 38.76323997308592]];

const lightMap =
  "https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png";
const lightAttribution =
  '&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors';

const darkMap =
  "https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png";
const darkAttribution =
  '&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors';

const defaultMap = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";
const defaultAttribution =
  '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors';

const reliefMap = "https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png";
const reliefAttribution =
  'Map data: &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)';

let mapFilter = ["hue:180deg", "invert:100%"];
let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
});

// L.tileLayer.colorFilter("https://maps.wikimedia.org/osm-intl/{z}/{x}/{y}.png", {
//   attribution:
//     '<a href="https://wikimediafoundation.org/wiki/Maps_Terms_of_Use">Wikimedia</a>',
//   filter: mapFilter,
// });

L.Marker.prototype.options.icon = DefaultIcon;

const getCoordinates = (coordinates) => {
  // console.log(coordinates);
  if (!coordinates) return [0, 0];
  if (!coordinates.includes(",")) return [0, 0];
  const array = coordinates.split(",");
  return [array[0] ? array[0] : 0, array[1] ? array[1] : 0];
};

const onMoveEnd = () => {
  //   //todo
};
const RotatedMarker = forwardRef(({ children, ...props }, forwardRef) => {
  const markerRef = useRef();

  const { rotationAngle, rotationOrigin } = props;
  useEffect(() => {
    const marker = markerRef.current;
    if (marker) {
      marker.setRotationAngle(rotationAngle);
      marker.setRotationOrigin(rotationOrigin);
    }
  }, [rotationAngle, rotationOrigin]);

  return (
    <ReactLeafletDriftMarker
      ref={(ref) => {
        markerRef.current = ref;
        if (forwardRef) {
          forwardRef.current = ref;
        }
      }}
      {...props}
      // time in ms that marker will take to reach its destination
      duration={15000}
    >
      {children}
    </ReactLeafletDriftMarker>
  );
});

// const styles = StyleSheet.create({
//   container: {
//     flex: 1,
//     justifyContent: "center",
//     alignItems: "center",
//   },
//   button: {
//     backgroundColor: "#fff",
//     borderWidth: 2,
//     borderRadius: 50,
//     padding: 16,
//   },
//   card: {
//     backgroundColor: "#fff",
//     height: 250,
//     justifyContent: "center",
//     alignItems: "center",
//   },
// });
// let socket = null;
// const socketUrl =
//   process.env.REACT_APP_DEVELOPMENT_ENV === "dev"
//     ? "wss://localhost:5000/p?token="
//     : window.location.protocol === "https:"
//     ? window.location.origin + "wss://tether.qamarica.com/p?token="
//     : "ws://tether.qamarica.com/p?token=";

const VehiclesTrack = (props) => {
  // const [visible, setVisible] = useState(false);
  const [vehicles, setVehicles] = useState([]);
  const [mapStyle, setMapStyle] = useState(0);

  const [page, setPage] = useState(1);
  const perPage = 50;
  const { data, total, loading, error } = useQuery({
    type: "getList",
    resource: "assets",
    payload: {
      pagination: { page, perPage },
      sort: { field: "id", order: "ASC" },
      filter: {},
    },
  });

  const { id } = localStorage.getItem("tetherauth")
    ? JSON.parse(localStorage.getItem("tetherauth"))
    : ["", 0];

  useEffect(() => {
    // socket = new WebSocket(socketUrl + token);
    // socket.onopen = () => {
    //   // connection opened
    //   if (!socket) return;
    //   if (socket.readyState !== WebSocket.OPEN) return;
    //   socket.send("admin panel"); // send a message
    //   console.log("socket open");
    // };
    // socket.onmessage = (e) => {
    //   // a message was received
    //   updateVehicleMarker(e);
    //   console.log(e.data);
    // };
    // socket.onerror = (e) => {
    //   // an error occurred
    //   console.log(e.message);
    // };
    // socket.onclose = (e) => {
    //   // connection closed
    //   console.log(e.code, e.reason);
    //   console.log("socket close");
    // };
    // socket.onerror = (err) => {
    //   console.error(
    //     "Socket encountered error: ",
    //     err.message,
    //     "Closing socket"
    //   );
    //   if (socket) socket.close();
    // };

    if (data && !loading && !error) {
      setVehicles(data);
    }
    // effect cleanup function
    return () => {
      // any socket closure logic, cleanup etc..
      // socket = null;
      // console.log("socket null");
    };
  }, [data, total, loading, error]); // <-- empty dependency array

  // function checkSocket() {
  //   if (!socket || socket.readyState === WebSocket.CLOSED) socket.connect(); //check if websocket instance is closed, if so call `connect` function.
  // }

  // function toggle() {
  //   setVisible((visible) => !visible);
  // }

  // console.log(this.state.vehicles);
  // filter={mapFilter}

  function MapPlaceholder() {
    return (
      <p>
        Map of Ethiopia.{" "}
        <noscript>You need to enable JavaScript to see this map.</noscript>
      </p>
    );
  }

  // const applyRotation = (marker, _options) => {
  //   const oldIE = L.DomUtil.TRANSFORM === "msTransform";
  //   const options = Object.assign(_options, { rotationOrigin: "center" });
  //   const { rotationAngle, rotationOrigin } = options;

  //   if (rotationAngle && marker) {
  //     marker._icon.style[L.DomUtil.TRANSFORM + "Origin"] = rotationOrigin;

  //     if (oldIE) {
  //       // for IE 9, use the 2D rotation
  //       marker._icon.style[
  //         L.DomUtil.TRANSFORM
  //       ] = `rotate(${rotationAngle} deg)`;
  //     } else {
  //       // for modern browsers, prefer the 3D accelerated version
  //       marker._icon.style[
  //         L.DomUtil.TRANSFORM
  //       ] += ` rotateZ(${rotationAngle}deg)`;
  //     }
  //   }
  // };

  // function updateVehicleMarker(log) {
  //   if (log.coordinate) {
  //     vehicles.find((v) => (v.id = log.vehicleRef)).lastLocation =
  //       log.coordinate;
  //     vehicles.find((v) => (v.id = log.vehicleRef)).log.push(log.coordinate);

  //     setVehicles(vehicles);
  //   } else {
  //     console.log("invalid log");
  //   }
  // }

  function getVehicleRotation(vehicle) {
    const vLog = vehicle.log;
    if (!vLog) return 300;
    const size = vLog.length;
    if (!vLog.length) return 300;

    const prevPosition = vehicle.log[size - 1].coordinate;
    const currPosition = size > 1 ? vehicle.log[size - 2].coordinate : "8:38";

    return getBearing(prevPosition.toString(), currPosition.toString());
  }

  function getMarkers(vehicles, userId) {
    return Array.isArray(vehicles)
      ? vehicles.map((v) => (
          <RotatedMarker
            icon={getVehicleIcon(v, id)}
            key={v.id}
            position={getCoordinates(v.lastLocation)}
            rotationAngle={getVehicleRotation(v)}
            rotationOrigin="center"
          >
            <Popup>
              {v.tag + " " + v.brand + " " + v.model}
              <br /> <br />
              {v.plate}
              <br />
              <br />
              <br />
              {/* <VehicleControlButton vehicle={v} userId={userId} /> */}
              {v.ownerRef === userId ? (
                <EditButton
                  basePath="/assets"
                  label="Vehicle control"
                  record={v}
                  icon={<SettingsRemote />}
                />
              ) : null}
            </Popup>
          </RotatedMarker>
        ))
      : null;
  }

  function getPolygon(vehicles, userId) {
    return Array.isArray(vehicles)
      ? vehicles.map((v) =>
          v.ownerRef === userId || v.driverRef === userId ? (
            <Polygon
              key={v.id}
              pathOptions={{ color: v.colorHex }}
              positions={
                v.ownerRef === userId
                  ? v.ownerFence.map((point) => getCoordinates(point))
                  : v.driverRef === userId
                  ? v.driverFence.map((point) => getCoordinates(point))
                  : []
              }
            >
              <Tooltip sticky>GeoFence for vehicle {v.plate}</Tooltip>
            </Polygon>
          ) : null
        )
      : null;
  }

  function getPolyline(vehicles, userId) {
    return Array.isArray(vehicles)
      ? vehicles.map((v) =>
          v.ownerRef === userId || v.driverRef === userId ? (
            Array.isArray(v.log) ? (
              <Polyline
                key={v.id}
                pathOptions={{ color: v.colorHex }}
                positions={v.log.map((point) =>
                  getCoordinates(point.coordinate)
                )}
              >
                <Tooltip sticky>24 hour trail for vehicle {v.plate}</Tooltip>
              </Polyline>
            ) : null
          ) : null
        )
      : null;
  }

  function getTilesLayer() {
    switch (mapStyle) {
      case 0:
        return <TileLayer attribution={defaultAttribution} url={defaultMap} />;
      case 1:
        return <TileLayer attribution={lightAttribution} url={lightMap} />;
      case 2:
        return <TileLayer attribution={darkAttribution} url={darkMap} />;
      case 3:
        return <TileLayer attribution={reliefAttribution} url={reliefMap} />;
      default:
        return <TileLayer attribution={defaultAttribution} url={defaultMap} />;
    }
  }

  // function changeMapStyle() {
  //   const val = mapStyle + 1;
  //   setMapStyle(val > 3 ? 0 : val);
  // }

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <p>ERROR: {error}</p>;
  }

  return (
    <ListContextProvider
      value={{
        data: keyBy(data, "id"),
        ids: data.map(({ id }) => id),
        total,
        page,
        perPage,
        setPage,
        currentSort: { field: "id", order: "ASC" },
        basePath: "/assets",
        resource: "assets",
        selectedIds: [],
      }}
      options={{ title: "Live stream" }}
    >
      <MapContainer
        // center={[9.013431657356811, 38.76323997308592]}
        // zoom={8}
        bounds={
          data.length > 0
            ? data.map((v) => getCoordinates(v.lastLocation))
            : outerBounds
        }
        boundsOptions={{ padding: [50, 50] }}
        onmoveend={onMoveEnd()}
        scrollWheelZoom={true}
        placeholder={<MapPlaceholder />}
        fullscreenControl={true}
        style={{ height: "90vh" }}
        filter={mapFilter}
      >
        {getTilesLayer()}
        {/* <Control position="topleft">
          <button onClick={() => changeMapStyle()}>Reset View</button>
        </Control> */}
        {getMarkers(vehicles, id)}
        {getPolyline(vehicles, id)}
        {getPolygon(vehicles, id)}
        {/* <View style={styles.container}>
          <TouchableOpacity onPress={toggle}>
            <View style={styles.button}>
              <Text>Toggle BottomSheet</Text>
            </View>
          </TouchableOpacity>
          <BottomSheet
            visible={visible}
            onBackButtonPress={toggle}
            onBackdropPress={toggle}
          >
            <View style={styles.card}>
              <Text>Place your custom view inside BottomSheet</Text>
            </View>
          </BottomSheet>
        </View> */}
      </MapContainer>
    </ListContextProvider>
  );
};

export default VehiclesTrack;
