import React, { useState } from "react";
import PropTypes from "prop-types";
import { propTypes, reduxForm } from "redux-form";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import Lock from "@material-ui/icons/Lock";
import {
  Notification,
  defaultTheme,
  translate,
  userLogin as userLoginAction,
} from "react-admin";
import { Link } from "@material-ui/core";
import {
  Avatar,
  TextField,
  Button,
  Card,
  CardActions,
} from "@material-ui/core";
import {
  getValidEmail,
  getValidPhone,
  isValidPhone,
  isValidEmail,
  isValidPassword,
} from "../utils/validator";
import { useHistory } from "react-router";
import { getColorsFromTheme, styles } from "../utils/authFormStyle";

const Login = ({ userLogin, location }) => {
  let history = useHistory();
  const [username, setUsername] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [usernameError, setUsernameError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const loginTheme = createMuiTheme(defaultTheme);
  const { primary1Color, accent1Color } = getColorsFromTheme(loginTheme);

  const handleUsernameChange = (value) => {
    setUsername(value);
    setPhone(getValidPhone(value));
    setEmail(getValidEmail(value));

    setUsernameError((er) => !isValidPhone(value) && !isValidEmail(value));
  };

  const handlePasswordChange = (value) => {
    setPassword(value);
    setPasswordError((er) => !isValidPassword(value));
  };

  const submit = () => {
    // will call authProvider.login({ email, password })
    userLogin(
      { phone, email, password },
      location.state ? location.state.nextPathname : "/"
    );
  };

  const useDemoAccount = () => {
    document.loginForm.username.value = "900000000";
    document.loginForm.password.value = "password";
    setUsername("900000000");
    setPassword("password");
    setPhone(getValidPhone("900000000"));
    setEmail(getValidEmail("900000000"));
  };

  return (
    <ThemeProvider theme={loginTheme}>
      <div style={styles.main}>
        <Card style={styles.card}>
          <div style={styles.avatar}>
            <Avatar backgroundcolor={accent1Color} icon={<Lock />} size={60} />
          </div>
          <form name="loginForm">
            <div style={styles.form}>
              <p style={styles.hint}>Tether Login</p>
              <div style={styles.input}>
                <TextField
                  variant="outlined"
                  fullWidth
                  name="username"
                  label="username"
                  helperText="Enter your email or phone number in valid format eg. 911223344 or name@email.com"
                  required
                  error={usernameError}
                  defaultValue={username}
                  onChange={(e) => handleUsernameChange(e.target.value)}
                />
              </div>
              <div style={styles.input}>
                <TextField
                  variant="outlined"
                  fullWidth
                  name="password"
                  label="password"
                  type="password"
                  helperText="Password must be more than 6 chars long and must include special characters and capital letters"
                  required
                  error={passwordError}
                  defaultValue={password}
                  onChange={(e) => handlePasswordChange(e.target.value)}
                />
              </div>
              <div style={styles.input}>
                <Button
                  color="primary"
                  variant="contained"
                  size="large"
                  // disabled={submitting}
                  fullWidth
                  onClick={submit}
                >
                  Log in
                </Button>
              </div>
              <div style={styles.input}>
                <Button
                  variant="outlined"
                  size="large"
                  fullWidth
                  onClick={() => {
                    history.push("/register");
                  }}
                >
                  Register
                </Button>
              </div>
              <CardActions>
                {" "}
                <div style={styles.link}>
                  <Link
                    onClick={() => {
                      history.push("/forgot-password");
                    }}
                  >
                    Forgot password?
                  </Link>
                </div>
              </CardActions>
              <CardActions>
                <Button variant="text" size="small" onClick={useDemoAccount}>
                  Use demo account
                </Button>
              </CardActions>
            </div>
          </form>
        </Card>
        <Notification />
      </div>
    </ThemeProvider>
  );
};

Login.propTypes = {
  ...propTypes,
  authClient: PropTypes.func,
  previousRoute: PropTypes.string,
  theme: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired,
};

Login.defaultProps = {
  theme: {},
};

const enhance = compose(
  translate,
  reduxForm({
    form: "login",
    validate: (values, props) => {
      const errors = {};
      // const { translate } = props;

      if (!values.username) errors.username = "required";

      if (!values.password) errors.password = "required";

      return errors;
    },
  }),
  connect(null, { userLogin: userLoginAction })
);

export default enhance(Login);
