import React from "react";

const StatText = (props) => {
  const { title, value, tint = "#000000", isSmall = false } = props;

  return (
    <div className="stat-container">
      <div className="stat-title" style={{ color: `${tint}99` }}>
        {title}
      </div>
      {value.length > 3 || isSmall ? (
        <div className="stat-value-small" style={{ color: `${tint}` }}>
          {value}
        </div>
      ) : (
        <div className="stat-value" style={{ color: `${tint}` }}>
          {value}
        </div>
      )}
    </div>
  );
};

export default StatText;
