import React, { useEffect, useState } from "react";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import Lock from "@material-ui/icons/Lock";
import {
  Notification,
  useNotify,
  defaultTheme,
  Loading,
  useRedirect,
} from "react-admin";
import { Link } from "@material-ui/core";
import {
  Avatar,
  TextField,
  Button,
  Card,
  CardActions,
} from "@material-ui/core";
import { isValidPassword } from "../utils/validator";
import { useHistory } from "react-router";
import { getColorsFromTheme, styles } from "../utils/authFormStyle";
import {
  verifyResetTokenUser,
  changeUserPasswordToken,
} from "../utils/useUser";
import { getParamsFromUrl } from "../utils/urlUtils";

const ChangePasswordToken = ({ theme }) => {
  let history = useHistory();
  const successText =
    "We have successfully verified your email. You can now proceed to your dashboard.";
  const failText =
    "The reset password token have expired or is unavailable. Go to reset password page and try again.";

  const titleTextVerify = "Verify you own the email address";
  const titleTextChange = "Your new password";

  const redirect = useRedirect();
  const notify = useNotify();
  const [loading, setLoading] = useState(false);

  const [token, setToken] = useState("");
  const [password, setPassword] = useState("");
  const [cpassword, setCpassword] = useState("");

  const [cpasswordError, setCpasswordError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [result, setResult] = useState("none");
  const [VerificationRes, setVerificationRes] = useState(failText);

  const registerTheme = createMuiTheme(defaultTheme);
  const { primary1Color, accent1Color } = getColorsFromTheme(registerTheme);

  useEffect(() => {
    const params = getParamsFromUrl();
    const value = params?.token;
    setToken((t) => value);

    console.log(value);
    if (value) {
      setLoading(true);
      setResult((result) => "none");
      verifyResetTokenUser({ token: value })
        .then(() => {
          setResult((result) => "success");
          setVerificationRes((VerificationRes) => successText);
        })
        .catch((e) => {
          setResult((result) => "fail");
          setVerificationRes((VerificationRes) => failText);
        })
        .finally(() => {
          setLoading(false);
        });
    }
    // effect cleanup function
    return () => {};
  }, []);

  const handlePasswordChange = (value) => {
    setPassword(value);
    setPasswordError((er) => !isValidPassword(value));
  };

  const handleCpasswordChange = (value) => {
    setCpassword(value);
    setCpasswordError((er) => !isValidPassword(value) || value !== password);
  };

  const submit = (e) => {
    e.preventDefault();
    // const handleClick = () => {
    setLoading(true);

    changeUserPasswordToken({ token, password })
      .then(() => {
        notify("Password change successful");
        redirect("/login");
      })
      .catch((e) => {
        notify("Password change failed " + e.message, { type: "warning" });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (loading) return <Loading />;

  return (
    <ThemeProvider theme={registerTheme}>
      <div style={styles.main}>
        <Card style={styles.card}>
          <div style={styles.avatar}>
            <Avatar backgroundcolor={accent1Color} icon={<Lock />} size={60} />
          </div>
          <form name="changePasswordForm">
            <div style={styles.form}>
              <p style={styles.hint}>
                {" "}
                {result !== "success" ? titleTextVerify : titleTextChange}
              </p>
              {result !== "success" ? (
                <p style={styles.hint}>{VerificationRes}</p>
              ) : null}
              {result === "success" ? (
                <div>
                  <div style={styles.input}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      name="password"
                      label="new password"
                      type="password"
                      helperText="Password must be more than 6 chars long and must include special characters and capital letters"
                      required
                      error={passwordError}
                      defaultValue={password}
                      onChange={(e) => handlePasswordChange(e.target.value)}
                    />
                  </div>
                  <div style={styles.input}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      name="confirmpassword"
                      label="confirm password"
                      type="password"
                      helperText="Password must be valid and match with the above field"
                      required
                      error={cpasswordError}
                      defaultValue={cpassword}
                      onChange={(e) => handleCpasswordChange(e.target.value)}
                    />
                  </div>
                  <div style={styles.input}>
                    <Button
                      color="primary"
                      variant="contained"
                      size="large"
                      // disabled={submitting}
                      fullWidth
                      onClick={submit}
                    >
                      Change password
                    </Button>
                  </div>
                </div>
              ) : null}
              <CardActions>
                <div style={styles.link}>
                  <Link
                    onClick={() => {
                      history.push("/forgot-password");
                    }}
                  >
                    Reset password
                  </Link>
                </div>
              </CardActions>
            </div>
          </form>
        </Card>
        <Notification />
      </div>
    </ThemeProvider>
  );
};

export default ChangePasswordToken;
