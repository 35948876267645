import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  BooleanInput,
  NumberInput,
  ReferenceInput,
  required,
  SelectInput,
} from "react-admin";
import HorizontalInputStyle from "./HorizontalInputStyle";

const deviceStatus = [
  { id: "uninstalled", name: "uninstalled" },
  { id: "setup", name: "setup" },
  { id: "testing", name: "testing" },
  { id: "active", name: "active" },
  { id: "maintanance", name: "maintanance" },
  { id: "offline", name: "offline" },
  { id: "faulty", name: "faulty" },
];

const userOptionRenderer = (choice) => `${choice.name} - ${choice.phone}`;

const DevicesEdit = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput disabled source="id" />
        <TextInput required source="identifier" style={HorizontalInputStyle} />
        <TextInput required source="model" style={HorizontalInputStyle} />
        <TextInput required source="SIMNumber" />
        <TextInput required source="trackerType" />
        <ReferenceInput
          label="Master"
          source="masterRef"
          reference="users"
          validate={[required()]}
          filter={{ role: "agent" }}
          style={HorizontalInputStyle}
        >
          <SelectInput optionText={userOptionRenderer} />
        </ReferenceInput>
        <ReferenceInput
          label="Sales"
          source="salesRef"
          reference="users"
          filter={{ role: "sales" }}
        >
          <SelectInput optionText={userOptionRenderer} />
        </ReferenceInput>
        <TextInput required source="serverURL" style={HorizontalInputStyle} />
        <TextInput
          required
          source="devicePassword"
          style={HorizontalInputStyle}
        />
        <SelectInput
          required
          source="deviceStatus"
          label="Status"
          choices={deviceStatus}
        />
        {/* <TextInput required source="deviceStatus" /> */}
        <TextInput required source="relayOnCode" style={HorizontalInputStyle} />
        <TextInput required source="relayOffCode" />
        <TextInput required source="note" fullWidth={true} />
        <NumberInput required source="SIMBalance" />
        <BooleanInput required source="relayStatus" label="Relay" />
      </SimpleForm>
    </Edit>
  );
};

export default DevicesEdit;
