import React from "react";
import {
  Datagrid,
  TabbedShowLayout,
  Tab,
  Show,
  TextField,
  DateField,
  ReferenceField,
  BooleanField,
  FunctionField,
  ReferenceManyField,
} from "react-admin";
import { ColorField } from "../components/ColorComponent";
import HorizontalInputStyle from "./HorizontalInputStyle";
import { MapField } from "../components/MapField";
import {
  formattedLastTime,
  formattedDate,
  formattedTime,
  formatToDecimal,
} from "./formatter";

const VehiclesShow = (props) => {
  return (
    <Show {...props}>
      <TabbedShowLayout>
        <Tab label="Location">
          <FunctionField
            label="last signal"
            render={(record) => `${formattedLastTime(record.lastLog)}`}
            reference="devices"
            style={HorizontalInputStyle}
          />
          <MapField source="lastLocation" />
        </Tab>

        <Tab label="Details">
          <TextField source="brand" />
          <TextField source="model" />
          <TextField source="type" />
          <TextField source="tag" />
          <ReferenceField label="Cohort" source="cohortRef" reference="cohorts">
            <TextField source="cohortName" />
          </ReferenceField>
          <TextField required source="note" fullWidth={true} />
          <TextField source="color" />
          <ColorField source="colorHex" />
          <TextField source="plate" />
          <TextField source="status" />
          {/* vehicle controls */}
          <TextField source="engine" />
        </Tab>
        <Tab label="Account">
          {/* for extended history */}
          <TextField source="serviceStatus" label="Service status" />
          <TextField source="deferTrimDate" />
          <TextField source="registerDate" />
          <TextField source="subscriptionExpiryDate" />
          <TextField source="subscriptionPrice" />
          <TextField source="subscriptionTier" />
        </Tab>

        <Tab label="Owner">
          <BooleanField source="isOwnerSpeedLimited" />
          {/* owner booleans */}
          <BooleanField source="isOwnerFenced" />
          <BooleanField source="isOwnerParked" />
          <BooleanField source="isOwnerServiceActive" />

          <TextField source="ownerFence" />
          <TextField source="ownerSpeed" />
          <TextField source="ownerSpeedLimitValue" />
          <TextField source="ownerParkedLocation" />
          {/* owner notification type */}
          <TextField source="ownerTetherNotificationType" />
          <TextField source="ownerFenceNotificationType" />
          <TextField source="ownerSpeedNotificationType" />
          <TextField source="ownerParkNotificationType" />
        </Tab>
        <Tab label="Owner permissions">
          {/* owner notif section */}
          <BooleanField source="canOwnerGetDisconNotif" />
          <BooleanField source="canOwnerGetSpeedLimitNotif" />
          <BooleanField source="canOwnerGetFenceNotif" />
          <BooleanField source="canOwnerGetParkNotif" />
          {/* owner perm section */}
          <BooleanField source="canOwnerSpeedLimit" />
          <BooleanField source="canOwnerFence" />
          <BooleanField source="canOwnerPark" />
          <BooleanField source="canOwnerKill" />
          <BooleanField source="canOwnerTrack" />
          {/* owner values */}
        </Tab>

        <Tab label="Driver">
          {/* driver booleans */}
          <BooleanField source="isDriverSpeedLimited" />
          <BooleanField source="isDriverFenced" />
          <BooleanField source="isDriverParked" />
          <BooleanField source="isDriverServiceActive" />
          {/* driver values */}
          <TextField source="driverFence" />
          <TextField source="driverStatus" />
          <TextField source="driverVerification" />
          <TextField source="driverSpeedLimitValue" />
          <TextField source="driverParkedLocation" />
          {/* driver notification types */}
          <TextField source="driverTetherNotificationType" />
          <TextField source="driverFenceNotificationType" />
          <TextField source="driverSpeedNotificationType" />
          <TextField source="driverParkNotificationType" />
        </Tab>
        <Tab label="Driver permissions">
          {/* driver notif section */}
          <BooleanField source="canDriverGetDisconNotif" />
          <BooleanField source="canDriverGetSpeedLimitNotif" />
          <BooleanField source="canDriverGetFenceNotif" />
          <BooleanField source="canDriverGetParkNotif" />
          {/* driver perm section */}
          <BooleanField source="canDriverSpeedLimit" />
          <BooleanField source="canDriverFence" />
          <BooleanField source="canDriverPark" />
          <BooleanField source="canDriverKill" />
          <BooleanField source="canDriverTrack" />
        </Tab>

        <Tab label="Report">
          <ReferenceManyField reference="report" target="vehicleRef">
            {/* style={{ tableLayout: "fixed" }} */}

            <Datagrid style={{ tableLayout: "fixed" }}>
              <FunctionField
                label="Day"
                render={(record) =>
                  `${formattedDate(record.date, "ddd, Do MMM")}`
                }
              />
              <DateField source="date" label="Date" />

              <FunctionField
                label="Mileage"
                render={(record) =>
                  record.mileageInMeters > 0
                    ? `${record.mileageInMeters / 1000}kms`
                    : "0.00km"
                }
              />
              <FunctionField
                label="Driven"
                render={(record) =>
                  `${formattedTime(record.movingInMinutes * 60 * 1000, false)}`
                }
              />
              <FunctionField
                label="Idling"
                render={(record) =>
                  `${formattedTime(record.idlingInMinutes * 60 * 1000, false)}`
                }
              />
              <FunctionField
                label="Parked"
                render={(record) =>
                  `${formattedTime(record.parkedInMinutes * 60 * 1000, false)}`
                }
              />
              {/* <TextField source="movingInMins" />
              <TextField source="idlingInMins" />
              <TextField source="parkedInMins" /> */}
              <TextField source="fence_violation" />
              <TextField source="speed_violation" />
              {/* <TextField source="avg_speed" /> */}
              <FunctionField
                label="average speed"
                render={(record) =>
                  `${formatToDecimal(record.avg_speed, 2)}kms/h`
                }
              />
              <TextField source="entries" label="Log entries" />
              {/* <TextField source="speed" />
              <TextField source="isOutsideFence" />
              <TextField source="deviceStatus" />
              <TextField source="movementStatus" />
              <TextField source="expireAt" /> */}
            </Datagrid>
          </ReferenceManyField>
        </Tab>

        <Tab label="Timestamps">
          {/* last owner notification_timestamp */}
          <FunctionField
            label="Last Owner Disconnected Notification"
            render={(record) =>
              `${formattedLastTime(record.lastOwnerDisconnectedNotif, false)}`
            }
            style={HorizontalInputStyle}
          />

          <FunctionField
            label="Last Owner Speed Notification"
            render={(record) =>
              `${formattedLastTime(record.lastOwnerSpeedLimitNotif, false)}`
            }
            style={HorizontalInputStyle}
          />

          <FunctionField
            label="Last Owner Fence Notification"
            render={(record) =>
              `${formattedLastTime(record.lastOwnerFenceNotif, false)}`
            }
            style={HorizontalInputStyle}
          />

          <FunctionField
            label="Last Owner Park Notification"
            render={(record) =>
              `${formattedLastTime(record.lastOwnerParkNotif, false)}`
            }
            style={HorizontalInputStyle}
          />

          {/* last driver notification_timestamp */}
          <FunctionField
            label="Last Driver Disconnected Notification"
            render={(record) =>
              `${formattedLastTime(record.lastDriverDisconnectedNotif, false)}`
            }
            style={HorizontalInputStyle}
          />

          <FunctionField
            label="Last Driver Speed Notification"
            render={(record) =>
              `${formattedLastTime(record.lastDriverSpeedLimitNotif, false)}`
            }
            style={HorizontalInputStyle}
          />

          <FunctionField
            label="Last Driver Fence Notification"
            render={(record) =>
              `${formattedLastTime(record.lastDriverFenceNotif, false)}`
            }
            style={HorizontalInputStyle}
          />

          <FunctionField
            label="Last Driver Park Notification"
            render={(record) =>
              `${formattedLastTime(record.lastDriverParkNotif, false)}`
            }
            style={HorizontalInputStyle}
          />
        </Tab>
        {/* <EditButton label="Edit" basePath="/vehicles" />
        <DeleteButton label="Delete" basePath="/vehicles" /> */}
      </TabbedShowLayout>
    </Show>
  );
};

export default VehiclesShow;
