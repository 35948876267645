import React from "react";
import {
  List,
  Datagrid,
  TextField,
  ChipField,
  EditButton,
  NumberField,
  DateField,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import classnames from "classnames";

const useStyles = makeStyles({
  lead: { color: "blue" },
  follower: { color: "grey" },
  customer: { color: "green" },
});

const ColoredChipField = (props) => {
  const classes = useStyles();
  return (
    <ChipField
      className={classnames({
        [classes.lead]: props.record[props.source] === "lead",
        [classes.follower]: props.record[props.source] === "follower",
        [classes.customer]: props.record[props.source] === "customer",
      })}
      {...props}
    />
  );
};
// Ensure the original component defaultProps are still applied as they may be used by its parents (such as the `Show` component):
ColoredChipField.defaultProps = ChipField.defaultProps;

const ContentList = (props) => (
  <List {...props}>
    <Datagrid rowClick="show">
      <TextField source="title" />
      <TextField source="message" />
      <TextField source="userType" label="recipients" />
      <TextField source="method" />
      <NumberField source="smsHits" />
      <NumberField source="telegramHits" />
      <DateField source="lastSent" />
      <TextField source="messageStatus" />
      <EditButton label="Edit" basePath="/content" />
    </Datagrid>
  </List>
);

export default ContentList;
