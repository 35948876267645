import React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  FunctionField,
  ChipField,
  TextInput,
  Filter,
  FilterButton,
  CreateButton,
  ExportButton,
  TopToolbar,
  SearchInput,
  useTranslate,
  ReferenceInput,
  AutocompleteInput,
} from "react-admin";
import { Chip } from "@material-ui/core";
import { formattedLastTime } from "./formatter";

const ListActions = () => (
  <TopToolbar>
    <FilterButton />
    <CreateButton />
    <ExportButton />
    {/* Add your custom actions */}
    {/* <Button
      onClick={() => {
        alert("Your custom action");
      }}
      label="Show calendar"
    >
      <IconEvent />
    </Button> */}
  </TopToolbar>
);

const QuickFilter = ({ label }) => {
  const translate = useTranslate();
  return <Chip sx={{ marginBottom: 1 }} label={translate(label)} />;
};

// const SearchFilter = (props) => (
//   <Filter
//     {...props}
//     filterToQuery={(searchText) => ({
//       plate: { $regex: searchText, $options: "i" },
//     })}
//   >
//     <TextInput label="Search" source="plate" alwaysOn />
//   </Filter>
// );

const vehicleFilters = [
  <SearchInput source="q" alwaysOn />,
  <QuickFilter source="Service" label="Active" defaultValue={"active"} />,
  <QuickFilter source="Service" label="Expired" defaultValue={"expired"} />,
  <TextInput label="Plate" source="plate" />,
];

const VehiclesList = (props) => {
  return (
    <List {...props} filters={vehicleFilters}>
      {/* actions={<ListActions />} */}
      {/* change to DatagridConfigurable */}
      <Datagrid rowClick="show">
        <TextField source="plate" />
        {/* <TextField source="id" /> */}
        <TextField source="brand" />
        <TextField source="model" />
        {/* <TextField source="type" /> */}
        {/* <TextField source="tag" /> */}
        {/* <TextField source="color" /> */}
        {/* <TextField source="colorHex" /> */}
        <ChipField source="serviceStatus" label="Service" />
        {/* <TextField source="status" label="State" /> */}
        {/* owner ref */}
        {/* <TextField source="ownerRef" /> */}
        <ReferenceField
          label="Owner"
          source="ownerRef"
          reference="customers"
          link="show"
        >
          <TextField source="name" />
        </ReferenceField>
        {/* driver ref */}
        {/* <TextField source="driverRef" /> */}
        {/* <ReferenceField label="Driver" source="driverRef" reference="customers">
          <TextField source="name" />
        </ReferenceField> */}

        {/* device ref */}
        {/* <TextField source="deviceRef" /> */}
        <ReferenceField
          label="Tracker"
          source="deviceRef"
          reference="devices"
          link="show"
        >
          {/* <TextField source="SIMNumber" /> */}
          <FunctionField
            label="Tracker"
            render={(record) =>
              `imei ${record.identifier} - ${record.SIMNumber}`
            }
          />
        </ReferenceField>

        {/* notifier ref */}
        <ReferenceField
          label="Notifier"
          source="offlineNotifierRef"
          reference="users"
          link="show"
        >
          <TextField source="name" />
        </ReferenceField>

        {/* owner booleans */}
        {/* <TextField source="isOwnerSpeedLimited" /> */}
        {/* <TextField source="isOwnerFenced" /> */}
        {/* <TextField source="isOwnerParked" /> */}
        {/* <TextField source="isOwnerServiceActive" /> */}
        {/* driver booleans */}
        {/* <TextField source="isDriverSpeedLimited" /> */}
        {/* <TextField source="isDriverFenced" /> */}
        {/* <TextField source="isDriverParked" /> */}
        {/* <TextField source="isDriverServiceActive" /> */}
        {/* owner notif section */}
        {/* <TextField source="canOwnerGetDisconNotif" /> */}
        {/* <TextField source="canOwnerGetSpeedLimitNotif" /> */}
        {/* <TextField source="canOwnerGetFenceNotif" /> */}
        {/* <TextField source="canOwnerGetParkNotif" /> */}
        {/* driver notif section */}
        {/* <TextField source="canDriverGetDisconNotif" /> */}
        {/* <TextField source="canDriverGetSpeedLimitNotif" /> */}
        {/* <TextField source="canDriverGetFenceNotif" /> */}
        {/* <TextField source="canDriverGetParkNotif" /> */}
        {/* owner perm section */}
        {/* <TextField source="canOwnerSpeedLimit" /> */}
        {/* <TextField source="canOwnerFence" /> */}
        {/* <TextField source="canOwnerPark" /> */}
        {/* <TextField source="canOwnerKill" /> */}
        {/* <TextField source="canOwnerTrack" /> */}
        {/* driver perm section */}
        {/* <TextField source="canDriverSpeedLimit" /> */}
        {/* <TextField source="canDriverFence" /> */}
        {/* <TextField source="canDriverPark" /> */}
        {/* <TextField source="canDriverKill" /> */}
        {/* <TextField source="canDriverTrack" /> */}
        {/* owner values */}
        {/* <TextField source="ownerFence" /> */}
        {/* <TextField source="ownerSpeed" /> */}
        {/* <TextField source="ownerSpeedLimitValue" /> */}
        {/* <TextField source="ownerParkedLocation" /> */}
        {/* owner notification type */}
        {/* <TextField source="ownerTetherNotificationType" /> */}
        {/* <TextField source="ownerFenceNotificationType" /> */}
        {/* <TextField source="ownerSpeedNotificationType" /> */}
        {/* <TextField source="ownerParkNotificationType" /> */}
        {/* driver values */}
        {/* <TextField source="driverFence" /> */}
        {/* <TextField source="driverStatus" /> */}
        {/* <TextField source="driverVerification" /> */}
        {/* <TextField source="driverSpeedLimitValue" /> */}
        {/* <TextField source="driverParkedLocation" /> */}
        {/* driver notification types */}
        {/* <TextField source="driverTetherNotificationType" /> */}
        {/* <TextField source="driverFenceNotificationType" /> */}
        {/* <TextField source="driverSpeedNotificationType" /> */}
        {/* <TextField source="driverParkNotificationType" /> */}
        {/* vehicle controls */}
        {/* <TextField source="engine" /> */}
        {/* last owner notification_timestamp */}
        {/* <TextField source="lastOwnerDisconnectedNotif" /> */}
        {/* <TextField source="lastOwnerSpeedLimitNotif" /> */}
        {/* <TextField source="lastOwnerFenceNotif" /> */}
        {/* <TextField source="lastOwnerParkNotif" /> */}
        {/* last driver notification_timestamp */}
        {/* <TextField source="lastDriverDisconnectedNotif" /> */}
        {/* <TextField source="lastDriverSpeedLimitNotif" /> */}
        {/* <TextField source="lastDriverFenceNotif" /> */}
        {/* <TextField source="lastDriverParkNotif" /> */}
        {/* last fields */}
        {/* <TextField source="lastLocation" /> */}
        {/* <DateField source="lastLog" showTime="true" /> */}
        <FunctionField
          label="last signal"
          render={(record) => `${formattedLastTime(record.lastLog)}`}
        />
        {/* for extended history */}
        {/* <TextField source="deferTrimDate" /> */}
        {/* <TextField source="registerDate" /> */}
        <DateField source="subscriptionExpiryDate" label="Expires on" />
        {/* <TextField source="subscriptionPrice" /> */}

        {/* <ShowButton label="Show" basePath="/vehicles" /> */}
        {/* <EditButton label="Edit" basePath="/vehicles" /> */}
        {/* <DeleteButton label="Delete" basePath="/vehicles" /> */}
      </Datagrid>
    </List>
  );
};

export default VehiclesList;
