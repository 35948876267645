import React from "react";
import {
  Edit,
  TextInput,
  SelectInput,
  DateInput,
  TabbedForm,
  FormTab,
  ReferenceInput,
  BooleanInput,
  AutocompleteInput,
  ArrayInput,
  SimpleFormIterator,
  NumberInput,
} from "react-admin";
import HorizontalInputStyle from "./HorizontalInputStyle";
import { userFilter, userOptionRenderer } from "./userFilter";

const invoiceStatus = [
  { id: "active", name: "active" },
  { id: "sent", name: "sent" },
  { id: "overdue", name: "overdue" },
  { id: "confirm", name: "confirm" },
  { id: "paid", name: "paid" },
  { id: "closed", name: "closed" },
];

const vehicleOptionRenderer = (vehicle) =>
  vehicle ? `plate ${vehicle.plate}` : "";
// const defaultNotification = notification.map((notification) => notification.id);
const InvoiceEdit = (props) => {
  return (
    <Edit {...props}>
      <TabbedForm>
        <FormTab label="Details">
          <TextInput source="invoiceNumber" />
          {/* <TextInput source="vehicleRef" /> */}
          <ReferenceInput
            label="Vehicle"
            source="vehicleRef"
            reference="vehicles"
            filterToQuery={(searchText) => ({
              identifier: { $regex: searchText, $options: "i" },
            })}
          >
            <AutocompleteInput optionText={vehicleOptionRenderer} />
          </ReferenceInput>

          <ReferenceInput
            label="Customer"
            source="userRef"
            reference="customers"
            allowEmpty
            style={HorizontalInputStyle}
            filterToQuery={(searchText) => ({
              identifier: { $regex: searchText, $options: "i" },
            })}
          >
            <AutocompleteInput optionText={userOptionRenderer} />
          </ReferenceInput>

          <SelectInput
            source="status"
            style={HorizontalInputStyle}
            choices={invoiceStatus}
            required
          />
        </FormTab>
        <FormTab label="Bank Info">
          <TextInput source="PayToName" />
          <TextInput source="PayToBankName" />
          <TextInput source="PayToAccountName" />
          <TextInput source="PayToAccountNumber" />
        </FormTab>
        <FormTab label="Items">
          <ArrayInput source="items">
            <SimpleFormIterator inline>
              <TextInput
                source="description"
                label="Description"
                helperText={false}
                style={HorizontalInputStyle}
              />
              <NumberInput source="amount" label="Amount" helperText={false} />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
        <FormTab label="Dates">
          <DateInput source="issuedDate" />
          <DateInput source="nextExpiryDate" />
          <DateInput source="dueDate" />
          <DateInput source="issuedNoticeDate" />
          <DateInput source="reminderNoticeDate" />
          <DateInput source="dueNoticeDate" />
          <DateInput source="overdueNoticeDate" />
          <DateInput source="warningNoticeDate" />
          <DateInput source="suspensionNoticeDate" />
        </FormTab>
        <FormTab label="Notified">
          <BooleanInput source="issuedNotified" />
          <BooleanInput source="reminderNotified" />
          <BooleanInput source="dueNotified" />
          <BooleanInput source="overdueNotified" />
          <BooleanInput source="warningNotified" />
          <BooleanInput source="suspensionNotified" />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export default InvoiceEdit;
