import React from "react";
import {
  Create,
  FormTab,
  TabbedForm,
  TextInput,
  BooleanInput,
  SelectInput,
  DateInput,
} from "react-admin";
import { acquisitionChannels } from "../utils/constants";

const userStatus = [
  { id: "active", name: "active" },
  { id: "expired", name: "expired" },
  { id: "suspended", name: "suspended" },
  { id: "fresh", name: "fresh" },
];

const userRole = [
  { id: "owner", name: "owner" },
  { id: "admin", name: "admin" },
  { id: "manager", name: "manager" },
  { id: "customer", name: "customer" },
  { id: "agent", name: "agent" },
  { id: "prospect", name: "prospect" },
  { id: "lead", name: "lead" },
  { id: "follower", name: "follower" },
  { id: "sales", name: "sales" },
];
// const acquisitionChannels = [
//   { id: "fca", name: "FCA" },
//   { id: "tko", name: "TKO" },
//   { id: "gga", name: "GGA" },
//   { id: "wom", name: "WOM" },
// ];

const UsersCreate = (props) => {
  return (
    <Create {...props}>
      <TabbedForm>
        <FormTab label="Account">
          {/* <TextInput disabled source="id" /> */}
          <TextInput source="name" required />
          <TextInput source="phone" required />
          <TextInput source="password" required />
          <TextInput source="passphrase" required />
        </FormTab>
        <FormTab label="Details">
          <SelectInput
            source="targetChannel"
            label="Channel"
            choices={acquisitionChannels}
          />
          <DateInput source="targetDate" label="Reach" />
          <SelectInput
            required
            source="status"
            label="Status"
            choices={userStatus}
          />
          <SelectInput required source="role" label="Role" choices={userRole} />
        </FormTab>
        <FormTab label="Security">
          <BooleanInput source="isPasswordExpired" />
          <BooleanInput source="isEmailVerified" />
          <BooleanInput source="isPhoneVerified" />
        </FormTab>
      </TabbedForm>
    </Create>
  );
};

export default UsersCreate;
