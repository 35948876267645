import React from "react";
import { Edit, SimpleForm, TextInput, SelectInput } from "react-admin";
import HorizontalInputStyle from "./HorizontalInputStyle";

// const userStatus = [
//   { id: "active", name: "active" },
//   { id: "expired", name: "expired" },
//   { id: "suspended", name: "suspended" },
//   { id: "fresh", name: "fresh" },
// ];

// const notification = [
//   { id: "CALL", name: "CALL" },
//   { id: "SMS", name: "SMS" },
//   { id: "PUSH", name: "PUSH" },
// ];

const areaStatus = [
  { id: "passive", name: "passive" },
  { id: "active", name: "active" },
];

// const defaultNotification = notification.map((notification) => notification.id);
const CohortEdit = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source="cohortName" />
        <TextInput source="cohortDescription" />

        <SelectInput
          source="status"
          style={HorizontalInputStyle}
          choices={areaStatus}
          required
        />
        <TextInput source="note" />
      </SimpleForm>
    </Edit>
  );
};

export default CohortEdit;
