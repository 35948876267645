import React, { useState } from "react";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import Lock from "@material-ui/icons/Lock";
import {
  Notification,
  useNotify,
  defaultTheme,
  useAuthProvider,
  Loading,
  useRedirect,
} from "react-admin";
import { Avatar, TextField, Button, Card } from "@material-ui/core";
import {
  getValidEmail,
  getValidPhone,
  isValidPhone,
  isValidEmail,
  isValidPassword,
} from "../utils/validator";
import { useHistory } from "react-router";
import { getColorsFromTheme, styles } from "../utils/authFormStyle";
import { registerUser } from "../utils/useUser";

const Register = ({ theme }) => {
  let history = useHistory();
  const redirect = useRedirect();
  const notify = useNotify();
  const [loading, setLoading] = useState(false);

  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [nameError, setNameError] = useState(false);
  const [usernameError, setUsernameError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const registerTheme = createMuiTheme(defaultTheme);
  const { primary1Color, accent1Color } = getColorsFromTheme(registerTheme);

  const handleNameChange = (value) => {
    setName(value);
    setNameError((er) => !value);
  };

  const handleUsernameChange = (value) => {
    setUsername(value);
    setPhone(getValidPhone(value));
    setEmail(getValidEmail(value));
    setUsernameError((er) => !isValidPhone(value) && !isValidEmail(value));
  };

  const handlePasswordChange = (value) => {
    setPassword(value);
    setPasswordError((er) => !isValidPassword(value));
  };

  const submit = (e) => {
    e.preventDefault();
    // const handleClick = () => {
    setLoading(true);

    registerUser({ name, phone, email, password })
      .then(() => {
        notify("Registration successful");
        redirect("/login");
      })
      .catch((e) => {
        notify("Registration failed", { type: "warning" });
      })
      .finally(() => {
        setLoading(false);
      });
    // };
    // setPhone(getValidPhone(username));
    // setEmail(getValidEmail(username));
    // // will call authProvider.login({ email, password })
    // mutate()
    //   .then(() => {
    //     this.props.history.push("/login");
    //   })
    //   .catch((error) => notify(error));
  };

  const authProvider = useAuthProvider();
  // const { mutate, isLoading } = useMutation(
  //   ["register", { name, phone, email, password }],
  //   () => authProvider.register({ name, phone, email, password })
  // );

  if (loading) return <Loading />;

  return (
    <ThemeProvider theme={registerTheme}>
      <div style={styles.main}>
        <Card style={styles.card}>
          <div style={styles.avatar}>
            <Avatar backgroundcolor={accent1Color} icon={<Lock />} size={60} />
          </div>
          <form name="loginForm">
            <div style={styles.form}>
              <p style={styles.hint}>Register for Tether</p>
              <div style={styles.input}>
                <TextField
                  variant="outlined"
                  fullWidth
                  name="name"
                  label="name"
                  helperText="Your full name"
                  required
                  error={nameError}
                  defaultValue={name}
                  onChange={(e) => handleNameChange(e.target.value)}
                />
              </div>
              <div style={styles.input}>
                <TextField
                  variant="outlined"
                  fullWidth
                  name="username"
                  label="username"
                  helperText="Enter your email or phone number in valid format eg. 911223344 or name@email.com"
                  required
                  error={usernameError}
                  defaultValue={username}
                  onChange={(e) => handleUsernameChange(e.target.value)}
                />
              </div>
              <div style={styles.input}>
                <TextField
                  variant="outlined"
                  fullWidth
                  name="password"
                  label="password"
                  type="password"
                  helperText="Password must be more than 6 chars long and must include special characters and capital letters"
                  required
                  error={passwordError}
                  defaultValue={password}
                  onChange={(e) => handlePasswordChange(e.target.value)}
                />
              </div>
              <div style={styles.input}>
                <Button
                  color="primary"
                  variant="contained"
                  size="large"
                  // disabled={submitting}
                  fullWidth
                  onClick={submit}
                >
                  Register
                </Button>
              </div>
              <div style={styles.input}>
                <Button
                  variant="outlined"
                  size="large"
                  fullWidth
                  onClick={() => {
                    history.push("/login");
                  }}
                >
                  Login
                </Button>
              </div>
            </div>
          </form>
        </Card>
        <Notification />
      </div>
    </ThemeProvider>
  );
};

export default Register;
