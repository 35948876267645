// in src/Dashboard.js
import React, { useState, useEffect } from "react";
import { ListContextProvider, Loading, useQuery } from "react-admin";
import keyBy from "lodash/keyBy";
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  Legend,
  AreaChart,
  XAxis,
  YAxis,
  Area,
  Tooltip,
  CartesianGrid,
  PieChart,
  Sector,
  Pie,
  Cell,
} from "recharts";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";

import {
  MapContainer,
  TileLayer,
  CircleMarker,
  useMapEvents,
} from "react-leaflet";
import { LatLng } from "leaflet";
// import L from "leaflet";
import "leaflet-fullscreen/dist/leaflet.fullscreen.css";
import "leaflet/dist/leaflet.css";
import "leaflet-fullscreen/dist/Leaflet.fullscreen.js";
import "leaflet.tilelayer.colorfilter/src/leaflet-tilelayer-colorfilter.js";
import SummaryText from "./SummaryText";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { Button, Box, Typography } from "@material-ui/core";
import { getDashBoardData, stringToColor } from "../utils/fleetUtil";
import { formattedDate } from "./formatter";
// import HeatmapLayer from "react-leaflet-heatmap-layer";

import { loadUser } from "../utils/useUser";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

// const ROLE = {
//   OWNER: "owner",
//   ADMIN: "admin",
//   MANAGER: "manager",
//   AGENT: "agent",
//   CUSTOMER: "customer",
//   PROSPECT: "prospect",
// };

// function isTeam(permissions) {
//   return (
//     permissions === ROLE.OWNER ||
//     permissions === ROLE.ADMIN ||
//     permissions === ROLE.MANAGER
//   );
// }

// function isCutomer(permissions) {
//   return permissions === ROLE.CUSTOMER || permissions === ROLE.PROSPECT;
// }

const data = [];
const d = new Date();
for (let num = 0; num < 30; num++) {
  const day = d;
  day.setDate(d.getDate() + num);
  data.push({
    date: day.toISOString(),
    value: 1 + Math.random(),
  });
}

// const barData = [
//   {
//     name: "Page A",
//     uv: 4000,
//     pv: 2400,
//     amt: 2400,
//   },
//   {
//     name: "Page B",
//     uv: 3000,
//     pv: 1398,
//     amt: 2210,
//   },
//   {
//     name: "Page C",
//     uv: 2000,
//     pv: 9800,
//     amt: 2290,
//   },
//   {
//     name: "Page D",
//     uv: 2780,
//     pv: 3908,
//     amt: 2000,
//   },
//   {
//     name: "Page E",
//     uv: 1890,
//     pv: 4800,
//     amt: 2181,
//   },
//   {
//     name: "Page F",
//     uv: 2390,
//     pv: 3800,
//     amt: 2500,
//   },
//   {
//     name: "Page G",
//     uv: 3490,
//     pv: 4300,
//     amt: 2100,
//   },
// ];

// const quarterData = [
//   {
//     date: "2000-01",
//     uv: 4000,
//     pv: 2400,
//     amt: 2400,
//   },
//   {
//     date: "2000-02",
//     uv: 3000,
//     pv: 1398,
//     amt: 2210,
//   },
//   {
//     date: "2000-03",
//     uv: 2000,
//     pv: 9800,
//     amt: 2290,
//   },
//   {
//     date: "2000-04",
//     uv: 2780,
//     pv: 3908,
//     amt: 2000,
//   },
//   {
//     date: "2000-05",
//     uv: 1890,
//     pv: 4800,
//     amt: 2181,
//   },
//   {
//     date: "2000-06",
//     uv: 2390,
//     pv: 3800,
//     amt: 2500,
//   },
//   {
//     date: "2000-07",
//     uv: 3490,
//     pv: 4300,
//     amt: 2100,
//   },
//   {
//     date: "2000-08",
//     uv: 4000,
//     pv: 2400,
//     amt: 2400,
//   },
//   {
//     date: "2000-09",
//     uv: 3000,
//     pv: 1398,
//     amt: 2210,
//   },
//   {
//     date: "2000-10",
//     uv: 2000,
//     pv: 9800,
//     amt: 2290,
//   },
//   {
//     date: "2000-11",
//     uv: 2780,
//     pv: 3908,
//     amt: 2000,
//   },
//   {
//     date: "2000-12",
//     uv: 1890,
//     pv: 4800,
//     amt: 2181,
//   },
// ];

// const monthTickFormatter = (tick) => {
//   const date = new Date(tick);

//   return date.getMonth() + 1;
// };

// const renderQuarterTick = (tickProps) => {
//   const { x, y, payload } = tickProps;
//   const { value, offset } = payload;
//   const date = new Date(value);
//   const month = date.getMonth();
//   const quarterNo = Math.floor(month / 3) + 1;
//   const isMidMonth = month % 3 === 1;

//   if (month % 3 === 1) {
//     return <text x={x} y={y - 4} textAnchor="middle">{`Q${quarterNo}`}</text>;
//   }

//   const isLast = month === 11;

//   if (month % 3 === 0 || isLast) {
//     const pathX = Math.floor(isLast ? x + offset : x - offset) + 0.5;

//     return <path d={`M${pathX},${y - 4}v${-35}`} stroke="red" />;
//   }
//   return null;
// };

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];
// const pieData = [
//   { name: "Group A", value: 400 },
//   { name: "Group B", value: 300 },
//   { name: "Group C", value: 300 },
//   { name: "Group D", value: 200 },
// ];

const renderSpeedActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill="#333"
      >{`${value} speed violations`}</text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={18}
        textAnchor={textAnchor}
        fill="#999"
      >
        {`(Rate ${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  );
};

const renderFenceActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill="#333"
      >{`${value} fence violations`}</text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={18}
        textAnchor={textAnchor}
        fill="#999"
      >
        {`(Rate ${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  );
};

// const CustomTooltip = ({ active, payload, label }) => {
//   if (active && payload && payload.length) {
//     return (
//       <div className="custom-tooltip">
//         <p className="label">{`${label} : ${payload[0].value}`}</p>
//         <p className="intro">{label}</p>
//         <p className="desc">Anything you want can be displayed here.</p>
//       </div>
//     );
//   }

//   return null;
// };

function HeatMap({ data }) {
  const [markers, setMarkers] = useState([]);
  const [allMarkers, setAllMarkers] = useState([]);

  useEffect(() => {
    const times = data.repeat(1000);

    setAllMarkers(data);

    // effect cleanup function
    return () => {
      setAllMarkers([]);
    };
  }, []); // <-- empty dependen

  const map = useMapEvents({
    moveend() {
      const visibleCrumbs = allMarkers.filter((m) =>
        map.getBounds().contains(new LatLng(m[1], m[2]))
      );

      const prime = Math.ceil(visibleCrumbs.length / 1000);
      const selectedCrumbs = visibleCrumbs.filter((v, i) => i % prime === 0);

      setMarkers(
        selectedCrumbs.map((v, i) => (
          <CircleMarker key={v[0]} center={new LatLng(v[1], v[2])} radius={8} />
          // Circle
        ))
      );
      console.log("prime: ", prime);
      console.log("allMarkers: ", allMarkers.length);
      console.log("breadcrumbs: ", selectedCrumbs.length);
    },
  });

  return markers;
}

Array.prototype.repeat = function (n) {
  var a = [];
  for (var i = 0; i < n; [i++].push.apply(a, this));
  return a;
};

const MapField = ({ data }) => {
  return (
    <MapContainer
      preferCanvas={true}
      center={[8, 38]}
      zoom={6}
      boundsOptions={{ padding: [50, 50] }}
      scrollWheelZoom={true}
      fullscreenControl={true}
      style={{ height: 400 }}
    >
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />

      <HeatMap effectOn={this} data={data} />
    </MapContainer>
  );
};

// const getCoordinates = (coordinates) => {
//   if (!coordinates) return [0, 0];
//   const array = coordinates.split(",");
//   return [array[0] ? array[0] : 0, array[1] ? array[1] : 0];
// };

const UserDashboard = (props) => {
  // const [visible, setVisible] = useState(false);
  // const [vehicles, setVehicles] = useState([]);
  // const [mapStyle, setMapStyle] = useState(0);

  const [page, setPage] = useState(1);
  const perPage = 50;
  const { data, total, loading, error } = useQuery({
    type: "getList",
    resource: "fleet", //
    payload: {
      pagination: { page, perPage },
      sort: { field: "id", order: "ASC" },
      filter: {},
    },
  });

  useEffect(() => {
    loadUser();

    // if (data && !loading && !error) {
    // setVehicles(data);
    // }
    // effect cleanup function
    return () => {};
  }, []); // <-- empty dependen

  //#region dash
  const classes = useStyles();
  const [activeSpeedIndex, setSpeedActiveIndex] = useState(0);
  const [activeFenceIndex, setFenceActiveIndex] = useState(0);

  const onFencePieEnter = (_, index) => {
    setFenceActiveIndex(index);
  };

  const onSpeedPieEnter = (_, index) => {
    setSpeedActiveIndex(index);
  };

  // const [open, setOpen] = React.useState(false);
  // const [selectedValue, setSelectedValue] = React.useState(1);

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  // const handleClose = (value) => {
  //   setOpen(false);
  //   // setSelectedValue(value);
  // };

  // const [selectedDate, setSelectedDate] = React.useState(
  //   new Date("2014-08-18T21:11:54")
  // );

  // const handleDateChange = (date) => {
  //   setSelectedDate(date);
  // };
  //#endregion dash
  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <p>ERROR: {error}</p>;
  }

  console.log("Data>>>>>>>>>>", data);

  const {
    totalAssetCount,
    onlineAssetCount,
    offlineAssetCount,
    disconnectedAssetCount,
    heatMapData,
    mileageTotalData,
    // mileageAverageData,
    // mileageTopData,
    // mileageBottomData,
    // idlingTopData,
    // idlingBottomData,
    // parkedTopData,
    // parkedBottomData,
    violationsData,
    topSpeedViolatorsData,
    topFenceViolatorsData,
    avgTravelPerDayInKms,
    avgTravelPerDayInHrs,
    speedViolationRate,
    fenceViolationRate,
  } = getDashBoardData(data || []);

  if (!data.length) {
    return (
      <Box textAlign="center" m={1}>
        <Typography variant="h4" paragraph>
          No vehicles under your name
        </Typography>
        <Typography variant="body1">Call support</Typography>
        {/* <CreateButton /> */}
        <Button onClick={() => window.open("tel:+251911702257")}>Call</Button>
      </Box>
    );
  }

  return (
    <ListContextProvider
      value={{
        data: keyBy(data, "id"),
        ids: data.map(({ id }) => id),
        total,
        page,
        perPage,
        setPage,
        currentSort: { field: "id", order: "ASC" },
        basePath: "/assets",
        resource: "assets",
        selectedIds: [],
      }}
      options={{ title: "Live stream" }}
    >
      <div className="dashboard-card-container">
        <Grid container spacing={3} fixed={1}>
          {/* <Grid item xs={12}>
            <div className="dashboard-picker">
              <Button
                variant="outlined"
                color="primary"
                onClick={handleClickOpen}
              >
                Mon, 24 mar - Tue, 29 mar
              </Button>
              <DateRangePickerDialog
                selectedValue={selectedValue}
                open={open}
                onClose={handleClose}
              />
            </div>
          </Grid> */}
          <Grid item xs={12} sm={6} md={6} lg={3}>
            <Paper className={classes.paper}>
              <SummaryText
                top="Total"
                middle={totalAssetCount}
                bottom="Assets"
                tint="#000000"
              />
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={3}>
            <Paper className={classes.paper}>
              {" "}
              <SummaryText
                top="Healthy"
                middle={onlineAssetCount}
                bottom="Online"
                tint="#009587"
              />
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={3}>
            <Paper className={classes.paper}>
              {" "}
              <SummaryText
                top="Warning"
                middle={offlineAssetCount}
                bottom="Offline"
                tint="#F5B00F"
              />
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={3}>
            <Paper className={classes.paper}>
              {" "}
              <SummaryText
                top="Action needed"
                middle={disconnectedAssetCount}
                bottom="Disconnected"
                tint="#FF6666"
              />
            </Paper>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Paper className={classes.paper}>
              <div className="card-title">Vehicle log cluster</div>

              <MapField data={heatMapData} />
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Paper className={classes.paper}>
              <div className="card-title">Mileage total</div>

              <ResponsiveContainer width="100%" height={400}>
                <AreaChart
                  data={mileageTotalData}
                  margin={{
                    top: 0,
                    right: 0,
                    left: 20,
                    bottom: 0,
                  }}
                >
                  {/* <defs>
                    <linearGradient id="color" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="0%" stopColor="#2451B7" stopOpacity={0.4} />
                      <stop
                        offset="75%"
                        stopColor="#2451B7"
                        stopOpacity={0.05}
                      />
                    </linearGradient>
                  </defs> */}

                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey="name"
                    // axisLine={false}
                    // tickLines={false}
                    tickFormatter={(str) =>
                      `${formattedDate(new Date(str), "MMM Do")}`
                    }
                  />
                  <YAxis
                    // axisLine={false}
                    // tickLines={false}
                    tickCount={8}
                    tickFormatter={(number) => `${number}kms`}
                  />
                  <Tooltip />
                  {Object.keys(mileageTotalData[0]).map((key, index) =>
                    // [Number(key), obj[key]]
                    // console.log(key)
                    key !== "name" ? (
                      <Area
                        key={index}
                        type="monotone"
                        stackId="1"
                        dataKey={`${key}`}
                        stroke={stringToColor(key)}
                        fill={stringToColor(key)}
                      />
                    ) : null
                  )}
                  {/* content={<CustomToolTip/>} */}
                  {/* <CartesianGrid opacity={0.1} vertical={false} /> */}
                </AreaChart>
              </ResponsiveContainer>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Paper className={classes.paper}>
              <div className="card-title">Violations</div>
              <ResponsiveContainer width="100%" height={400}>
                <BarChart
                  width={500}
                  height={300}
                  data={violationsData}
                  margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                  barSize={20}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey="name"
                    // axisLine={false}
                    // tickLines={false}
                    tickFormatter={(str) =>
                      `${formattedDate(new Date(str), "MMM Do")}`
                    }
                  />
                  <YAxis yAxisId="left" orientation="left" stroke="#FF0000" />
                  <YAxis yAxisId="right" orientation="right" stroke="#F5B00F" />
                  <Tooltip />
                  <Legend />
                  <Bar yAxisId="left" dataKey="speed" fill="#FF0000" />
                  <Bar yAxisId="right" dataKey="geofence" fill="#F5B00F" />
                </BarChart>
              </ResponsiveContainer>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Paper className={classes.paper}>
              <div className="card-title">Top violators</div>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <ResponsiveContainer width="100%" height={400}>
                    <PieChart width={400} height={400}>
                      <Pie
                        activeIndex={activeSpeedIndex}
                        activeShape={renderSpeedActiveShape}
                        data={topSpeedViolatorsData}
                        cx="50%"
                        cy="50%"
                        innerRadius={80}
                        outerRadius={120}
                        fill="#8884d8"
                        dataKey="value"
                        onMouseEnter={onSpeedPieEnter}
                      >
                        {topSpeedViolatorsData.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={COLORS[index % COLORS.length]}
                          />
                        ))}
                      </Pie>
                    </PieChart>
                  </ResponsiveContainer>
                </Grid>{" "}
                <Grid item xs={6}>
                  <ResponsiveContainer width="100%" height={400}>
                    <PieChart width={400} height={400}>
                      <Pie
                        activeIndex={activeFenceIndex}
                        activeShape={renderFenceActiveShape}
                        data={topFenceViolatorsData}
                        cx="50%"
                        cy="50%"
                        innerRadius={80}
                        outerRadius={120}
                        fill="#8884d8"
                        dataKey="value"
                        onMouseEnter={onFencePieEnter}
                      >
                        {topSpeedViolatorsData.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={COLORS[index % COLORS.length]}
                          />
                        ))}
                      </Pie>
                    </PieChart>
                  </ResponsiveContainer>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          {/* 
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Paper className={classes.paper}>
              <div className="card-title">Top mileage</div>

              <ResponsiveContainer width="100%" height={400}>
                <BarChart
                  width={500}
                  height={300}
                  data={quarterData}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" tickFormatter={monthTickFormatter} />
                  <XAxis
                    dataKey="date"
                    axisLine={false}
                    tickLine={false}
                    interval={0}
                    tick={renderQuarterTick}
                    height={1}
                    scale="band"
                    xAxisId="quarter"
                  />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="pv" fill="#8884d8" />
                  <Bar dataKey="uv" fill="#82ca9d" />
                </BarChart>
              </ResponsiveContainer>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Paper className={classes.paper}>
              <div className="card-title">Mileage avarage</div>
              <ResponsiveContainer width="100%" height={400}>
                <AreaChart data={data}>
                  <defs>
                    <linearGradient id="color" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="0%" stopColor="#2451B7" stopOpacity={0.4} />
                      <stop
                        offset="75%"
                        stopColor="#2451B7"
                        stopOpacity={0.05}
                      />
                    </linearGradient>
                  </defs>
                  <Area dataKey="value" stroke="#2451B7" fill="url(#color)" />
                  <XAxis
                    dataKey="date"
                    axisLine={false}
                    tickLines={false}
                    tickFormatter={(str) => `$${str}`}
                  />
                  <YAxis
                    dataKey="value"
                    axisLine={false}
                    tickLines={false}
                    tickCount={8}
                    tickFormatter={(number) => `$${number.toFixed(2)}`}
                  />
                  <Tooltip />
                  // content={<CustomToolTip/>} 
                  <CartesianGrid opacity={0.1} vertical={false} />
                </AreaChart>
              </ResponsiveContainer>
            </Paper>
          </Grid> */}

          <Grid item xs={12} sm={6} md={6} lg={3}>
            <Paper className={classes.paper}>
              <SummaryText
                top="mileage"
                middle={avgTravelPerDayInKms}
                bottom="on avarage per day"
              />
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={3}>
            <Paper className={classes.paper}>
              <SummaryText
                top="travels"
                middle={avgTravelPerDayInHrs}
                bottom="on avarage per day"
              />
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={3}>
            <Paper className={classes.paper}>
              <SummaryText
                top="overspeed rate"
                middle={speedViolationRate}
                bottom="on avarage per day"
              />
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={3}>
            <Paper className={classes.paper}>
              <SummaryText
                top="stray rate"
                middle={fenceViolationRate}
                bottom="on avarage per day"
              />
            </Paper>
          </Grid>
        </Grid>
      </div>
    </ListContextProvider>
  );
};

export default UserDashboard;
