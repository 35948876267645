import React from "react";
import {
  Edit,
  TextInput,
  BooleanInput,
  NumberInput,
  TabbedForm,
  SelectInput,
  FormTab,
  SelectArrayInput,
  ReferenceInput,
  Toolbar,
  SaveButton,
  useGetIdentity,
  FormDataConsumer,
  Loading,
} from "react-admin";

import { ColorInput } from "../components/ColorComponent";
import HorizontalInputStyle from "./HorizontalInputStyle";

import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import { MapFenceInput } from "../components/MapField";

const serviceStatus = [
  { id: "expired", name: "expired" },
  { id: "offline", name: "offline" },
  { id: "active", name: "active" },
];

const status = [
  { id: "tampered", name: "tampered" },
  { id: "offline", name: "offline" },
  { id: "driving", name: "driving" },
  { id: "parked", name: "parked" },
  { id: "violated", name: "violated" },
  { id: "idling", name: "idling" },
];

const notification = [
  { id: "CALL", name: "CALL" },
  { id: "SMS", name: "SMS" },
  { id: "PUSH", name: "PUSH" },
];

const subscriptionTier = [
  { id: "MOKARI", name: "MOKARI", price: 948 },
  { id: "JEMARI", name: "JEMARI", price: 1428 },
  { id: "AWAQI", name: "AWAQI", price: 2388 },
];

const driverStatus = [
  { id: "unassigned", name: "unassigned" },
  { id: "requested", name: "requested" },
  { id: "active", name: "active" },
  { id: "suspended", name: "suspended" },
  // { id: "declined", name: "declined" },
  // { id: "fresh", name: "fresh" },
];

const vehicleType = [
  { id: "mini", name: "mini" },
  { id: "Hatchback", name: "Hatchback" },
  { id: "Compact", name: "Compact" },
  { id: "SUV/Crossover", name: "SUV/Crossover" },
  { id: "Pickup", name: "Pickup" },
  { id: "Coupe", name: "Coupe" },
  { id: "Stationwagon", name: "Stationwagon" },
  { id: "Convertible", name: "Convertible" },
  { id: "Van", name: "Van" },
  { id: "Mini van", name: "Mini van" },
  { id: "Micro", name: "Micro" },
  { id: "City/Kei car", name: "City/Kei car" },
  { id: "Bus", name: "Bus" },
  { id: "Motorcycle", name: "Motorcycle" },
  { id: "Dump truck", name: "Dump truck" },
  { id: "Trailer truck", name: "Trailer truck" },
  { id: "Yellow equipment", name: "Yellow equipment" },
];

// const userOptionRenderer = (choice) => `${choice.name} - ${choice.phone}`;

// const UserEditToolbar = (props) => (
//   <Toolbar {...props}>
//     <SaveButton {...props} />
//   </Toolbar>
// );

// const isUserOwner = (identity, record) => {
//   return identity.id === record.ownerRef;
// };

const TrackEdit = (props) => {
  const { identity, loading: identityLoading } = useGetIdentity();
  if (identityLoading) return <Loading />;
  return (
    <Edit {...props}>
      {/* toolbar={<UserEditToolbar {...props} />} */}
      <TabbedForm>
        {/* toolbar={<UserEditToolbar {...props} />} */}
        <FormTab label="Details">
          {/* <TextInput disabled source="id" /> */}
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.ownerRef === identity.id && (
                <TextInput
                  required
                  source="plate"
                  style={HorizontalInputStyle}
                  {...rest}
                  disabled
                />
              )
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.driverRef === identity.id && (
                <TextInput
                  required
                  source="plate"
                  style={HorizontalInputStyle}
                  {...rest}
                  disabled
                />
              )
            }
          </FormDataConsumer>

          <SelectInput
            required
            source="serviceStatus"
            choices={serviceStatus}
            style={HorizontalInputStyle}
            disabled
          />
          <SelectInput
            required
            source="status"
            choices={status}
            style={HorizontalInputStyle}
            disabled
          />
          <SelectInput
            required
            source="subscriptionTier"
            label="Subscription Tier"
            choices={subscriptionTier}
            disabled
          />
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.ownerRef === identity.id && (
                <TextInput
                  required
                  source="brand"
                  style={HorizontalInputStyle}
                  {...rest}
                />
              )
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.driverRef === identity.id && (
                <TextInput
                  required
                  source="brand"
                  style={HorizontalInputStyle}
                  {...rest}
                  disabled
                />
              )
            }
          </FormDataConsumer>

          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.ownerRef === identity.id && (
                <TextInput
                  required
                  source="model"
                  style={HorizontalInputStyle}
                  {...rest}
                />
              )
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.driverRef === identity.id && (
                <TextInput
                  required
                  source="model"
                  style={HorizontalInputStyle}
                  {...rest}
                  disabled
                />
              )
            }
          </FormDataConsumer>

          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.ownerRef === identity.id && (
                <SelectInput
                  required
                  source="type"
                  style={HorizontalInputStyle}
                  label="Vehicle type"
                  choices={vehicleType}
                  {...rest}
                />
              )
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.driverRef === identity.id && (
                <SelectInput
                  required
                  source="type"
                  style={HorizontalInputStyle}
                  label="Vehicle type"
                  choices={vehicleType}
                  disabled
                />
              )
            }
          </FormDataConsumer>

          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.ownerRef === identity.id && (
                <TextInput required source="tag" {...rest} />
              )
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.driverRef === identity.id && (
                <TextInput required source="tag" {...rest} disabled />
              )
            }
          </FormDataConsumer>

          {/* <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.ownerRef === identity.id && (
                <TextInput
                  required
                  source="note"
                  style={HorizontalInputStyle}
                  {...rest}
                />
              )
            }
          </FormDataConsumer> */}
          {/* <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.driverRef === identity.id && (
                <TextInput
                  required
                  source="note"
                  style={HorizontalInputStyle}
                  {...rest}
                  disabled
                />
              )
            }
          </FormDataConsumer> */}

          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.ownerRef === identity.id && (
                <TextInput
                  required
                  source="color"
                  label="color name"
                  style={HorizontalInputStyle}
                  {...rest}
                />
              )
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.driverRef === identity.id && (
                <TextInput
                  required
                  source="color"
                  label="color name"
                  style={HorizontalInputStyle}
                  {...rest}
                  disabled
                />
              )
            }
          </FormDataConsumer>

          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.ownerRef === identity.id && (
                <ReferenceInput
                  label="Cohort"
                  source="cohortRef"
                  reference="cohorts"
                  allowEmpty
                  style={HorizontalInputStyle}
                  {...rest}
                >
                  <SelectInput optionText="cohortName" />
                </ReferenceInput>
              )
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.driverRef === identity.id && (
                <ReferenceInput
                  label="Cohort"
                  source="cohortRef"
                  reference="cohorts"
                  allowEmpty
                  disabled
                  style={HorizontalInputStyle}
                  {...rest}
                >
                  <SelectInput optionText="cohortName" />
                </ReferenceInput>
              )
            }
          </FormDataConsumer>

          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.ownerRef === identity.id && (
                <ColorInput source="colorHex" picker="Swatches" {...rest} />
              )
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.driverRef === identity.id && (
                <ColorInput
                  source="colorHex"
                  picker="Swatches"
                  {...rest}
                  disabled
                />
              )
            }
          </FormDataConsumer>

          <br />
          {/* Block Chrome Compact Github Photoshop Swatches Twitter */}
        </FormTab>

        <FormTab label="Controls">
          {/* driver status */}
          <div className="engine-label">
            disables/enables the vehicle engine
          </div>
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.ownerRef === identity.id && (
                <BooleanInput
                  required
                  source="engine"
                  defaultValue={true}
                  {...rest}
                />
              )
            }
          </FormDataConsumer>

          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.ownerRef === identity.id && (
                <TextInput
                  required
                  source="driverRef"
                  label="Driver phone or id"
                  style={HorizontalInputStyle}
                  {...rest}
                />
              )
            }
          </FormDataConsumer>

          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.ownerRef === identity.id && (
                <SelectInput
                  required
                  source="driverStatus"
                  label="Driver status"
                  choices={driverStatus}
                  style={HorizontalInputStyle}
                  {...rest}
                />
              )
            }
          </FormDataConsumer>

          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.ownerRef === identity.id && (
                <TextInput
                  required
                  source="driverVerification"
                  label="Driver verification"
                  disabled
                  {...rest}
                />
              )
            }
          </FormDataConsumer>

          {/* owner notification type */}
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.ownerRef === identity.id && (
                <SelectArrayInput
                  required
                  source="ownerTetherNotificationType"
                  label="Tamper notification"
                  style={HorizontalInputStyle}
                  choices={notification}
                  {...rest}
                />
              )
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.driverRef === identity.id && (
                <SelectArrayInput
                  required
                  source="driverTetherNotificationType"
                  label="Tamper notification"
                  style={HorizontalInputStyle}
                  choices={notification}
                  {...rest}
                />
              )
            }
          </FormDataConsumer>

          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.ownerRef === identity.id && (
                <SelectArrayInput
                  required
                  source="ownerFenceNotificationType"
                  label="Fence notification"
                  style={HorizontalInputStyle}
                  choices={notification}
                  {...rest}
                />
              )
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.driverRef === identity.id && (
                <SelectArrayInput
                  required
                  source="driverFenceNotificationType"
                  label="Fence notification"
                  style={HorizontalInputStyle}
                  choices={notification}
                  {...rest}
                />
              )
            }
          </FormDataConsumer>

          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.ownerRef === identity.id && (
                <SelectArrayInput
                  required
                  source="ownerSpeedNotificationType"
                  label="Speed notification"
                  style={HorizontalInputStyle}
                  choices={notification}
                  {...rest}
                />
              )
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.driverRef === identity.id && (
                <SelectArrayInput
                  required
                  source="driverSpeedNotificationType"
                  label="Speed notification"
                  style={HorizontalInputStyle}
                  choices={notification}
                  {...rest}
                />
              )
            }
          </FormDataConsumer>

          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.ownerRef === identity.id && (
                <SelectArrayInput
                  required
                  source="ownerParkNotificationType"
                  label="Park notification"
                  choices={notification}
                  {...rest}
                />
              )
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.driverRef === identity.id && (
                <SelectArrayInput
                  required
                  source="driverParkNotificationType"
                  label="Park notification"
                  choices={notification}
                  {...rest}
                />
              )
            }
          </FormDataConsumer>

          {/* owner booleans */}
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.ownerRef === identity.id && (
                <BooleanInput
                  required
                  defaultValue={true}
                  source="isOwnerServiceActive"
                  label="Service"
                  style={HorizontalInputStyle}
                  {...rest}
                />
              )
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.driverRef === identity.id && (
                <BooleanInput
                  required
                  defaultValue={true}
                  source="isDriverServiceActive"
                  label="Service"
                  style={HorizontalInputStyle}
                  {...rest}
                />
              )
            }
          </FormDataConsumer>

          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.ownerRef === identity.id && (
                <BooleanInput
                  required
                  defaultValue={false}
                  source="isOwnerSpeedLimited"
                  label="Speed limited"
                  style={HorizontalInputStyle}
                  {...rest}
                />
              )
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.driverRef === identity.id && (
                <BooleanInput
                  required
                  defaultValue={false}
                  source="isDriverSpeedLimited"
                  label="Speed limited"
                  style={HorizontalInputStyle}
                  {...rest}
                />
              )
            }
          </FormDataConsumer>

          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.ownerRef === identity.id && (
                <BooleanInput
                  required
                  defaultValue={false}
                  source="isOwnerFenced"
                  label="Fenced"
                  style={HorizontalInputStyle}
                  {...rest}
                />
              )
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.driverRef === identity.id && (
                <BooleanInput
                  required
                  defaultValue={false}
                  source="isDriverFenced"
                  label="Fenced"
                  style={HorizontalInputStyle}
                  {...rest}
                />
              )
            }
          </FormDataConsumer>

          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.ownerRef === identity.id && (
                <BooleanInput
                  required
                  defaultValue={false}
                  source="isOwnerParked"
                  label="Parked"
                  {...rest}
                />
              )
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.driverRef === identity.id && (
                <BooleanInput
                  required
                  defaultValue={false}
                  source="isDriverParked"
                  label="Parked"
                  {...rest}
                />
              )
            }
          </FormDataConsumer>
        </FormTab>

        <FormTab label="Fence">
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.ownerRef === identity.id && (
                <MapFenceInput source="ownerFence" label="GeoFence" {...rest} />
                // <GeoFenceInput source="ownerFence" label="GeoFence" {...rest} />
              )
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.driverRef === identity.id && (
                <MapFenceInput
                  source="driverFence"
                  label="GeoFence"
                  {...rest}
                />
              )
            }
          </FormDataConsumer>
        </FormTab>

        <FormTab label="Speed">
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.ownerRef === identity.id && (
                <NumberInput
                  required
                  source="ownerSpeedLimitValue"
                  label="Speed limit"
                  {...rest}
                />
              )
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.driverRef === identity.id && (
                <NumberInput
                  required
                  source="driverSpeedLimitValue"
                  label="Speed limit"
                  {...rest}
                />
              )
            }
          </FormDataConsumer>
        </FormTab>
        {/* <DeleteButton undoable={true} /> */}
      </TabbedForm>
    </Edit>
  );
};

export default TrackEdit;
