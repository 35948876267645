import React from "react";
import {
  List,
  Datagrid,
  ChipField,
  TextField,
  BooleanField,
  ReferenceField,
  EditButton,
  FunctionField,
  Show,
  CardActions,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import { formattedLastTime } from "./formatter";
import { MapField } from "../components/MapField";

// const NoneActions = (props) => <CardActions />;

const useStyles = makeStyles({
  uninstalled: { color: "grey" },
  setup: { color: "yellow" },
  testing: { color: "yellow" },
  active: { color: "green" },
  maintanance: { color: "yellow" },
  offline: { color: "grey" },
  faulty: { color: "red" },
});

const NoneActions = (props) => <CardActions {...props} />;

const ColoredChipField = (props) => {
  const classes = useStyles();
  return (
    <ChipField
      className={classnames({
        [classes.uninstalled]: props.record[props.source] === "uninstalled",
        [classes.setup]: props.record[props.source] === "setup",
        [classes.testing]: props.record[props.source] === "testing",
        [classes.active]: props.record[props.source] === "active",
        [classes.maintanance]: props.record[props.source] === "maintanance",
        [classes.offline]: props.record[props.source] === "offline",
        [classes.faulty]: props.record[props.source] === "faulty",
      })}
      {...props}
    />
  );
};
// Ensure the original component defaultProps are still applied as they may be used by its parents (such as the `Show` component):
ColoredChipField.defaultProps = ChipField.defaultProps;

// const VehiclePanel = ({ id, record, resource }) => (
//   <ReferenceManyField reference="vehicles" target="deviceRef">
//     <Datagrid rowClick="show">
//       <TextField source="SIMNumber" />
//     </Datagrid>
//   </ReferenceManyField>
// );
const DevicePanel = (props) => (
  <Show {...props} actions={<NoneActions />}>
    <MapField source="lastLocation" />
  </Show>
);

const DevicesList = (props) => {
  return (
    <List {...props}>
      {/* expand={<VehiclePanel />}  */}
      <Datagrid hasBulkActions={false} expand={<DevicePanel />}>
        <TextField source="identifier" label="IME" />
        <TextField source="model" label="Model" />
        <TextField source="SIMNumber" label="Number" />
        <TextField source="trackerType" label="Type" />
        {/* <TextField source="serverURL" label="Uplink" /> */}
        <TextField source="devicePassword" label="Password" />
        <ColoredChipField source="deviceStatus" label="Status" />
        <ReferenceField label="Master" source="masterRef" reference="users">
          <TextField source="name" />
        </ReferenceField>
        <BooleanField source="relayStatus" label="Relay" />

        <TextField source="note" label="Note" />
        <FunctionField
          label="Last signal"
          render={(record) => `${formattedLastTime(record.lastLog, true)}`}
        />
        {/* <TextField source="SIMBalance" /> */}
        <EditButton label="Edit" basePath="/devices" />
        {/* <DeleteButton label="Delete" basePath="/devices" /> */}
      </Datagrid>
    </List>
  );
};

export default DevicesList;
