import React from "react";
import {
  Create,
  TextInput,
  SelectInput,
  BooleanInput,
  DateInput,
  SelectArrayInput,
  ReferenceInput,
  TabbedForm,
  FormTab,
} from "react-admin";
import { MapFenceInput } from "../components/MapField";
import HorizontalInputStyle from "./HorizontalInputStyle";

const invoiceStatus = [
  { id: "active", name: "active" },
  { id: "sent", name: "sent" },
  { id: "overdue", name: "overdue" },
  { id: "paid", name: "paid" },
  { id: "confirm", name: "confirm" },
  { id: "closed", name: "closed" },
];

const InvoiceCreate = (props) => {
  return (
    <Create {...props}>
      <TabbedForm>
        <FormTab label="Details">
          <TextInput source="invoiceNumber" />
          <ReferenceInput
            label="vehicle"
            source="vehicleRef"
            reference="vehicles"
            style={HorizontalInputStyle}
          >
            <SelectInput source="plate" />
          </ReferenceInput>
          {/* <ReferenceInput
            label="Tracker"
            source="deviceRef"
            reference="devices"
            style={HorizontalInputStyle}
            allowEmpty
          >
            <SelectInput optionText={trackerOptionRenderer} />
          </ReferenceInput> */}
          <DateInput source="dueDate" />
          <TextInput source="amount" />
          <TextInput source="reason" />
          <TextInput source="status" />
          <SelectInput
            source="status"
            style={HorizontalInputStyle}
            choices={invoiceStatus}
            required
          />
        </FormTab>
        <FormTab label="Dates">
          <DateInput source="issuedDate" />
          <DateInput source="nextExpiryDate" />
          <DateInput source="dueDate" />
          <DateInput source="issuedNoticeDate" />
          <DateInput source="reminderNoticeDate" />
          <DateInput source="dueNoticeDate" />
          <DateInput source="overdueNoticeDate" />
          <DateInput source="warningNoticeDate" />
          <DateInput source="suspensionNoticeDate" />
        </FormTab>
        <FormTab label="Notified">
          <BooleanInput source="issuedNotified" />
          <BooleanInput source="reminderNotified" />
          <BooleanInput source="dueNotified" />
          <BooleanInput source="overdueNotified" />
          <BooleanInput source="warningNotified" />
          <BooleanInput source="suspensionNotified" />
        </FormTab>
        <FormTab label="Confirmation">
          <TextInput source="bankName" />
          <TextInput source="senderName" />
          <TextInput source="transactionNumber" />
        </FormTab>
      </TabbedForm>
    </Create>
  );
};

export default InvoiceCreate;
