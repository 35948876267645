import React from "react";
import {
  List,
  Datagrid,
  TextField,
  ChipField,
  DateField,
  ReferenceField,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import classnames from "classnames";

const useStyles = makeStyles({
  customer: { color: "green" },
  prospect: { color: "grey" },
  manager: { color: "yellow" },
  admin: { color: "red" },
});

const ColoredChipField = (props) => {
  const classes = useStyles();
  return (
    <ChipField
      className={classnames({
        [classes.customer]: props.record[props.source] === "customer",
        [classes.prospect]: props.record[props.source] === "prospect",
        [classes.manager]: props.record[props.source] === "manager",
        [classes.admin]: props.record[props.source] === "admin",
      })}
      {...props}
    />
  );
};
// Ensure the original component defaultProps are still applied as they may be used by its parents (such as the `Show` component):
ColoredChipField.defaultProps = ChipField.defaultProps;

// const userOptionRenderer = (choice) => `${choice.name} - ${choice.phone}`;

const CompaniesList = (props) => {
  return (
    <List {...props}>
      <Datagrid rowClick="show">
        <TextField source="companyName" />
        <TextField source="email" />
        <TextField source="phone" />
        <ReferenceField label="Owner" source="ownerRef" reference="customers">
          <TextField source="name" />
        </ReferenceField>
        <DateField source="registerDate" />
        <ChipField source="status" />
        <TextField source="note" />
      </Datagrid>
    </List>
  );
};

export default CompaniesList;
