import L from "leaflet";
import disconnected from "../icons/disconnected.svg";
import driving from "../icons/driving.svg";
import engine_cut from "../icons/engine_cut.svg";
import engine_cut_violation from "../icons/engine_cut_violation.svg";
import fence_violation from "../icons/fence_violation.svg";
import fenced from "../icons/fenced.svg";
import park from "../icons/park.svg";
import park_violation from "../icons/park_violation.svg";
import speed_violation from "../icons/speed_violation.svg";
import speed from "../icons/speed.svg";

const iconDisconnected = new L.Icon({
  iconUrl: disconnected,
  iconRetinaUrl: disconnected,
  //   iconAnchor: null,
  //   popupAnchor: null,
  //   shadowUrl: null,
  //   shadowSize: null,
  //   shadowAnchor: null,
  iconSize: new L.Point(33, 68),
  //   className: "leaflet-div-icon",
});

const iconDriving = new L.Icon({
  iconUrl: driving,
  iconRetinaUrl: driving,
  iconSize: new L.Point(33, 68),
});

const iconEngineCut = new L.Icon({
  iconUrl: engine_cut,
  iconRetinaUrl: engine_cut,
  iconSize: new L.Point(55, 68),
});

const iconEngineCutViolation = new L.Icon({
  iconUrl: engine_cut_violation,
  iconRetinaUrl: engine_cut_violation,
  iconSize: new L.Point(120, 141),
});

const iconFenceViolation = new L.Icon({
  iconUrl: fence_violation,
  iconRetinaUrl: fence_violation,
  iconSize: new L.Point(40, 76),
});

const iconFenced = new L.Icon({
  iconUrl: fenced,
  iconRetinaUrl: fenced,
  iconSize: new L.Point(40, 76),
});

const iconParkViolation = new L.Icon({
  iconUrl: park_violation,
  iconRetinaUrl: park_violation,
  iconSize: new L.Point(120, 120),
});

const iconPark = new L.Icon({
  iconUrl: park,
  iconRetinaUrl: park,
  iconSize: new L.Point(120, 120),
});

const iconSpeedViolation = new L.Icon({
  iconUrl: speed_violation,
  iconRetinaUrl: speed_violation,
  iconSize: new L.Point(34, 103),
});

const iconSpeed = new L.Icon({
  iconUrl: speed,
  iconRetinaUrl: speed,
  iconSize: new L.Point(34, 68),
});

export {
  iconDisconnected,
  iconDriving,
  iconEngineCut,
  iconEngineCutViolation,
  iconFenceViolation,
  iconFenced,
  iconParkViolation,
  iconPark,
  iconSpeedViolation,
  iconSpeed,
};
